import React, { useState, useCallback } from 'react'
import {
    Box,
    Typography,
    Button,
    Alert,
    Skeleton,
    Tooltip,
} from '@mui/material'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiContext,
    useGridSelector,
    gridFilterModelSelector,
} from '@mui/x-data-grid'

const CustomToolbar = () => {
    const apiRef = useGridApiContext()
    const filterModel = useGridSelector(apiRef, gridFilterModelSelector)

    return (
        <GridToolbarContainer>
            <Box sx={{ width: '100%', p: 1 }}>
                <Typography variant="h6" gutterBottom>
                    Table Preview
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <GridToolbarFilterButton />
                </Box>
            </Box>
        </GridToolbarContainer>
    )
}

const GalleryTab = ({ tables, loading, error, onTableSelect }) => {
    const [filterModel, setFilterModel] = useState({
        items: [],
    })

    const columns = [
        {
            field: 'title',
            headerName: 'Table Title',
            flex: 2,
            renderCell: (params) => (
                <>
                    <Tooltip title={params.value} arrow>
                        <Box
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                            }}
                        >
                            {params.value}
                        </Box>
                    </Tooltip>
                </>
            ),
        },
        // { field: 'type', headerName: 'Type', flex: 1, filterable: true },
        // {
        //     field: 'population',
        //     headerName: 'Population',
        //     flex: 1,
        //     filterable: true,
        // },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => onTableSelect(params.row)}
                >
                    Open
                </Button>
            ),
        },
    ]

    if (loading) {
        return (
            <Box sx={{ height: 600, width: '100%' }}>
                <Box sx={{ mb: 2 }}>
                    <Skeleton variant="rectangular" height={48} />
                </Box>
                <Box sx={{ p: 1, mb: 2 }}>
                    <Skeleton variant="text" width={200} height={32} />
                    <Skeleton variant="text" width={100} height={24} />
                </Box>
                <Box sx={{ display: 'flex', mb: 1 }}>
                    {[1, 2, 3, 4, 5].map((i) => (
                        <Skeleton
                            key={i}
                            variant="text"
                            width={`${100 / 5}%`}
                            height={48}
                            sx={{ mx: 0.5 }}
                        />
                    ))}
                </Box>
                {[1, 2, 3, 4, 5].map((i) => (
                    <Box key={i} sx={{ display: 'flex', mb: 1 }}>
                        {[1, 2, 3, 4, 5].map((j) => (
                            <Skeleton
                                key={j}
                                variant="text"
                                width={`${100 / 5}%`}
                                height={52}
                                sx={{ mx: 0.5 }}
                            />
                        ))}
                    </Box>
                ))}
            </Box>
        )
    }

    if (error) {
        return <Typography color="error">{error}</Typography>
    }

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Alert severity="info" sx={{ mb: 2 }}>
                Browse all available tables in the gallery. Use filters to find
                specific tables by type, population, or source document. Click
                'Open' to preview any table's full content and details.
            </Alert>
            <DataGrid
                rows={tables}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
                slots={{
                    toolbar: CustomToolbar,
                }}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
            />
        </Box>
    )
}

export default GalleryTab
