import React, { useState } from 'react'
import {
    Modal,
    Box,
    Typography,
    Button,
    IconButton,
    Alert,
    CircularProgress,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {
    extractAndStoreTFLTables,
    uploadMultipleFilesUpdated,
} from '../../../../utils/utils'
import { useAuthInfo } from '@propelauth/react'

const BulkUploadModal = ({ open, onClose, documentType, documentId }) => {
    const [selectedFile, setSelectedFile] = useState(null)
    const [error, setError] = useState('')
    const [isUploading, setIsUploading] = useState(false)
    const [showProcessingAlert, setShowProcessingAlert] = useState(false)
    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            if (file.size > 100 * 1024 * 1024) {
                setError('File size exceeds 100 MB limit')
                setSelectedFile(null)
            } else {
                console.log(file)
                setSelectedFile(file)
                setError('')
            }
        }
    }

    const handleUpload = async () => {
        if (!selectedFile) {
            setError('Please select a file')
            return
        }

        setIsUploading(true)
        setError('')

        try {
            const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(
                orgs[0].orgId
            )
            const readFileContent = () => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = (e) => resolve(e.target.result)
                    reader.onerror = (e) => reject(e)
                    reader.readAsArrayBuffer(selectedFile)
                })
            }
            const readerFileContent = await readFileContent()
            // Upload file without orgId
            const uploadResult = await uploadMultipleFilesUpdated(
                setIsUploading,
                [{ name: selectedFile.name, file: readerFileContent }],
                [readerFileContent],
                setError,
                'input',
                orgAccessToken
            )

            await extractAndStoreTFLTables(
                selectedFile.name,
                'input',
                'tables',
                documentType,
                documentId,
                orgAccessToken
            )

            setShowProcessingAlert(true)
            setSelectedFile(null)
            setIsUploading(false)

            // Close the modal after 5 seconds
            setTimeout(() => {
                onClose()
                setShowProcessingAlert(false)
            }, 5000)
        } catch (error) {
            console.error('Upload failed:', error)
            setError('Upload failed. Please try again.')
            setIsUploading(false)
        }
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography variant="h6">Bulk Upload Tables</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Alert severity="info" sx={{ mb: 3 }}>
                    <Typography variant="body2">
                        <strong>File Format Requirements:</strong>
                        <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                            <li>
                                Each table must have a unique title directly
                                above the table content
                            </li>
                            <li>Supported formats: RTF, DOCX, PDF</li>
                            <li>
                                Tables should be properly formatted with clear
                                columns and rows
                            </li>
                            <li>
                                For RTF files: Ensure tables are not split
                                across pages
                            </li>
                            <li>Maximum file size: 100 MB</li>
                        </ul>
                    </Typography>
                </Alert>

                {showProcessingAlert ? (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Your tables are being processed in the background. This
                        could take up to 5 minutes to complete.
                    </Alert>
                ) : (
                    <>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Upload a PDF, DOCX, CSV, or RTF file (max 100 MB)
                        </Typography>

                        <Box
                            sx={{
                                border: '2px dashed #ccc',
                                borderRadius: 1,
                                p: 3,
                                textAlign: 'center',
                                mb: 2,
                            }}
                        >
                            <input
                                type="file"
                                accept=".pdf,.docx,.csv,.rtf"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="file-upload"
                            />
                            <label htmlFor="file-upload">
                                <Button
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                    variant="outlined"
                                >
                                    Choose File
                                </Button>
                            </label>
                            {selectedFile && (
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    {selectedFile.name} (
                                    {(selectedFile.size / 1024 / 1024).toFixed(
                                        2
                                    )}{' '}
                                    MB)
                                </Typography>
                            )}
                        </Box>

                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}

                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleUpload}
                            disabled={!selectedFile || isUploading}
                            sx={{ mt: 2 }}
                        >
                            {isUploading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Upload'
                            )}
                        </Button>
                    </>
                )}
            </Box>
        </Modal>
    )
}

export default BulkUploadModal
