import React, { useState, useCallback, useEffect } from 'react'
import {
    Modal,
    Box,
    Typography,
    Button,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    CircularProgress,
    Chip,
    Divider,
} from '@mui/material'
import { MultipleFileUploader } from '../../../components/MultipleFileUploader'
import {
    extractAndStoreMultipleMongo,
    uploadMultipleFilesUpdated,
} from '../../../utils/utils'
import { useAuthInfo } from '@propelauth/react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

function SourceSelectionModal({
    open,
    handleClose,
    onSourceSelect,
    inputFiles,
    isLoadingInputFiles,
    oldFiles,
    id,
}) {
    const [tabValue, setTabValue] = useState(0)
    const [selectedSources, setSelectedSources] = useState([])
    const [isUploading, setIsUploading] = useState(false)

    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()

    const getOrgAccessToken = useCallback(async () => {
        return await authInfo.tokens.getAccessTokenForOrg(orgs[0].orgId)
    }, [authInfo, orgs])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    useEffect(() => {
        setSelectedSources(oldFiles)
    }, [oldFiles])

    const handleSourceToggle = (source) => {
        setSelectedSources((prev) =>
            prev.includes(source)
                ? prev.filter((s) => s !== source)
                : [...prev, source]
        )
    }

    const handleFileUpload = async (files, fileContents) => {
        setIsUploading(true)
        try {
            const orgAccessToken = await getOrgAccessToken()
            await uploadMultipleFilesUpdated(
                setIsUploading,
                files,
                fileContents,
                () => {},
                'input',
                orgAccessToken
            )
            const orgAccessToken2 = await getOrgAccessToken()
            const newSources = files.map((file) => file.name)
            const mongoSources = files.map(
                (file) => `${orgs[0].orgId}/${file.name}`
            )
            await extractAndStoreMultipleMongo(
                setIsUploading,
                null,
                mongoSources,
                'input',
                orgAccessToken2
            )
            setSelectedSources((prev) => [...prev, ...newSources])
        } catch (error) {
            console.error('Error uploading files:', error)
        } finally {
            setIsUploading(false)
        }
    }

    const handleConfirm = () => {
        onSourceSelect(selectedSources)
        handleClose()
    }

    const formatSourceTitle = (source) => {
        return source.split('/').pop()
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    gutterBottom
                >
                    Select or Upload Sources
                </Typography>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{ mb: 2 }}
                >
                    <Tab label="Select Existing" />
                    <Tab label="Upload New" />
                </Tabs>
                {tabValue === 0 && (
                    <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
                        {isLoadingInputFiles ? (
                            <CircularProgress />
                        ) : (
                            <List>
                                {inputFiles.map((file) => (
                                    <ListItem
                                        key={file.name}
                                        button
                                        onClick={() =>
                                            handleSourceToggle(file.name)
                                        }
                                    >
                                        <Checkbox
                                            edge="start"
                                            checked={selectedSources.includes(
                                                file.name
                                            )}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                        <ListItemText primary={file.name} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                )}
                {tabValue === 1 && (
                    <Box>
                        <MultipleFileUploader
                            handleFiles={handleFileUpload}
                            // isDisabled={isDemo}
                        />
                        {isUploading && <CircularProgress />}
                    </Box>
                )}
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        maxHeight: '30vh',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                    }}
                >
                    {selectedSources.map((source) => (
                        <Chip
                            key={source}
                            label={formatSourceTitle(source)}
                            onDelete={() => handleSourceToggle(source)}
                        />
                    ))}
                </Box>
                <Button
                    onClick={handleConfirm}
                    variant="contained"
                    sx={{ mt: 2 }}
                >
                    Confirm Selection
                </Button>
            </Box>
        </Modal>
    )
}

export default SourceSelectionModal
