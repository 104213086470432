import React, { useState, useEffect } from 'react'
import {
    Modal,
    Box,
    Typography,
    Button,
    Chip,
    Autocomplete,
    TextField,
    Divider,
    CircularProgress,
    Alert,
} from '@mui/material'
import Select from 'react-select'
import { useDemo } from '../../../contexts/DemoContext'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
}

const ruleTypeOptions = [
    { value: 'copypaste', label: 'Copy/Paste' },
    { value: 'summarize', label: 'Summarize' },
    { value: 'conclude', label: 'Conclude' },
]

const predefinedTopics = [
    'Primary PK',
    'Secondary PK',
    'Primary PD',
    'Secondary PD',
    'Adverse events',
]

function RuleCreationStep({
    currentRule,
    setCurrentRule,
    availableFiles,
    onAddRule,
    documents,
    isLoadingFiles,
}) {
    const { isDemo } = useDemo()

    const isConcludeRule = currentRule.ruleType?.value === 'conclude'

    // Transform blob data into select options
    const fileOptions =
        documents?.map((doc) => ({
            value: doc.name,
            label: doc.name,
        })) || []

    const handleRuleTypeChange = (selectedOption) => {
        setCurrentRule((prev) => ({
            ...prev,
            ruleType: selectedOption,
            selectedFiles: [],
            topics: [],
            instructions: '',
            modifications: '',
        }))
    }

    const handleFileChange = (selectedOptions) => {
        console.log('Selected files:', selectedOptions)
        setCurrentRule((prev) => ({
            ...prev,
            selectedFiles: selectedOptions || [],
        }))
    }

    const handleTopicsChange = (event, newValue) => {
        if (isDemo && newValue.length > 1) {
            newValue = [newValue[newValue.length - 1]]
        }
        setCurrentRule((prev) => ({
            ...prev,
            topics: newValue,
        }))
    }

    const handleInstructionsChange = (event) => {
        setCurrentRule((prev) => ({
            ...prev,
            instructions: event.target.value,
        }))
    }

    // const showInstructions = ['justify', 'conclude'].includes(
    //     currentRule.ruleType?.value
    // )

    const showTopics = currentRule.ruleType?.value === 'summarize'

    return (
        <Box>
            {isConcludeRule && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    The conclude rule will create a narrative-based conclusion
                    from all tabular information within this document. Note:
                    This rule requires uploaded tables to work properly - if no
                    tables are present, the conclusion may be incomplete or fail
                    to generate.
                </Alert>
            )}

            <Typography variant="subtitle1" gutterBottom>
                Rule Type
            </Typography>
            <Select
                options={ruleTypeOptions}
                value={currentRule.ruleType}
                onChange={handleRuleTypeChange}
                placeholder="Select Rule Type"
                isDisabled={isLoadingFiles}
            />

            {!isConcludeRule && (
                <>
                    <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                        Source Files
                    </Typography>
                    {isLoadingFiles ? (
                        <Box display="flex" justifyContent="center" my={2}>
                            <CircularProgress size={24} />
                        </Box>
                    ) : (
                        <Select
                            isMulti
                            options={fileOptions}
                            value={currentRule.selectedFiles}
                            onChange={handleFileChange}
                            placeholder="Select source files"
                            isDisabled={!currentRule.ruleType}
                        />
                    )}
                </>
            )}

            {showTopics && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1" gutterBottom>
                        Topics
                    </Typography>
                    {isDemo && (
                        <Typography variant="caption" color="textSecondary">
                            In demo mode, only one topic can be selected.
                        </Typography>
                    )}
                    <Autocomplete
                        multiple
                        id="topics-select"
                        options={predefinedTopics}
                        freeSolo
                        value={currentRule.topics || []}
                        onChange={handleTopicsChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Add a topic"
                                variant="outlined"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={index}
                                    label={option}
                                    {...getTagProps({ index })}
                                    sx={{ borderRadius: '16px' }}
                                />
                            ))
                        }
                    />
                </>
            )}

            {/* {showInstructions && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1" gutterBottom>
                        Additional Instructions
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        value={currentRule.instructions || ''}
                        onChange={handleInstructionsChange}
                        placeholder="Enter additional instructions..."
                        variant="outlined"
                    />
                </>
            )} */}

            <Button
                onClick={onAddRule}
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                disabled={
                    !currentRule.ruleType ||
                    (!isConcludeRule && !currentRule.selectedFiles?.length) ||
                    (currentRule.ruleType?.value === 'summarize' &&
                        !currentRule.topics?.length)
                }
            >
                Add Rule
            </Button>
        </Box>
    )
}

function RulesModal({
    open,
    onClose,
    onAddRule,
    documentId,
    getOrgAccessToken,
    availableFiles,
    documents = [],
}) {
    const [rules, setRules] = useState([])
    const [isLoadingFiles, setIsLoadingFiles] = useState(false)
    const [currentRule, setCurrentRule] = useState({
        ruleType: null,
        selectedFiles: [],
        topics: [],
        instructions: '',
        modifications: '',
    })

    // Effect to handle initial loading state
    useEffect(() => {
        if (open) {
            setIsLoadingFiles(true)
            if (documents && documents.length > 0) {
                setIsLoadingFiles(false)
            }
        }
    }, [open, documents])

    const handleAddRule = () => {
        const newRule = {
            type: currentRule.ruleType.value,
            sources: currentRule.selectedFiles.map((file) => file.value), // Use filename as source
            topics: currentRule.topics || [],
            instructions: currentRule.instructions || '',
            modifications: currentRule.modifications || '',
        }

        console.log('Adding new rule:', newRule)
        setRules((prevRules) => [...prevRules, newRule])

        // Reset current rule after adding
        setCurrentRule({
            ruleType: null,
            selectedFiles: [],
            topics: [],
            instructions: '',
            modifications: '',
        })
    }

    const handleFinalSubmit = () => {
        console.log('Submitting rules:', rules)
        rules.forEach((rule) => {
            console.log('Processing rule:', rule)
            onAddRule(rule)
        })
        onClose()
    }

    const handleRemoveRule = (indexToRemove) => {
        setRules((prevRules) =>
            prevRules.filter((_, index) => index !== indexToRemove)
        )
    }

    const handleModalClose = () => {
        setRules([])
        setCurrentRule({
            ruleType: null,
            selectedFiles: [],
            topics: [],
            instructions: '',
            modifications: '',
        })
        onClose()
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    gutterBottom
                >
                    Add Rules
                </Typography>
                <Box sx={{ mb: 2 }}>
                    {rules.map((rule, index) => (
                        <Chip
                            key={index}
                            label={
                                rule.type === 'conclude'
                                    ? 'Conclude: Generate conclusion from tables'
                                    : `${rule.type}: ${rule.sources.join(
                                          ', '
                                      )}${
                                          rule.topics?.length
                                              ? ` [${rule.topics.join(', ')}]`
                                              : ''
                                      }${
                                          rule.instructions
                                              ? ' (+ instructions)'
                                              : ''
                                      }`
                            }
                            onDelete={() => handleRemoveRule(index)}
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'primary.contrastText',
                                m: 0.5,
                            }}
                        />
                    ))}
                </Box>
                <RuleCreationStep
                    currentRule={currentRule}
                    setCurrentRule={setCurrentRule}
                    availableFiles={availableFiles}
                    onAddRule={handleAddRule}
                    documents={documents}
                    isLoadingFiles={isLoadingFiles}
                />
                <Button
                    onClick={handleFinalSubmit}
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2 }}
                    disabled={rules.length === 0}
                >
                    Submit All Rules
                </Button>
            </Box>
        </Modal>
    )
}

export default RulesModal
