import React from 'react';
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, FormControl, TextField, FormControlLabel, h4, Radio, RadioGroup, Slide } from '@mui/material';
import "../App.css"
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { MultipleFileUploader } from '../components/MultipleFileUploader';
import PDFWebViewComponent from '../components/PDFWebViewComponent';
import { uploadMultipleFilesUpdated, generateOutline, generateBoth, createINDModule2Section, extractAndStoreMultiple, createOutputVectorstore, processTemplate, delay, emailTrigger } from '../utils/utils';
import { getOneDocumentSet, listAllFiles } from '../utils/utils';
import plus from '../icons/Plus.svg'
import paperclip from '../icons/Paper clip.svg'
import dots from '../icons/DotsVector.svg'
import CustomTableButton from '../components/CustomTableButton';
import ToggleComponent from '../components/ToggleComponent';
import MainButton from '../components/MainButton';
import SidebarComponent from '../components/SidebarComponent';
import SidebarPen from '../icons/SidebarPen.png'; 
import SidebarLines from '../icons/SidebarLines.png'; 
import SidebarSend from '../icons/SidebarSend.png'; 
import UpdateDocSidebar from '../components/UpdateDocSidebar';

function IndividualAutomationPage() {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [modalOpen, setModalOpen] = useState(false)

    const [step, setStep] = useState(1)

    const navigate = useNavigate();

    const navigateToDocumentWithUrl = (params) => {
        navigate(`/document/afbdb775-8157-4f6f-ba81-418a0cc13192`);
    };



    const handleClick = async () => {

        setLoading(true)
        setModalOpen(true)
        await delay(5000);
        await emailTrigger();
        setLoading(false)

    }


    const handleRowClick = (params) => {
        console.log('params', params.row)
        navigate(`/editTemplate/${params.row.id}`, { state: { ...params.row } });
    };

    const colDefs = [
        { field: 'name', headerName: 'Name', flex: 4, headerClassName: 'table-header' },
        { field: 'url', headerName: 'Created', flex: 1 },
        { field: 'id', headerName: 'Last Updated', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => <CustomTableButton isOn={params.row.status} />
        },
    ];

    const templatesList = [
        {
            type: 'CSR AE table automation',
            name: 'CSR AE table automation',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/NCO Template.docx',
            id: "12a1b1d4-9a95-4575-8d92-9eeacc7558d6",
            status: true
        },
        {
            type: 'DSUR data update automation',
            name: 'DSUR data update automation',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/DSUR Template.docx',
            id: "e01eef77-03b1-48ba-b149-01c9336c9330",
            status: true
        },
        {
            type: 'IB non-clinical data update automation',
            name: 'IB non-clinical data update automation',
            url: 'https://otsukateststorage.blob.core.windows.net/templates/Artos - Otsuka CSR template.docx',
            id: "ca0bffc5-5144-41a4-85b6-b656ddf870eb",
            status: false
        },
    ]

    const handleDelete = () => {
        // Your delete logic here
        console.log('Delete button clicked');
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState("trigger");

    const triggerSidebar = () => {
        console.log("trigger side bar activated")
        setIsSidebarOpen("trigger")
    };

    const actionSidebar = () => {
        console.log("action side bar activated")
        setIsSidebarOpen("action")
    };

    const sidebarTitle = isSidebarOpen === "trigger" ? "Edit Trigger" : "Edit Actions";

    const actionItems = [
        { iconSrc: SidebarPen, text: 'Update document in', subtext: '[in Veeva] [Artos - Otsuka CSR V.4.docx] [Full Document]' },
        { iconSrc: SidebarLines, text: 'Send notification to', subtext: '[all users in system] via email' },
    ];

    const triggerItems = [
        { iconSrc: SidebarPen, text: 'On Document Update', subtext: 'Update an entire document when something happens.' },
        { iconSrc: SidebarLines, text: 'On Section Update', subtext: 'Update a specific section when something happens.' },
    ];

    const components = isSidebarOpen === "action" ? actionItems : isSidebarOpen === "trigger" ? triggerItems : [];

    const [currentSidebarComponent, setCurrentSidebarComponent] = useState('default');

    const handleButtonClick = (value) => {
        setCurrentSidebarComponent(value);
    };

    // this code isn't fully implemented - need to come back to this later 
    const renderSidebarContent = () => {
        switch (currentSidebarComponent) {
            case 'Update Document':
                return <UpdateDocSidebar />;
            case 'default':
            default:
                return < SidebarComponent title={sidebarTitle} items={components} onClick={handleButtonClick}/>;
        }
    };
    

    return (
        <div className='full-width-container' style={{ backgroundColor: '#F9FAFB' }}>
            <div className='inner-container' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflow: 'auto', maxWidth:'90vw' }}>
                <div className='header-container' style={{ marginLeft: '20px', marginRight: '20px'}}>
                    <div className='header-bar'>
                        <div className="main-panel-header">
                            <h2 className="top-main-panel" >Automation Details</h2>
                        </div>
                        <div className='side-by-side-container'>
                            <ToggleComponent onClick={navigateToDocumentWithUrl} text='Create' width="15%" icon="fas fa-plus" className="main-button" />
                            <button className="delete-button" onClick={handleDelete}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div className='main-panel-content' style={{maxWidth:'60vw'}}>
                        <div className='folder-box' style={{width:'75%'}}>
                            <div className='folder-header' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', padding: '0 10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2 className='header-within-container'>Trigger</h2>
                                </div>
                                <div className='header-bar'>
                                    <p>This is the action that causes this automation to run.</p>
                                    <MainButton onClick={triggerSidebar} text='Edit' width="15%" icon="fas fa-edit" className="unfilled-not-neg-button" />
                                </div>
                            </div>
                            <div className='document-card' style={{ border: '1px solid #E5E7EB', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px', marginBottom: '5px', backgroundColor: 'white', borderRadius: '2px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                                    <div className='automation-item'>
                                        <img src={dots} alt="Dots" style={{ marginRight: '20px' }} />
                                        <p>Update in</p>
                                        <select style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                            <option value="veeva">Veeva</option>
                                            <option value="box">Box</option>
                                            <option value="sharepoint">Sharepoint</option>
                                        </select>

                                        <select style={{ marginRight: '10px', backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                            <option value="csr">Artos-12345 CSR1.docx</option>
                                            <option value="dsur">Artos AI Demo DSUR Final Version.docx</option>
                                        </select>

                                        <select style={{ backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                            <option value="adverse-event-table">Full Document</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='folder-box' style={{width:'75%'}}>
                        <div className='folder-header' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', padding: '0 10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h2 className='header-within-container'>Action</h2>
                            </div>
                            <div className='header-bar' >
                                <p>Specify the actions that occur after the trigger.</p>
                                <MainButton onClick={actionSidebar} text='Edit' width="15%" icon="fas fa-edit" className="unfilled-not-neg-button" />
                            </div>
                        </div>
                        <div className='document-card' style={{ border: '1px solid #E5E7EB', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px', marginBottom: '5px', backgroundColor: 'white', borderRadius: '2px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={dots} alt="Dots" style={{ marginRight: '20px' }} />
                                    <p>Update in</p>
                                    <select style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                        <option value="artos">Artos</option>
                                        <option value="veeva">Veeva</option>
                                        <option value="box">Box</option>
                                        <option value="sharepoint">Sharepoint</option>
                                    </select>

                                    <select style={{ marginRight: '10px', backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                        <option value="csr">Artos-12345 CSR1 Modified.docx</option>
                                        <option value="dsur">Artos AI Demo DSUR Modified.docx</option>
                                    </select>

                                    <select style={{ backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                        <option value="adverse-event-table">Full Document</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className='document-card' style={{ border: '1px solid #E5E7EB', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px', marginBottom: '5px', backgroundColor: 'white', borderRadius: '2px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={dots} alt="Dots" style={{ marginRight: '20px' }} />
                                    <p>Send email to:</p>
                                    <select style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                        <option value="allUsers">All Users</option>
                                        <option value="oneTeam">Team 1</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* <div className='folder-box' style={{minWidth:'60vw', width:'75%'}}>
                        <div className='folder-header' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', padding: '0 10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h2 className='header-within-container'>Action</h2>
                            </div>
                            <div className='header-bar' >
                                <p>Specify the actions that occur after the trigger.</p>
                                <MainButton onClick={actionSidebar} text='Edit' width="15%" icon="fas fa-edit" className="unfilled-not-neg-button" />
                            </div>
                        </div>
                        <div className='document-card' style={{ border: '1px solid #E5E7EB', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px', marginBottom: '5px', backgroundColor: 'white', borderRadius: '2px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={dots} alt="Dots" style={{ marginRight: '20px' }} />
                                    <p>Update in</p>
                                    <select style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                        <option value="artos">Artos</option>
                                        <option value="veeva">Veeva</option>
                                        <option value="box">Box</option>
                                        <option value="sharepoint">Sharepoint</option>
                                    </select>

                                    <select style={{ marginRight: '10px', backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                        <option value="csr">Artos-12345 CSR1 Modified.docx</option>
                                        <option value="dsur">Artos AI Demo DSUR Modified.docx</option>
                                    </select>

                                    <select style={{ backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                        <option value="adverse-event-table">Full Document</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className='document-card' style={{ border: '1px solid #E5E7EB', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px', marginBottom: '5px', backgroundColor: 'white', borderRadius: '2px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={dots} alt="Dots" style={{ marginRight: '20px' }} />
                                    <p>Send email to:</p>
                                    <select style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#EFF6FF', borderRadius: '2px', color: '#1F477D', border: 'none' }}>
                                        <option value="allUsers">All Users</option>
                                        <option value="oneTeam">Team 1</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='side-by-side-container' style={{ marginTop: '10px', justifyContent: 'flex-start', alignItems: 'right', maxWidth:'82vw' }}>
                        <MainButton onClick={handleClick} text='Save' width="15%" icon="fas fa-save" className="main-button" />
                    </div>

                </div>
                {/* <div className={`sidebar-container ${isSidebarOpen ? 'sidebar-open' : ''}`}> */}
                <div>
                    {/* <SidebarComponent title={sidebarTitle} items={components}/> */}
                    {renderSidebarContent()}
                </div>
            </div>



            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <div className='modal-background'>
                    <Slide direction='up' in={step === 1} mountOnEnter unmountOnExit >
                        <div>
                            <div className='modal-content'>

                                {loading ? <div>Saving Automation...</div> :
                                    <>
                                        <div>Your automation has saved and updated a file</div>
                                        <button className='generate-modal-submit' style={{ padding: '0', marginLeft: '0', marginTop: '20px', width: '200px' }} onClick={() => navigateToDocumentWithUrl()}>
                                            View Updated File
                                        </button>
                                    </>


                                }

                            </div>
                        </div>
                    </Slide>
                    <Slide direction='up' in={step === 2} mountOnEnter unmountOnExit >
                        <div className='modal-content'>
                            <div className='webviewContainer' style={{ height: '60vh', width: '75%', visibility: 'visible' }}>
                                <PDFWebViewComponent initialFile={'https://otsukateststorage.blob.core.windows.net/output/Artos - Otsuka CSR modified.docx'} id={'123'} startPageNumber={51}></PDFWebViewComponent>
                            </div>
                        </div>
                    </Slide>
                </div>
            </Modal>
        </div>
    )

}

export default IndividualAutomationPage;