import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    Alert,
    Divider,
    Fade,
    Modal,
    ThemeProvider,
    Snackbar,
} from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { useAuthInfo } from '@propelauth/react'
import CreateDocumentTextInput from '../../components/CreateDocumentTextInput'
import AddSourcesComponent from '../../components/AddSourcesComponent'
import RulesComponent from '../../components/inputs/rules/RulesComponent'
import ComplexLoaderComponent from '../../components/ComplexLoaderComponent'
import SourceSelectionModal from './steps/SourceSelectionModal'
import {
    getTemplateTypes,
    getOutlineFromTemplates,
    listAllFiles,
    combinedCreateNewDocument,
    uploadMultipleFilesUpdated,
    getAllRulesets,
} from '../../utils/utils'
import '../../App.css'
import { ArtosTheme } from '../../components/Themes'
import { useDemo } from '../../contexts/DemoContext'
import BulkUploadComponent from './steps/BulkUploadComponent'
import TabularDataGenerationComponent from './steps/TabularDataGenerationComponent'

function CreateDocumentPage() {
    const location = useLocation()
    const { id: documentId } = location.state || {}

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [step, setStep] = useState(0)
    const [documentName, setDocumentName] = useState('')
    const [productName, setProductName] = useState('')
    const [documentType, setDocumentType] = useState('')
    const [multipleFileList, setMultipleFileList] = useState([])
    const [multipleFileNameList, setMultipleFileNameList] = useState([])
    const [multipleFileContentList, setMultipleFileContentList] = useState([])
    const [contentPlanFileList, setContentPlanFileList] = useState([])
    const [contentPlanNameList, setContentPlanNameList] = useState([])
    const [contentPlanContentList, setContentPlanContentList] = useState([])

    const [outlineSections, setOutlineSections] = useState([])
    const [initialOutlineSections, setInitialOutlineSections] = useState([])
    const [rules, setRules] = useState([])
    const [updatedRules, setUpdatedRules] = useState([])
    const [templateTypes, setTemplateTypes] = useState([])
    const [sourceModalOpen, setSourceModalOpen] = useState(false)
    const [inputFiles, setInputFiles] = useState([])
    const [isLoadingInputFiles, setIsLoadingInputFiles] = useState(true)
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [selectedCloudFiles, setSelectedCloudFiles] = useState([])
    const [showPkTables, setShowPkTables] = useState(false)
    const [enableBulkUpload, setEnableBulkUpload] = useState(false)
    const [showComplexLoader, setShowComplexLoader] = useState(false)
    const [rulesetList, setRulesetList] = useState([])

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [isDocumentTypeLoading, setIsDocumentTypeLoading] = useState(true)

    const { isDemo, setIsDemo } = useDemo()

    const navigate = useNavigate()
    const auth = useAuthInfo()
    const orgs = auth.orgHelper.getOrgs()
    const org = orgs[0].orgId

    useEffect(() => {
        const shouldShowPkTables =
            (documentType === 'Module 2.6 PK Table 2.6.5.1' ||
                documentType === 'Module 2.6 PK Table 2.6.5') &&
            // Replace with Biohaven org Id
            org === '12345'
        setShowPkTables(shouldShowPkTables)
    }, [documentType, org])

    useEffect(() => {
        async function fetchRulesets() {
            const orgAccessToken = await getOrgAccessToken(org)
            const rulesets = await getAllRulesets(orgAccessToken)
            console.log('rulesets:', rulesets)
            setRulesetList(rulesets)
        }
        fetchRulesets()
    }, [documentType])

    const [steps, setSteps] = useState([])

    useEffect(() => {
        const baseSteps = ['Create Document', 'Add Source(s)', 'Define Rules']

        if (showPkTables) {
            baseSteps.push('Generate PK Tables')
        }

        if (enableBulkUpload) {
            baseSteps.push('Bulk Upload')
        }

        baseSteps.push('Generate Document')

        setSteps(baseSteps)
    }, [enableBulkUpload, showPkTables, documentType])

    const handleStepChange = async (direction) => {
        const newStep = step + direction
        if (newStep >= 0 && newStep < steps.length) {
            if (direction > 0 && !validateStep(step)) {
                return
            }
            setStep(newStep)
            await stepPostProcessing(newStep)
        }
    }

    const handleFileUpload = (files, fileContents) => {
        const newFiles = files.map((file, index) => ({
            name: file.name,
            content: fileContents[index],
        }))
        addUniqueFiles(newFiles, false)
    }

    const handleCloudFileSelect = (selectedFiles) => {
        const newFiles = selectedFiles.map((file) => ({
            name: file.name,
            // Add any other necessary properties for cloud files
        }))
        addUniqueFiles(newFiles, true)
    }

    const addUniqueFiles = (newFiles, isCloudFile) => {
        const existingFiles = isCloudFile ? selectedCloudFiles : uploadedFiles
        const setFiles = isCloudFile ? setSelectedCloudFiles : setUploadedFiles

        const uniqueFiles = newFiles.filter(
            (newFile) =>
                !existingFiles.some(
                    (existingFile) => existingFile.name === newFile.name
                )
        )

        if (uniqueFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...uniqueFiles])
        }

        if (uniqueFiles.length < newFiles.length) {
            console.log(
                'Some files were not added because they already exist in the list.'
            )
            // Optionally, you could show a user-friendly message here
        }
    }

    useEffect(() => {
        console.log(outlineSections)
    }, [outlineSections])

    useEffect(() => {
        async function retrieveTemplateTypes() {
            setLoading(true)
            setIsDocumentTypeLoading(true)
            try {
                const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
                const result = await getTemplateTypes(orgAccessToken)
                setTemplateTypes(result)
                setError(false)
            } catch (error) {
                console.error(error)
                setError(true)
            } finally {
                setLoading(false)
                setIsDocumentTypeLoading(false)
            }
        }

        retrieveTemplateTypes()
    }, [])

    useEffect(() => {
        if (documentType) {
            fetchOutline(documentType)
        }
    }, [documentType])

    const fetchOutline = async (docType) => {
        const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
        const outline = await getOutlineFromTemplates(docType, orgAccessToken)
        setOutlineSections(outline)
        setInitialOutlineSections(outline)
    }

    const handleUpdateOutlineSections = (updatedSections) => {
        setOutlineSections(updatedSections)
    }

    const handleRulesChange = (newRules) => {
        setRules((rules) => newRules)
        return
    }

    useEffect(() => {
        setUpdatedRules(rules)
    }, [rules])

    const stepPostProcessing = async (currentStep) => {
        switch (currentStep) {
            case 1: // After document creation step
                try {
                    const orgAccessToken = await getOrgAccessToken(
                        orgs[0].orgId
                    )
                    const files = await listAllFiles('input', orgAccessToken)
                    setInputFiles(files)
                    setIsLoadingInputFiles(false)
                } catch (error) {
                    console.error('Error fetching input files:', error)
                }
                break
            default:
                return
        }
    }

    const handleSourceModalOpen = () => {
        setSourceModalOpen(true)
    }

    const handleSourceModalClose = () => {
        setSourceModalOpen(false)
    }

    const handleSourceSelect = (selectedFiles) => {
        const newFiles = selectedFiles.map((file) => ({
            name: file.name,
            isCloud: true,
        }))
        setSelectedCloudFiles((prevFiles) => {
            const uniqueNewFiles = newFiles.filter(
                (newFile) =>
                    !prevFiles.some(
                        (prevFile) => prevFile.name === newFile.name
                    )
            )
            return [...prevFiles, ...uniqueNewFiles]
        })

        setMultipleFileNameList((prevList) => {
            const uniqueNewNames = newFiles
                .map((file) => file.name)
                .filter((name) => !prevList.includes(name))
            return [...prevList, ...uniqueNewNames]
        })
    }

    const handleMultipleFiles = (files, fileContents) => {
        const newFiles = files.map((file, index) => ({
            name: file.name,
            content: fileContents[index],
            isCloud: false,
        }))
        setUploadedFiles((prevFiles) => {
            const uniqueNewFiles = newFiles.filter(
                (newFile) =>
                    !prevFiles.some(
                        (prevFile) => prevFile.name === newFile.name
                    )
            )
            return [...prevFiles, ...uniqueNewFiles]
        })

        // Update multipleFileNameList
        setMultipleFileNameList((prevList) => {
            const uniqueNewNames = newFiles
                .map((file) => file.name)
                .filter((name) => !prevList.includes(name))
            return [...prevList, ...uniqueNewNames]
        })
    }

    const handleMultipleFilesOriginal = (files, fileContents) => {
        try {
            // Handle saving the file content or any other logic as needed for each file
            const newFiles = []
            console.log('files here', files)

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const content = fileContents[i]
                newFiles.push({ name: file.name, content })
                console.log('newFiles', newFiles)
            }

            // Update React state with the array of file objects
            setContentPlanFileList(newFiles)
            setContentPlanNameList(newFiles.map((file) => file.name))
            setContentPlanContentList(fileContents)
        } catch (e) {
            console.log('Error in handling files', e)
        }
    }

    const removeFile = (fileName, isCloud) => {
        if (isCloud) {
            setSelectedCloudFiles((prev) =>
                prev.filter((file) => file.name !== fileName)
            )
        } else {
            setUploadedFiles((prev) =>
                prev.filter((file) => file.name !== fileName)
            )
        }
        setMultipleFileNameList((prev) =>
            prev.filter((name) => name !== fileName)
        )
    }

    const originalRemoveFile = (index) => {
        const newFileList = [...contentPlanFileList]
        newFileList.splice(index, 1)
        setContentPlanFileList(newFileList) // Assuming your state setter is named like this
    }

    async function getOrgAccessToken(orgId) {
        console.log(orgId + ' is orgId')
        const orgAccessToken = await auth.tokens.getAccessTokenForOrg(orgId)
        return orgAccessToken
    }

    const formattedTemplateTypes = templateTypes.map((type, index) => ({
        id: `category${index + 1}`,
        label: type,
    }))

    let categories = formattedTemplateTypes

    const handleGenerateDocument = async () => {
        setLoading(true)
        try {
            const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
            var combinedOutline = ``
            outlineSections.forEach((section) => {
                combinedOutline += `${section.sectionName}\n`
                // if (section.active) {
                //     combinedOutline += `${section.sectionName}\n`
                // }
            })
            const hasUploadedFiles = uploadedFiles.length > 0

            setShowComplexLoader(true)
            // if (!isDemo) {
            const result = await combinedCreateNewDocument(
                multipleFileNameList,
                documentName,
                productName,
                documentType,
                combinedOutline,
                documentId,
                orgAccessToken,
                updatedRules,
                hasUploadedFiles
            )
            console.log('Document generated:', result)
            // }
        } catch (error) {
            console.error('Error generating document:', error)
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    // -2 because we are considering "generate document" as the last step, even though it is not a real step.
    const isLastStep = (currentStep) => currentStep === steps.length - 2

    const renderStepContent = (currentStep) => {
        if (showComplexLoader) {
            return (
                <ComplexLoaderComponent
                    onNextClick1={() => {
                        navigate('/')
                    }}
                    documentType={documentType}
                    orgId={org}
                />
            )
        }

        const stepComponents = {
            'Create Document': (
                <CreateDocumentTextInput
                    onNextClick={() => handleStepChange(1)}
                    categories={categories}
                    setDocumentName={setDocumentName}
                    setDocumentType={setDocumentType}
                    setProductName={setProductName}
                    setEnableBulkUpload={setEnableBulkUpload}
                    enableBulkUpload={enableBulkUpload}
                    isDocumentTypeLoading={isDocumentTypeLoading}
                />
            ),
            'Add Source(s)': (
                <AddSourcesComponent
                    onNextClick1={async () => {
                        const orgAccessToken = await getOrgAccessToken(
                            orgs[0].orgId
                        )
                        uploadMultipleFilesUpdated(
                            setLoading,
                            uploadedFiles,
                            uploadedFiles.map((file) => file.content),
                            setError,
                            'input',
                            orgAccessToken
                        )
                        handleStepChange(1)
                    }}
                    onBackClick={() => handleStepChange(-1)}
                    sourceClick={handleSourceModalOpen}
                    handleFiles={handleMultipleFiles}
                    uploadedFiles={uploadedFiles}
                    selectedCloudFiles={selectedCloudFiles}
                    removeFile={removeFile}
                />
            ),
            'Define Rules': (
                <RulesComponent
                    templateSections={initialOutlineSections}
                    sourceFiles={[...uploadedFiles, ...selectedCloudFiles]}
                    onRulesChange={handleRulesChange}
                    onNextClick={() => {
                        if (isLastStep(currentStep)) {
                            handleStepChange(1)
                            handleGenerateDocument()
                        } else {
                            handleStepChange(1)
                        }
                    }}
                    onBackClick={() => handleStepChange(-1)}
                    onUpdateOutlineSections={handleUpdateOutlineSections}
                    enableBulkUpload={enableBulkUpload}
                    isLastStep={isLastStep(currentStep)}
                    documentType={documentType}
                    handleFiles={handleMultipleFilesOriginal}
                    removeFile={originalRemoveFile}
                    multipleFileList={contentPlanFileList}
                    multipleFileNameList={contentPlanNameList}
                    multipleFileContentList={contentPlanContentList}
                    rulesetList={rulesetList}
                />
            ),
            'Generate PK Tables': (
                <TabularDataGenerationComponent
                    onNextClick={async () => {
                        if (isLastStep(currentStep)) {
                            await handleGenerateDocument()
                        } else {
                            handleStepChange(1)
                        }
                    }}
                    onBackClick={() => handleStepChange(-1)}
                    documentName={documentName}
                    productName={productName}
                    documentType={documentType}
                    documentSet={documentId}
                    uploadedFiles={uploadedFiles}
                    selectedCloudFiles={selectedCloudFiles}
                    getOrgAccessToken={() => getOrgAccessToken(orgs[0].orgId)}
                    isLastStep={isLastStep(currentStep)}
                />
            ),
            'Bulk Upload': (
                <BulkUploadComponent
                    onBackClick={() => handleStepChange(-1)}
                    onNextClick={async () => {
                        if (isLastStep(currentStep)) {
                            await handleGenerateDocument()
                        } else {
                            handleStepChange(1)
                        }
                    }}
                    documentType={documentType}
                    getOrgAccessToken={() => getOrgAccessToken(orgs[0].orgId)}
                    isLastStep={isLastStep(currentStep)}
                    documentId={documentId}
                />
            ),
        }

        return stepComponents[steps[currentStep]] || null
    }

    const validateStep = (currentStep) => {
        switch (currentStep) {
            case 0:
                if (
                    !documentName.trim() ||
                    !productName.trim() ||
                    !documentType
                ) {
                    setSnackbarMessage(
                        'Please fill out all fields before proceeding'
                    )
                    setSnackbarOpen(true)
                    return false
                }
                return true
            case 1:
                if (
                    uploadedFiles.length === 0 &&
                    selectedCloudFiles.length === 0
                ) {
                    setSnackbarMessage(
                        'Please select or upload at least one source'
                    )
                    setSnackbarOpen(true)
                    return false
                }
                return true
            default:
                return true
        }
    }

    return (
        <div
            className="full-width-container"
            style={{ backgroundColor: '#F9FAFB' }}
        >
            <div className="inner-container">
                <div className="header-container">
                    <div className="main-panel-header">
                        <h2 className="top-main-panel">
                            Create a new document
                        </h2>
                    </div>
                    <ThemeProvider theme={ArtosTheme}>
                        {isDemo && (
                            <Alert
                                severity="info"
                                sx={{
                                    mb: 2,
                                    maxWidth: 'fit-content',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                            >
                                This workflow is a demonstration in a sandbox
                                environment. Some functionality may be limited.
                            </Alert>
                        )}
                        <Stepper activeStep={step} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </ThemeProvider>
                    <br />

                    <div className="main-panel-content">
                        <Fade
                            in={true}
                            mountOnEnter
                            unmountOnExit
                            timeout={{ enter: 450, exit: 0 }}
                            easing={{
                                enter: 'cubic-bezier(0.4, 0, 0.2, 1)',
                                exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
                            }}
                        >
                            <div>{renderStepContent(step)}</div>
                        </Fade>
                    </div>
                </div>
            </div>

            <SourceSelectionModal
                open={sourceModalOpen}
                handleClose={handleSourceModalClose}
                onSourceSelect={handleSourceSelect}
                documentName={documentName}
                productName={productName}
                inputFiles={inputFiles}
                isLoadingInputFiles={isLoadingInputFiles}
                documentType={documentType}
                outline={outlineSections}
                id={documentId}
                handleStepChange={handleStepChange}
                setError={setError}
                orgId={org}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity="warning"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default CreateDocumentPage
