import React from 'react'
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom'
import {
    Button,
    Container,
    FormControl,
    TextField,
    FormControlLabel,
    h4,
    Radio,
    RadioGroup,
    Slide,
    Skeleton,
    Box,
    Alert,
} from '@mui/material'
import '../App.css'
import Modal from '@mui/material/Modal'
import { DataGrid } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { MultipleFileUploader } from '../components/MultipleFileUploader'
import PDFWebViewComponent from '../components/PDFWebViewComponent'
import {
    uploadMultipleFilesUpdated,
    generateOutline,
    generateBoth,
    createINDModule2Section,
    extractAndStoreMultiple,
    createOutputVectorstore,
    combinedCreateNewDocument,
    getTemplateTypes,
    getOutlineFromTemplates,
} from '../utils/utils'
import {
    getOneDocumentSet,
    listAllFiles,
    createMod26PKTable,
} from '../utils/utils'
import plus from '../icons/Plus.svg'
import paperclip from '../icons/Paper clip.svg'
import { useAuthInfo } from '@propelauth/react'
import { useDemo } from '../contexts/DemoContext'

function DocumentSetsPage() {
    const { id } = useParams()
    const { isDemo, setIsDemo } = useDemo()

    console.log('document set id', id) // This will log the id from the URL

    const [step, setStep] = useState(0)
    // const documentTypeList = ['Nonclinical Overview', 'Document Type 1', 'Document Type 2', 'Document Type 3', 'Document Type 4', 'Document Type 5']
    const [documentType, setDocumentType] = useState('')

    const [documentName, setDocumentName] = useState('')
    const [productName, setProductName] = useState('')

    const [outline, setOutline] = useState('')
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const [multipleFileList, setMultipleFileList] = useState([])
    const [multipleFileNameList, setMultipleFileNameList] = useState([])
    const [multipleFileContentList, setMultipleFileContentList] = useState([])

    const [inputFiles, setInputFiles] = useState([])

    const [viewFileUrl, setViewFileUrl] = useState('')

    const [allDocuments, setAllDocuments] = useState([])

    const [selectedFiles, setSelectedFiles] = useState([])
    const [existingMultipleFileNameList, setExistingMultipleFileNameList] =
        useState([])

    const [templateTypes, setTemplateTypes] = useState([])

    const [documentWarningModal, setDocumentWarningModal] = useState(false)

    const authInfo = useAuthInfo()

    const orgs = authInfo.orgHelper.getOrgs()

    async function getOrgAccessToken(orgId) {
        const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(orgId)
        // console.log('orgAccessToken', orgAccessToken)
        return orgAccessToken
    }

    useEffect(() => {
        async function retrieveOutline() {
            setLoading(true) // Start loading
            try {
                const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
                const result = await getOutlineFromTemplates(
                    documentType,
                    orgAccessToken
                ) // Assuming getDocumentSets returns the data
                console.log('outline here:', result)
                setOutline(result) // Update state with fetched data
                setError(false) // Reset error state if successful
            } catch (error) {
                console.error(error)
                setError(true) // Set error state
            } finally {
                setLoading(false) // End loading regardless of outcome
            }
        }

        if (documentType) {
            retrieveOutline()
        }
    }, [documentType])

    useEffect(() => {
        async function retrieveData() {
            setLoading(true) // Start loading

            const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
            try {
                const result = await getOneDocumentSet(
                    setLoading,
                    setError,
                    id,
                    orgAccessToken
                ) // Assuming getDocumentSets returns the data
                // console.log('result', result)
                // setAllDocuments(result); // Update state with fetched data
                // setError(false); // Reset error state if successful
                if (result && result.length > 0) {
                    setAllDocuments(result) // Update state with fetched data
                } else {
                    console.log('No documents found')
                    setAllDocuments([]) // Set document sets to an empty array
                }
            } catch (error) {
                console.error(error)
                setError(true) // Set error state
            } finally {
                setLoading(false) // End loading regardless of outcome
                setError(false)
            }
        }

        async function retrieveInputs() {
            setLoading(true) // Start loading
            try {
                const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
                const result = await listAllFiles('input', orgAccessToken) // Assuming getDocumentSets returns the data
                console.log('inputs', result)
                setInputFiles(result) // Update state with fetched data
                setError(false) // Reset error state if successful
            } catch (error) {
                console.error(error)
                setError(true) // Set error state
            } finally {
                setLoading(false) // End loading regardless of outcome
            }
        }

        async function retrieveTemplateTypes() {
            setLoading(true) // Start loading
            try {
                const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
                const result = await getTemplateTypes(orgAccessToken) // Assuming getDocumentSets returns the data
                console.log('result', result)
                setTemplateTypes(result) // Update state with fetched data
                setError(false) // Reset error state if successful
            } catch (error) {
                console.error(error)
                setError(true) // Set error state
            } finally {
                setLoading(false) // End loading regardless of outcome
            }
        }
        retrieveData()
        retrieveInputs()
        retrieveTemplateTypes()
    }, [])

    const navigate = useNavigate()

    const navigateToDocumentWithUrl = (params) => {
        navigate(`/document/${params.id}`)
    }

    const navigateToPageWithUrl = (params, id) => {
        navigate(`/${params}`, { state: { id } })
    }

    // const url = "https://biohaventeststorage.blob.core.windows.net/output/BHV%202100%20NCO%20Test%202%206-30.docx?sp=r&st=2024-06-30T23:07:34Z&se=2024-07-01T07:07:34Z&spr=https&sv=2022-11-02&sr=b&sig=GOnFNWnExYqZ4mbWRvwQKgvQfFXc9tZNIzrkBjdl2OE%3D"

    const resetAllFiles = () => {
        setMultipleFileList([])
        setMultipleFileNameList([])
        setMultipleFileContentList([])
    }

    const handleContinue = (increment = 1) => {
        setStep((prevStep) => prevStep + increment)
    }

    const handleReturn = () => {
        setStep((prevStep) => prevStep - 1)
    }

    const [newDocumentModal, setNewDocumentModal] = useState(false)

    const handleNewDocumentModalOpen = () => setNewDocumentModal(true)
    const handleNewDocumentModalClose = () => setNewDocumentModal(false)

    const handleClose = () => {
        handleNewDocumentModalClose()
        resetAllFiles()
        setDocumentType('')
        setDocumentName('')
        setProductName('')
        setOutline('')
        setStep(0)
    }

    // Define columns for the DataGrid
    const columns = [
        { field: 'doc_name', headerName: 'Document Name', flex: 2 },
        { field: 'doc_set', headerName: 'Document Set', flex: 1 },
        { field: 'doc_type', headerName: 'Document Type', flex: 2 },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            cellClassName: (params) => {
                switch (params.value) {
                    case 'N/A':
                        return 'status-button off'
                    case 'Generating':
                        return 'status-button maybe'
                    case 'Ready':
                        return 'status-button on'
                    default:
                        return 'status-button'
                }
            },
        },
        { field: 'created', headerName: 'Created', flex: 1 },
        { field: 'last_updated', headerName: 'Last Updated', flex: 1 },
        // { field: 'owner', headerName: 'Owner', flex: 1 }
    ]

    // Transform documents into rows for the DataGrid
    const rows = allDocuments.map((document) => ({
        id: document.id,
        doc_name: document.doc_name,
        doc_set: document.doc_set,
        doc_type: document.doc_type,
        created: document.created,
        last_updated: document.last_updated,
        owner: document.owner,
        status: document.status,
    }))

    const handleSelectFile = (index, fileName, isRemove) => {
        if (isRemove) {
            // Remove the file from selectedFiles and existingMultipleFileNameList
            setSelectedFiles(selectedFiles.filter((i) => i !== index))
            setExistingMultipleFileNameList(
                existingMultipleFileNameList.filter((name) => name !== fileName)
            )
        } else {
            // Add the file to selectedFiles and existingMultipleFileNameList
            setSelectedFiles([...selectedFiles, index])
            setExistingMultipleFileNameList([
                ...existingMultipleFileNameList,
                fileName,
            ])
        }
    }

    const handleMultipleFiles = (files, fileContents) => {
        try {
            // Handle saving the file content or any other logic as needed for each file
            const newFiles = []
            console.log('files here', files)

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const content = fileContents[i]
                newFiles.push({ name: file.name, content })
                console.log('newFiles', newFiles)
            }

            // Update React state with the array of file objects
            setMultipleFileList(newFiles)
            setMultipleFileNameList(newFiles.map((file) => file.name))
            setMultipleFileContentList(fileContents)
        } catch (e) {
            console.log('Error in handling files', e)
        }
    }

    // Inside your target component
    const location = useLocation()
    const rowDetails = location.state

    // console.log(rowDetails); // This will log the row object passed as state

    const removeFile = (index) => {
        const newFileList = [...multipleFileList]
        newFileList.splice(index, 1)
        setMultipleFileList(newFileList) // Assuming your state setter is named like this
    }

    const LoadingSkeleton = () => (
        <div className="loading-skeleton" style={{ padding: '25px' }}>
            <Box border={1} borderColor="grey.300" borderRadius={1}>
                <Box
                    display="flex"
                    borderBottom={1}
                    borderColor="grey.300"
                    p={1}
                >
                    <Skeleton variant="text" width="40%" height={30} />
                    <Skeleton
                        variant="text"
                        width="30%"
                        height={30}
                        style={{ marginLeft: '16px' }}
                    />
                    <Skeleton
                        variant="text"
                        width="20%"
                        height={30}
                        style={{ marginLeft: '16px' }}
                    />
                </Box>

                {[...Array(4)].map((_, index) => (
                    <Box
                        key={index}
                        display="flex"
                        borderBottom={1}
                        borderColor="grey.300"
                        p={1}
                    >
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton
                            variant="text"
                            width="30%"
                            height={20}
                            style={{ marginLeft: '16px' }}
                        />
                        <Skeleton
                            variant="text"
                            width="20%"
                            height={20}
                            style={{ marginLeft: '16px' }}
                        />
                    </Box>
                ))}
            </Box>
        </div>
    )

    return (
        <div className="full-width-container">
            <div className="inner-container">
                <div className="header-container">
                    <div className="main-panel-header">
                        <h2 className="top-main-panel">Document Set: {id}</h2>
                    </div>
                    {isDemo && (
                        <Alert severity="info" sx={{ mb: 2 }}>
                            This workflow is a demonstration in a sandbox
                            environment. No new documents can be generated.
                        </Alert>
                    )}
                    <div
                        className="secondary-header-container"
                        style={isDemo ? { marginTop: '0px' } : {}}
                    >
                        <p className="secondary-text">
                            Manage all documents related to your {id} Document
                            Set here.
                        </p>
                        <div
                            style={{ backgroundColor: '#1F477D' }}
                            className="new-document-button"
                            onClick={() =>
                                navigateToPageWithUrl('createDocument', id)
                            }
                        >
                            <p style={{ color: 'white' }}>Create Document</p>
                        </div>
                    </div>
                </div>

                {!loading && error && <div>Error...</div>}
                {loading ? (
                    <LoadingSkeleton />
                ) : allDocuments.length > 0 ? (
                    <div className="datagrid">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                            onRowClick={(params) => {
                                if (params.row.status === 'Ready') {
                                    navigateToDocumentWithUrl(
                                        { id: params.row.id },
                                        params.row.url
                                    )
                                } else {
                                    setDocumentWarningModal(true)
                                }
                            }}
                        />
                    </div>
                ) : (
                    <div>No documents found!</div>
                )}
            </div>

            <Modal
                open={newDocumentModal}
                onClose={handleNewDocumentModalClose}
            >
                <div className="modal-background">
                    <Slide
                        direction="up"
                        in={step === 0}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            <h2>Select Document Type</h2>
                            {/* <div className='button-list'>
                            <button className='document-type-button' onClick={() => setDocumentType('Nonclinical Overview')}>Nonclinical Overview</button>
                        </div> */}
                            <div className="button-list">
                                {templateTypes.map((type) => (
                                    <button
                                        key={type}
                                        className={`document-type-button ${
                                            documentType === type
                                                ? 'selected'
                                                : ''
                                        }`}
                                        onClick={() => setDocumentType(type)}
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>
                            {documentType && (
                                <div className="modal-button-container">
                                    <button
                                        onClick={() => handleContinue()}
                                        className="generate-modal-submit"
                                    >
                                        <p className="text-white">Confirm</p>
                                    </button>
                                </div>
                            )}
                        </div>
                    </Slide>
                    <Slide
                        direction="up"
                        in={step === 1}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            <h2>Enter Document Info</h2>
                            <div className="form-container">
                                <div className="input-wrapper">
                                    <label htmlFor="documentName">
                                        Document Name:
                                    </label>
                                    <input
                                        type="text"
                                        id="documentName"
                                        value={documentName}
                                        onChange={(e) =>
                                            setDocumentName(e.target.value)
                                        }
                                    />
                                </div>
                                {documentType !=
                                    'Module 2.6 PK Table 2.6.5.1' && (
                                    <>
                                        <div className="input-wrapper">
                                            <label htmlFor="productName">
                                                Product Name:
                                            </label>
                                            <input
                                                type="text"
                                                id="productName"
                                                value={productName}
                                                onChange={(e) =>
                                                    setProductName(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="outline">
                                                Outline:
                                            </label>
                                            <textarea
                                                id="outline"
                                                value={outline}
                                                onChange={(e) =>
                                                    setOutline(e.target.value)
                                                }
                                                rows="10"
                                            ></textarea>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="modal-button-container">
                                <button
                                    onClick={() => handleReturn()}
                                    className="generate-modal-submit grey-button"
                                >
                                    <p className="text-black">Return </p>
                                </button>
                                <button
                                    onClick={() => handleContinue()}
                                    className="generate-modal-submit"
                                >
                                    <p className="text-white">Confirm</p>
                                </button>
                            </div>
                        </div>
                    </Slide>
                    <Slide
                        direction="up"
                        in={step === 2}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            <h2>Upload Documents</h2>
                            <div className="form-container">
                                <div
                                    className={`input-container ${
                                        multipleFileList.length < 1
                                            ? 'dashed-border'
                                            : ''
                                    }`}
                                >
                                    {multipleFileList.length >= 1 && (
                                        <p style={{ textAlign: 'left' }}>
                                            Files Selected:
                                        </p>
                                    )}
                                    {multipleFileList.length == 0 && (
                                        <MultipleFileUploader
                                            handleFiles={handleMultipleFiles}
                                        />
                                    )}
                                    {multipleFileList.length >= 1 && (
                                        <>
                                            {multipleFileList.map(
                                                (file, index) => (
                                                    <div
                                                        className="multiple-file-upload-card"
                                                        key={file.name}
                                                    >
                                                        <div className="file-info">
                                                            <img
                                                                src={paperclip}
                                                                alt="Paperclip"
                                                            />
                                                            <p className="file-name">
                                                                {file.name}
                                                            </p>
                                                        </div>
                                                        <button
                                                            className="remove-file-btn"
                                                            onClick={() =>
                                                                removeFile(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <h2>OR</h2>
                            <div className="form-container">
                                <button
                                    className="general-select-btn"
                                    style={{ width: '20vw' }}
                                    onClick={() => handleContinue()}
                                >
                                    <p className="text-black">
                                        Choose From Library
                                    </p>
                                </button>
                            </div>
                            <div className="modal-button-container">
                                <button
                                    className="generate-modal-submit grey-button"
                                    onClick={() => handleReturn()}
                                >
                                    <p className="text-black">Return</p>
                                </button>
                                {multipleFileList && (
                                    <button
                                        className="generate-modal-submit"
                                        onClick={async () => {
                                            try {
                                                handleContinue(3)
                                                const orgAccessToken =
                                                    await getOrgAccessToken(
                                                        orgs[0].orgId
                                                    )
                                                await uploadMultipleFilesUpdated(
                                                    setLoading,
                                                    multipleFileList,
                                                    multipleFileContentList,
                                                    setError,
                                                    'input',
                                                    orgAccessToken
                                                )

                                                if (
                                                    documentType ===
                                                    'Module 2.6 PK Table 2.6.5.1'
                                                ) {
                                                    await createMod26PKTable(
                                                        multipleFileNameList,
                                                        'input',
                                                        documentType,
                                                        documentName
                                                    )
                                                } else {
                                                    await combinedCreateNewDocument(
                                                        multipleFileNameList,
                                                        documentName,
                                                        productName,
                                                        documentType,
                                                        outline,
                                                        id,
                                                        orgAccessToken,
                                                        // Rules are empty here
                                                        [],
                                                        true
                                                    )
                                                }
                                            } catch (error) {
                                                setError(true)
                                            }
                                            // await generateOutline(setLoading, setError, multipleFileNameList, outline, documentName, productName, documentType);
                                            // await generateBoth(setLoading, setError, documentName, productName, documentType);
                                            // // const url = await createINDModule2Section(setLoading, setError, multipleFileNameList, documentName, productName, 'input');
                                            // // navigateToDocumentWithUrl({ id: id }, url);
                                            // const doc_id = await createINDModule2Section(setLoading, setError, multipleFileNameList, documentName, productName, 'input');
                                            // await createOutputVectorstore(doc_id, 'output')
                                            // navigateToDocumentWithUrl({ id: doc_id });
                                        }}
                                    >
                                        Generate
                                    </button>
                                )}
                            </div>
                        </div>
                    </Slide>
                    <Slide
                        direction="up"
                        in={step === 3}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            <h2>Choose From Library</h2>
                            <div
                                className="files-container"
                                style={{ maxHeight: '40vh', overflow: 'auto' }}
                            >
                                {inputFiles.map((file, index) => (
                                    <div
                                        key={index}
                                        className={`multiple-file-upload-card ${
                                            selectedFiles.includes(index)
                                                ? 'selected-file-card'
                                                : ''
                                        }`}
                                    >
                                        <div className="file-info">
                                            <p className="file-name">
                                                {file.name}
                                            </p>
                                        </div>
                                        <div className="file-actions">
                                            <button
                                                className="view-file-btn"
                                                style={{ marginRight: '2px' }}
                                                onClick={() => {
                                                    setViewFileUrl(file.url)
                                                    handleContinue()
                                                }}
                                            >
                                                View
                                            </button>
                                            {selectedFiles.includes(index) ? (
                                                <button
                                                    className="remove-file-btn"
                                                    onClick={() =>
                                                        handleSelectFile(
                                                            index,
                                                            file.name,
                                                            true
                                                        )
                                                    }
                                                >
                                                    Remove
                                                </button>
                                            ) : (
                                                <button
                                                    className="select-file-btn"
                                                    onClick={() =>
                                                        handleSelectFile(
                                                            index,
                                                            file.name,
                                                            false
                                                        )
                                                    }
                                                >
                                                    Select
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className="modal-button-container"
                                style={{ margin: '20px' }}
                            >
                                <button
                                    className="generate-modal-submit grey-button"
                                    onClick={() => handleReturn()}
                                >
                                    <p>Return</p>
                                </button>
                                {existingMultipleFileNameList.length >= 1 && (
                                    <button
                                        className="generate-modal-submit"
                                        onClick={async () => {
                                            // await uploadMultipleFilesUpdated(setLoading, multipleFileList, multipleFileContentList, setError, 'input');
                                            // await extractAndStoreMultiple(setLoading, setError, multipleFileNameList,  'input');
                                            try {
                                                handleContinue(2)
                                                const orgAccessToken =
                                                    await getOrgAccessToken(
                                                        orgs[0].orgId
                                                    )
                                                await combinedCreateNewDocument(
                                                    existingMultipleFileNameList,
                                                    documentName,
                                                    productName,
                                                    documentType,
                                                    outline,
                                                    id,
                                                    orgAccessToken,
                                                    // Rules are empty here
                                                    [],
                                                    false
                                                )
                                            } catch (error) {
                                                setError(true)
                                            }

                                            // await generateOutline(setLoading, setError, existingMultipleFileNameList, outline, documentName, productName, documentType);
                                            // await generateBoth(setLoading, setError, documentName, productName, documentType);
                                            // // const url = await createINDModule2Section(setLoading, setError, multipleFileNameList, documentName, productName, 'input');
                                            // // navigateToDocumentWithUrl({ id: id }, url);
                                            // const doc_id = await createINDModule2Section(setLoading, setError, existingMultipleFileNameList, documentName, productName, 'input');
                                            // await createOutputVectorstore(doc_id, 'output')
                                            // navigateToDocumentWithUrl({ id: doc_id });
                                        }}
                                    >
                                        Generate
                                    </button>
                                )}
                            </div>
                        </div>
                    </Slide>
                    <Slide
                        direction="up"
                        in={step === 4}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            <button
                                className="generate-modal-submit"
                                style={{ margin: '10px' }}
                                onClick={() => handleReturn()}
                            >
                                Back
                            </button>
                            <div
                                className="webviewContainer"
                                style={
                                    viewFileUrl
                                        ? {
                                              height: '60vh',
                                              width: '75%',
                                              visibility: 'visible',
                                          }
                                        : {
                                              height: '0',
                                              width: '0',
                                              visibility: 'hidden',
                                          }
                                }
                            >
                                <PDFWebViewComponent
                                    initialFile={viewFileUrl}
                                    id={id}
                                ></PDFWebViewComponent>
                            </div>
                        </div>
                    </Slide>
                    <Slide
                        direction="up"
                        in={step === 5}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            <h2 style={{ textAlign: 'center' }}>
                                Your document is generating. You will recieve a
                                notification when your document is ready.
                            </h2>
                            <button
                                className="generate-modal-submit grey-button"
                                onClick={() => handleClose()}
                            >
                                <p>Close</p>
                            </button>
                        </div>
                    </Slide>
                </div>
            </Modal>
            <Modal
                open={documentWarningModal}
                onClose={() => setDocumentWarningModal(false)}
            >
                <div className="modal-background">
                    <div className="modal-content">
                        <h2>
                            Hold on a bit longer! This document is still
                            generating.
                        </h2>
                        <button
                            className="close-button"
                            onClick={() => setDocumentWarningModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DocumentSetsPage
