import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Box } from '@mui/material'
import { useAuthInfo } from '@propelauth/react'
import '../App.css'
import WebViewComponent from '../components/WebViewComponent'
import { getOneTemplate } from '../utils/utils'
import MainButton from '../components/MainButton'

function EditTemplatePage() {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [documentUrl, setDocumentUrl] = useState('')
    const webViewRef = useRef(null)

    const auth = useAuthInfo()
    const org = auth.orgHelper.getOrgs()[0].orgId

    useEffect(() => {
        async function retrieveData() {
            const orgAccessToken = await auth.tokens.getAccessTokenForOrg(org)
            try {
                const result = await getOneTemplate(id, orgAccessToken)
                setDocumentUrl(result['url'])
                setError(false)
            } catch (error) {
                console.error(error)
                setError(true)
            } finally {
                setLoading(false)
            }
        }
        retrieveData()
    }, [id, org, auth.tokens])

    const handleButtonClick = () => {
        const { selectedText, pageNumber } = webViewRef.current.getText()
        console.log(
            `Selected text: ${selectedText}, Page number: ${pageNumber}`
        )
        return selectedText
    }

    const optionsArray = [
        { value: 'option1', label: 'Patient Narrative' },
        { value: 'option2', label: 'DSUR' },
        { value: 'option3', label: 'CSR' },
    ]

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 64px)',
                overflow: 'hidden',
            }}
        >
            <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
                {documentUrl && (
                    <Box sx={{ flex: 2, overflow: 'hidden' }}>
                        <WebViewComponent
                            ref={webViewRef}
                            initialFile={documentUrl}
                            id={id}
                            onTextSelected={(selectedText, pageNumber) => {
                                console.log(
                                    `Text selected: ${selectedText} on page ${pageNumber}`
                                )
                            }}
                        />
                    </Box>
                )}
                {org === 'c3c7c741-e38b-4dbb-b8c0-be8367d36b7f' && (
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            p: 2,
                            maxWidth: '300px',
                            borderLeft: '1px solid #e0e0e0',
                        }}
                    >
                        <p className="secondary-text">AI System: </p>
                        <select
                            className="dropdown-list"
                            style={{ width: '100%', marginBottom: '20px' }}
                        >
                            {optionsArray.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <p className="secondary-text" style={{ marginTop: -2 }}>
                            Artos builds an "AI System" for each document type
                            to maximize quality. Make sure to select the right
                            AI System for the template being uploaded.
                        </p>
                        <p className="secondary-text">Options:</p>
                        <MainButton
                            onClick={handleButtonClick}
                            text="Save"
                            width="100%"
                            icon="fa-solid fa-arrow-right-arrow-left"
                            className="main-button"
                        />
                        <MainButton
                            onClick={handleButtonClick}
                            text="Replace"
                            width="100%"
                            icon="fa-solid fa-arrow-right-arrow-left"
                            className="unfilled-button"
                            backgroundColor="#FEF3C7"
                            fontColor="#B45309"
                        />
                        <MainButton
                            onClick={handleButtonClick}
                            text="Delete"
                            width="100%"
                            icon="fa-solid fa-arrow-right-arrow-left"
                            className="unfilled-button"
                        />
                    </Box>
                )}
            </Box>
            <Modal open={error}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <h2>Error</h2>
                    <p>
                        There was an error loading the document. This document
                        may still be generating.
                    </p>
                    <button onClick={() => setError(false)}>Close</button>
                </Box>
            </Modal>
        </Box>
    )
}

export default EditTemplatePage
