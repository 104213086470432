export const PRELOADED_DOC_IDS = ['a44b1ab8-fa9f-4c57-8260-66091337dd77']

export const DEFAULT_TABLE_ASSIGNMENTS = [
    {
        id: 'aims-efficacy',
        tableTitle: 'Table 1. AIMS Movement Rating Score',
        sectionName: '8.4.1 Efficacy Assessments',
    },
    {
        id: 'analysis-sets',
        tableTitle: 'Table 2. Analysis Sets Artosipramide',
        sectionName: '8.6.2 Analysis Populations',
    },
    {
        id: 'bars-safety',
        tableTitle: 'Table 3. BARS Global Score',
        sectionName: '8.4.2 Safety Assessments',
    },
    {
        id: 'cssrs-safety',
        tableTitle: 'Table 4. C-SSRS Data',
        sectionName: '8.4.2 Safety Assessments',
    },
    {
        id: 'cgii-efficacy',
        tableTitle: 'Table 5. CGI-I Score',
        sectionName: '8.4.1 Efficacy Assessments',
    },
    {
        id: 'cgis-efficacy',
        tableTitle: 'Table 6. CGI-S Score',
        sectionName: '8.4.1 Efficacy Assessments',
    },
    {
        id: 'lab-params',
        tableTitle: 'Table 7. Clinical Laboratory Parameters',
        sectionName: '8.4.12 Clinical Laboratory Assessments',
    },
    {
        id: 'conmeds',
        tableTitle: 'Table 8. Concomitant Medications',
        sectionName: '8.3.5 Prior and Concomitant Medications and Therapies',
    },
    {
        id: 'demo-arto',
        tableTitle: 'Table 9. Demographic Data: Artosipramide',
        sectionName: '12.1 Demographic Data Summary figures and tables',
    },
    {
        id: 'disposition',
        tableTitle: 'Table 10. Disposition Artosipramide',
        sectionName: '8.6.5 Study Population',
    },
    {
        id: 'ecg-efficacy',
        tableTitle: 'Table 11. ECG Data',
        sectionName: '8.4.1 Efficacy Assessments',
    },
    {
        id: 'exposure',
        tableTitle: 'Table 12. Extent of Exposure Artosipramide',
        sectionName: '8.6.8 Extent of Exposure',
    },
    {
        id: 'physical-exams',
        tableTitle: 'Table 13. Physical Exams',
        sectionName: '8.6.5 Study Population',
    },
    {
        id: 'pk-meta',
        tableTitle: 'Table 14. PK Metabolite Concentrations',
        sectionName: '8.4.1 Efficacy Assessments',
    },
    {
        id: 'prior-med',
        tableTitle: 'Table 15. Prior Medical Conditions',
        sectionName: '8.6.5 Study Population',
    },
    {
        id: 'protocol-dev',
        tableTitle: 'Table 16. Protocol Deviations Leading to Exclusion',
        sectionName: '14.2.2 Protocol deviations.',
    },
    {
        id: 'simpson',
        tableTitle: 'Table 17. Simpson Angus Scale Total Score',
        sectionName: '8.4.1 Efficacy Assessments',
    },
    {
        id: 'teae-1',
        tableTitle:
            'Table 18. Treatment-Emergent Adverse Events Occurring in One or More Subjects in Either Treatment Group During the Double-blind Phase',
        sectionName: '10.2 Adverse Events',
    },
    {
        id: 'teae-2',
        tableTitle:
            'Table 19. Treatment-Emergent Adverse Events Occurring in One or More Subjects in Either Treatment Group During the Double-blind Phase',
        sectionName: '10.2 Adverse Events',
    },
    {
        id: 'vital-signs',
        tableTitle: 'Table 20. Vital Signs',
        sectionName: '10.6 Vital Signs',
    },
]
