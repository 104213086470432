import React, { useState, useEffect } from 'react'
import {
    Card,
    CardContent,
    IconButton,
    Button,
    FormControl,
    Grid,
    Typography,
    Modal,
    Box,
    TextField,
    Select,
    MenuItem,
    Tooltip,
    Chip,
    OutlinedInput,
    InputLabel,
    ThemeProvider,
    Switch,
    Autocomplete,
    Divider,
    Alert,
} from '@mui/material'
import { AddCircle, Delete, Search, Edit, Info } from '@mui/icons-material'
import { ArtosTheme } from '../../Themes'
import { useDemo } from '../../../contexts/DemoContext'
import {
    saveRuleset,
    getAllRulesets,
    uploadMultipleFilesUpdated,
    extractContentPlan,
} from '../../../utils/utils'
import { useAuthInfo } from '@propelauth/react'
import { MultipleFileUploader } from '../../MultipleFileUploader'
import Skeleton from '@mui/material/Skeleton'

// import paperclip from '../../icons/Paper clip.svg'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const RulesComponent = ({
    templateSections,
    sourceFiles,
    onRulesChange,
    onNextClick,
    onBackClick,
    onUpdateOutlineSections,
    enableBulkUpload,
    isLastStep,
    documentType,
    handleFiles,
    removeFile,
    multipleFileList,
    multipleFileNameList,
    multipleFileContentList,
    rulesetList,
}) => {
    const [workingRules, setWorkingRules] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentRule, setCurrentRule] = useState({
        type: '',
        sources: [],
        modifications: '',
        topics: [],
        instructions: '',
    })
    const [editingSection, setEditingSection] = useState('')
    const [sections, setSections] = useState([])
    const [isAddSectionModalOpen, setIsAddSectionModalOpen] = useState(false)
    const [newSection, setNewSection] = useState({ number: '', title: '' })
    const [searchQuery, setSearchQuery] = useState('')
    const [isEditSectionModalOpen, setIsEditSectionModalOpen] = useState(false)
    const [editingSectionName, setEditingSectionName] = useState('')
    const [editingSectionNewName, setEditingSectionNewName] = useState('')
    const [selectedRuleset, setSelectedRuleset] = useState('')
    const [isPredefined, setIsPreDefined] = useState(true)
    const [addContentPlan, setHandleAddContentPlan] = useState(false)
    const [addSaveRulesetModal, setSaveRulesetModal] = useState(false)
    const [rulesetName, setRulesetName] = useState('')
    // const [rulesets, setRulesets] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const [groupedStudies, setGroupedStudies] = useState([])
    const [groupedStudiesList, setGroupedStudiesList] = useState([])
    const [selectedLocation, setSelectedLocation] = useState('')
    const [tempRules, setTempRules] = useState([])
    const [copypasteSection, setCopypasteSection] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [noFilesModal, setNoFilesModal] = useState(false)
    const [length, setLength] = useState('')

    const [previousRules, setPreviousRules] = useState({})

    const handleCopypasteSectionChange = (newValue) => {
        console.log('event.target.value:', newValue)
        setCurrentRule({
            ...currentRule,
            modifications: newValue,
        })
    }

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue) {
            event.preventDefault()
            setCopypasteSection((prev) => [...prev, inputValue])
            setInputValue('')
        }
    }

    const [contentPlan, setContentPlan] = useState(null)

    useEffect(() => {
        const groupStudiesByLocation = (contentPlan) => {
            const groupedStudies = {}

            if (contentPlan) {
                console.log('content plan:', contentPlan)

                contentPlan.content_plan.forEach((category) => {
                    category.studies.forEach((study) => {
                        const { location } = study
                        if (!groupedStudies[location]) {
                            groupedStudies[location] = []
                        }
                        groupedStudies[location].push({
                            category: category.category,
                            study_name: study.study_name,
                            file_name: study.file_name,
                        })
                    })
                })
            }

            return groupedStudies
        }

        // Group studies by location
        const groupedStudies = groupStudiesByLocation(contentPlan)

        const groupedStudiesList = Object.keys(groupedStudies).map((key) => ({
            location: key,
            studies: groupedStudies[key],
        }))

        console.log('grouped studies list', groupedStudiesList)
        groupedStudiesList.unshift({ location: '', studies: [] })

        // Update state with grouped studies
        setGroupedStudies(groupedStudies)
        setGroupedStudiesList(groupedStudiesList)
    }, [contentPlan])

    console.log('grouped studies list', groupedStudiesList)

    const { isDemo } = useDemo()

    const auth = useAuthInfo()
    const org = auth.orgHelper.getOrgs()[0].orgId

    async function getOrgAccessToken(orgId) {
        console.log(orgId + ' is orgId')
        const orgAccessToken = await auth.tokens.getAccessTokenForOrg(orgId)
        return orgAccessToken
    }

    // useEffect(() => {
    //     async function fetchRulesets() {
    //         const orgAccessToken = await getOrgAccessToken(org)
    //         const rulesets = await getAllRulesets(orgAccessToken)
    //         console.log('rulesets:', rulesets)
    //         setRulesets(rulesets)
    //     }
    //     fetchRulesets();
    //     },
    //     [])

    const predefinedTopics = [
        'Primary PK',
        'Secondary PK',
        'Primary PD',
        'Secondary PD',
        'Adverse events',
    ]

    const sectionOptions = [
        'Introduction',
        'Methods',
        'Results',
        'Discussion',
        'Conclusions',
    ]

    const lengthOptions = ['1 sentence', '2-4 sentences', '1-2 paragraphs']

    const handleSelectChange = (event) => {
        const location = event.target.value
        setSelectedLocation(location)
        const studies = groupedStudies[location]
        // Perform an action on the selected studies
        console.log('Selected studies:', studies)

        // Define the rule type and topic (if applicable)
        const ruleType = currentRule.type
        const ruleTopic = currentRule.topics // Replace with actual rule topic if applicable
        const modifications = currentRule.modifications

        // Create new rules for each study
        if (studies) {
            const newRules = studies
                .filter((study) => study.file_name)
                .map((study) => ({
                    type: ruleType,
                    sources: [study.file_name || null],
                    modifications: modifications,
                    topics: ruleTopic,
                    instructions: '',
                    study_name: study.study_name || null,
                }))

            console.log('New rules:', newRules)

            // Update the state with the new rules
            setTempRules(newRules)
        }
    }

    const sortSections = (sectionsToSort) => {
        return sectionsToSort.sort((a, b) => {
            return a.sectionName.localeCompare(b.sectionName, undefined, {
                numeric: true,
                sensitivity: 'base',
            })
        })
    }

    const handleRulesetChange = (event) => {
        const selectedRulesetName = event.target.value
        setSelectedRuleset(selectedRulesetName)

        if (selectedRulesetName === 'Default') {
            const initialSections = templateSections.map((section) => ({
                ...section,
                active: true,
            }))
            setSections(sortSections(initialSections))
            onUpdateOutlineSections(initialSections)

            const emptyRules = initialSections.reduce((acc, section) => {
                acc[section.sectionName] = []
                return acc
            }, {})
            setWorkingRules(emptyRules)

            const formattedEmptyRules = initialSections.map((section) => ({
                section: section.sectionName,
                rules: [],
            }))
            onRulesChange(formattedEmptyRules)
            return
        }

        const selectedRuleset = rulesetList
            .filter((ruleset) => ruleset.doc_type === documentType)
            .find((ruleset) => ruleset.ruleset === selectedRulesetName)

        if (selectedRuleset) {
            const payload = selectedRuleset

            const newSections = convertPayloadToSections(payload)
            setSections(sortSections(newSections))
            onUpdateOutlineSections(newSections)

            const newWorkingRules = convertPayloadToWorkingRules(payload)
            setWorkingRules(newWorkingRules)

            const formattedRules = payload.rules.map((section) => ({
                section: section.section,
                rules: section.rules.map((rule) => ({
                    type: rule.type || rule.rule,
                    sources: rule.sources || [],
                    modifications: rule.modifications || '',
                    topics: rule.topics || [],
                    instructions: rule.instructions || '',
                })),
            }))
            onRulesChange(formattedRules)
        }
    }

    const convertPayloadToWorkingRules = (payload) => {
        const workingRulesMap = {}
        payload.rules.forEach((section) => {
            workingRulesMap[section.section] = section.rules.map((rule) => ({
                type: rule.type,
                sources: rule.sources || [],
                modifications: rule.modifications || '',
                topics: rule.topics || [],
                instructions: rule.instructions || '',
            }))
        })
        return workingRulesMap
    }

    const convertPayloadToSections = (payload) => {
        console.log('payload:', payload)
        return payload.rules.map((section) => ({
            sectionName: section.section,
            active: section.active !== undefined ? section.active : true,
        }))
    }

    useEffect(() => {
        if (templateSections.length > 0) {
            // Only apply Standard CSR Ruleset if document type is CSR Template
            if (documentType === 'CSR Template') {
                // Set initial ruleset selection
                setSelectedRuleset('Standard CSR Ruleset')

                // Find the Standard CSR Ruleset
                // const defaultRuleset = predefinedRulesets[documentType]?.find(
                //     (ruleset) => ruleset.name === 'Standard CSR Ruleset'
                // )

                const defaultRuleset = rulesetList
                    .filter((ruleset) => ruleset.doc_type === documentType)
                    .find(
                        (ruleset) => ruleset.ruleset === 'Standard CSR Ruleset'
                    )

                if (defaultRuleset) {
                    // Apply the ruleset payload
                    const payload = defaultRuleset

                    // Convert and set sections
                    const newSections = convertPayloadToSections(payload)
                    setSections(sortSections(newSections))
                    onUpdateOutlineSections(newSections)

                    // Convert and set working rules
                    const newWorkingRules =
                        convertPayloadToWorkingRules(payload)
                    setWorkingRules(newWorkingRules)

                    // Format and update rules
                    const formattedRules = payload.rules.map((section) => ({
                        section: section.section,
                        rules: section.rules.map((rule) => ({
                            type: rule.type,
                            sources: rule.sources || [],
                            modifications: rule.modifications || '',
                            topics: rule.topics || [],
                            instructions: rule.instructions || '',
                        })),
                    }))
                    onRulesChange(formattedRules)
                }
            } else {
                // For non-CSR Template documents, initialize with empty rules
                setSelectedRuleset('')

                const initialSections = templateSections.map((section) => ({
                    ...section,
                    active: true,
                }))
                setSections(sortSections(initialSections))
                onUpdateOutlineSections(initialSections)

                const emptyRules = initialSections.reduce((acc, section) => {
                    acc[section.sectionName] = []
                    return acc
                }, {})
                setWorkingRules(emptyRules)

                const formattedEmptyRules = initialSections.map((section) => ({
                    section: section.sectionName,
                    rules: [],
                }))
                onRulesChange(formattedEmptyRules)
            }
        }
    }, [templateSections, documentType, rulesetList])

    useEffect(() => {
        updateRulesSchema()
    }, [workingRules, sections])

    const handleOpenModal = (sectionName) => {
        setEditingSection(sectionName)
        setCurrentRule({
            type: 'copypaste',
            sources: [],
            modifications: '',
            topics: [],
            instructions: '',
        })
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
        setCurrentRule({
            type: 'copypaste',
            sources: [],
            modifications: '',
            topics: [],
            instructions: '',
        })
        setEditingSection('')
    }

    const handleAddRule = () => {
        if (!editingSection) return

        // Define the rule type and topic (if applicable)
        const ruleType = currentRule.type
        const ruleTopic = currentRule.topics // Replace with actual rule topic if applicable
        const modifications = currentRule.modifications

        const studies = groupedStudies[selectedLocation]

        console.log('studies in addrule', studies)

        let newRules = []

        // Create new rules for each study
        if (studies) {
            newRules = studies
                .filter((study) => study.file_name)
                .map((study) => ({
                    type: ruleType,
                    sources: [study.file_name || null],
                    modifications: modifications,
                    topics: ruleTopic,
                    instructions: '',
                    study_name: study.study_name || null,
                }))

            console.log('New rules in addrule:', newRules)

            setTempRules(newRules)
        }

        setWorkingRules((prevRules) => {
            const sectionRules = prevRules[editingSection] || []
            const newRulesList = [...sectionRules, currentRule, ...newRules]
            const reorderedRules = reorderRules(newRulesList)

            return {
                ...prevRules,
                [editingSection]: reorderedRules,
            }
        })
        handleCloseModal()
    }

    const reorderRules = (rules) => {
        const groupedRules = rules.reduce((acc, rule) => {
            const key = rule.sources[0]
            if (!acc[key]) {
                acc[key] = []
            }
            acc[key].push(rule)
            return acc
        }, {})

        return Object.values(groupedRules).flat()
    }

    const handleRemoveRule = (sectionName, ruleIndex) => {
        setWorkingRules((prevRules) => ({
            ...prevRules,
            [sectionName]: prevRules[sectionName].filter(
                (_, index) => index !== ruleIndex
            ),
        }))
    }

    const handleSectionToggle = (sectionName) => {
        const section = sections.find((s) => s.sectionName === sectionName)
        const isCurrentlyActive = section.active

        const updatedSections = sections.map((section) =>
            section.sectionName === sectionName
                ? { ...section, active: !section.active }
                : section
        )

        console.log('updatedSections:', updatedSections)
        setSections(updatedSections)
        onUpdateOutlineSections(updatedSections)

        if (isCurrentlyActive) {
            // If section is being disabled
            setPreviousRules((prev) => ({
                ...prev,
                [sectionName]: workingRules[sectionName] || [],
            }))

            // Replace existing rules with no-text rule
            setWorkingRules((prev) => ({
                ...prev,
                [sectionName]: [
                    {
                        type: 'no-text',
                        sources: [],
                        modifications: '',
                        topics: [],
                        instructions: '',
                    },
                ],
            }))
        } else {
            // If section is being enabled
            setWorkingRules((prev) => ({
                ...prev,
                [sectionName]: previousRules[sectionName] || [],
            }))

            setPreviousRules((prev) => {
                const newPrev = { ...prev }
                delete newPrev[sectionName]
                return newPrev
            })
        }
    }

    const handleAddSectionOpen = () => {
        setIsAddSectionModalOpen(true)
    }

    const handleAddSectionClose = () => {
        setIsAddSectionModalOpen(false)
        setNewSection({ number: '', title: '' })
    }

    const handleSaveRuleset = () => {
        setSaveRulesetModal(true)
    }

    const handleSaveRulesetClose = () => {
        setSaveRulesetModal(false)
    }

    const handleAddContentPlan = () => {
        setHandleAddContentPlan(true)
    }

    const handleAddContentPlanClose = () => {
        setHandleAddContentPlan(false)
    }

    const handleAddContentPlanFile = async () => {
        setLoading(true)
        console.log('setloading true')
        const orgAccessToken = await getOrgAccessToken(org)
        await uploadMultipleFilesUpdated(
            setLoading,
            multipleFileList,
            multipleFileContentList,
            setError,
            'intermediate',
            orgAccessToken
        )
        setLoading(true) // set loading to true to prevent multiple clicks
        console.log('multipleFileNameList:', multipleFileNameList)
        const ingestedContentPlan = await extractContentPlan(
            multipleFileNameList[0],
            orgAccessToken
        )
        console.log('ingestedContentPlan:', ingestedContentPlan)
        setContentPlan(ingestedContentPlan)
        setHandleAddContentPlan(false)
        setLoading(false)
    }

    const handleAddSection = () => {
        if (newSection.number && newSection.title) {
            const newSectionName = `${newSection.number} ${newSection.title}`
            const updatedSections = sortSections([
                ...sections,
                { sectionName: newSectionName, active: true },
            ])
            setSections(updatedSections)
            setWorkingRules((prevRules) => ({
                ...prevRules,
                [newSectionName]: [],
            }))
            onUpdateOutlineSections(updatedSections)
            handleAddSectionClose()
        }
    }

    const handleEditSectionOpen = (sectionName) => {
        setEditingSectionName(sectionName)
        setEditingSectionNewName(sectionName)
        setIsEditSectionModalOpen(true)
    }

    const handleEditSectionClose = () => {
        setIsEditSectionModalOpen(false)
        setEditingSectionName('')
        setEditingSectionNewName('')
    }

    const handleEditSection = () => {
        if (
            editingSectionNewName &&
            editingSectionNewName !== editingSectionName
        ) {
            const updatedSections = sections.map((section) =>
                section.sectionName === editingSectionName
                    ? { ...section, sectionName: editingSectionNewName }
                    : section
            )
            setSections(updatedSections)
            onUpdateOutlineSections(updatedSections)

            setWorkingRules((prevRules) => {
                const updatedRules = { ...prevRules }
                updatedRules[editingSectionNewName] =
                    updatedRules[editingSectionName]
                delete updatedRules[editingSectionName]
                return updatedRules
            })

            handleEditSectionClose()
        }
    }

    const updateRulesSchema = async () => {
        const formattedRules = sections.map((section) => ({
            section: section.sectionName,
            rules: (workingRules[section.sectionName] || []).map((rule) => ({
                type: rule.type,
                sources: rule.sources || [],
                modifications: rule.modifications || '',
                topics: rule.topics || [],
                instructions: rule.instructions || '',
            })),
        }))

        onRulesChange(formattedRules)
    }

    const saveRulesSchema = async () => {
        const formattedRules = sections
            .filter((section) => section.active)
            .map((section) => ({
                section: section.sectionName,
                rules: (workingRules[section.sectionName] || []).map(
                    (rule) => ({
                        type: rule.type,
                        sources: rule.sources || [],
                        modifications: rule.modifications || '',
                        topics: rule.topics || [],
                        instructions: rule.instructions || '',
                    })
                ),
            }))

        const ruleset = {
            ruleset: rulesetName,
            doc_type: documentType,
            rules: formattedRules,
        }

        console.log('exported ruleset:', ruleset)

        const orgAccessToken = await getOrgAccessToken(org)

        saveRuleset(ruleset, orgAccessToken)

        setSaveRulesetModal(false)
    }

    const handleSelectAll = () => {
        const allActive = sections.every((section) => section.active)
        const updatedSections = sections.map((section) => ({
            ...section,
            active: !allActive,
        }))
        setSections(updatedSections)
        onUpdateOutlineSections(updatedSections)

        // If disabling all sections, add no-text rules
        if (allActive) {
            const noTextRules = {}
            sections.forEach((section) => {
                setPreviousRules((prev) => ({
                    ...prev,
                    [section.sectionName]:
                        workingRules[section.sectionName] || [],
                }))
                noTextRules[section.sectionName] = [
                    {
                        type: 'no-text',
                        sources: [],
                        modifications: '',
                        topics: [],
                        instructions: '',
                    },
                ]
            })
            setWorkingRules((prev) => ({
                ...prev,
                ...noTextRules,
            }))
        } else {
            // If enabling all sections, restore previous rules
            const restoredRules = {}
            sections.forEach((section) => {
                restoredRules[section.sectionName] =
                    previousRules[section.sectionName] || []
            })
            setWorkingRules((prev) => ({
                ...prev,
                ...restoredRules,
            }))
            setPreviousRules({})
        }
    }

    const filteredSections = sections.filter((section) =>
        section.sectionName.toLowerCase().includes(searchQuery.toLowerCase())
    )

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }

    const handleAddTopics = (newValue) => {
        if (isDemo && currentRule.topics.length >= 1) {
            return
        }
        setCurrentRule({
            ...currentRule,
            topics: newValue,
        })
    }

    const clickHandler = async () => {
        await updateRulesSchema()
        if (sourceFiles.length === 0) {
            setNoFilesModal(true)
        } else {
            onNextClick()
        }
    }

    const SectionTitle = ({ title }) => (
        <Box sx={{ display: 'inline-block', maxWidth: '100%' }}>
            <Tooltip title={title} arrow>
                <Typography
                    variant="subtitle1"
                    sx={{
                        display: 'inline-block',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {title}
                </Typography>
            </Tooltip>
        </Box>
    )

    return (
        <Box
            sx={{
                maxWidth: '75vw',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '70vw',
            }}
        >
            <ThemeProvider theme={ArtosTheme}>
                <Typography variant="h5" gutterBottom>
                    Define Rules
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    Define rules that guide how the AI generates your document.
                </Typography>

                {isDemo && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Section modification is disabled in this demo, but rules
                        can be modified. Choose a pre-defined ruleset, or add as
                        many rules as you'd like.
                    </Alert>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 2,
                    }}
                >
                    {/* <Button
                        startIcon={<AddCircle />}
                        onClick={handleAddSectionOpen}
                        color="primary"
                        variant="contained"
                        disabled={isDemo}
                    >
                        Add New Section
                    </Button> */}
                    <Button
                        startIcon={<AddCircle />}
                        onClick={handleAddContentPlan}
                        color="primary"
                        variant="contained"
                        disabled={isDemo}
                    >
                        Upload Content Plan
                    </Button>
                    <Button
                        startIcon={<AddCircle />}
                        onClick={handleSaveRuleset}
                        color="primary"
                        variant="contained"
                        disabled={isDemo}
                    >
                        Save Ruleset
                    </Button>
                    {documentType && rulesetList.length >= 1 && (
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="ruleset-select-label">
                                Select Ruleset
                            </InputLabel>
                            <Select
                                labelId="ruleset-select-label"
                                value={selectedRuleset}
                                onChange={handleRulesetChange}
                                label="Select Ruleset"
                            >
                                {/* {rulesets[doc_type]?.map(
                                    (ruleset) => (
                                        <MenuItem
                                            key={ruleset.name}
                                            value={ruleset.name}
                                        >
                                            {ruleset.name}
                                        </MenuItem>
                                    )
                                )} */}
                                {rulesetList &&
                                    rulesetList
                                        .filter(
                                            (ruleset) =>
                                                ruleset.doc_type ===
                                                documentType
                                        )
                                        .map((filteredRuleset) => (
                                            <MenuItem
                                                key={filteredRuleset.ruleset}
                                                value={filteredRuleset.ruleset}
                                            >
                                                {filteredRuleset.ruleset}
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            onClick={handleSelectAll}
                            color="primary"
                            variant="outlined"
                            sx={{ marginRight: 2 }}
                        >
                            {sections.every((section) => section.active)
                                ? 'Disable All'
                                : 'Enable All'}
                        </Button>
                        <TextField
                            placeholder="Search sections..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: <Search color="action" />,
                            }}
                        />
                    </Box>
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        maxHeight: '70vh',
                        marginBottom: 2,
                    }}
                >
                    {filteredSections.map((section) => (
                        <Card
                            key={section.sectionName}
                            variant="outlined"
                            sx={{
                                marginBottom: 2,
                                opacity: section.active ? 1 : 0.7,
                                transition: 'opacity 0.3s ease-in-out',
                                backgroundColor: section.active
                                    ? 'inherit'
                                    : '#f5f5f5',
                            }}
                        >
                            <CardContent>
                                <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                    wrap="nowrap"
                                >
                                    <Grid item xs zeroMinWidth>
                                        <SectionTitle
                                            title={section.sectionName}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            onClick={() =>
                                                handleEditSectionOpen(
                                                    section.sectionName
                                                )
                                            }
                                            size="small"
                                            sx={{ mr: 1 }}
                                            disabled={isDemo}
                                        >
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            startIcon={<AddCircle />}
                                            onClick={() =>
                                                handleOpenModal(
                                                    section.sectionName
                                                )
                                            }
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            disabled={!section.active}
                                            sx={{ mr: 2 }}
                                        >
                                            Add Rule
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Switch
                                            checked={section.active}
                                            onClick={() =>
                                                handleSectionToggle(
                                                    section.sectionName
                                                )
                                            }
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                                {(workingRules[section.sectionName] || []).map(
                                    (rule, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                padding: '8px 0',
                                                borderBottom:
                                                    index <
                                                    (
                                                        workingRules[
                                                            section.sectionName
                                                        ] || []
                                                    ).length -
                                                        1
                                                        ? '1px solid #e0e0e0'
                                                        : 'none',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Chip
                                                    label={`Type: ${
                                                        rule.type === 'no-text'
                                                            ? 'Shell (no text)'
                                                            : rule.type
                                                    }`}
                                                    color="primary"
                                                    size="small"
                                                    sx={{ mr: 1, mb: 1 }}
                                                />
                                                {rule.type !== 'no-text' && (
                                                    <>
                                                        <Chip
                                                            label={`Sources: ${rule.sources.join(
                                                                ', '
                                                            )}`}
                                                            color="primary"
                                                            size="small"
                                                            sx={{
                                                                mr: 1,
                                                                mb: 1,
                                                            }}
                                                        />
                                                        {rule.modifications && (
                                                            <Chip
                                                                label={`Modifications: ${rule.modifications}`}
                                                                color="primary"
                                                                size="small"
                                                                sx={{
                                                                    mr: 1,
                                                                    mb: 1,
                                                                }}
                                                            />
                                                        )}
                                                        {rule.topics &&
                                                            rule.topics.length >
                                                                0 && (
                                                                <Chip
                                                                    label={`Topics: ${rule.topics.join(
                                                                        ', '
                                                                    )}`}
                                                                    color="primary"
                                                                    size="small"
                                                                    sx={{
                                                                        mr: 1,
                                                                        mb: 1,
                                                                    }}
                                                                />
                                                            )}
                                                        {rule.instructions && (
                                                            <Chip
                                                                label="Instructions"
                                                                color="secondary"
                                                                size="small"
                                                                sx={{
                                                                    mr: 1,
                                                                    mb: 1,
                                                                }}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                            {section.active &&
                                                rule.type !== 'no-text' && (
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveRule(
                                                                    section.sectionName,
                                                                    index
                                                                )
                                                            }
                                                            size="small"
                                                            sx={{
                                                                color: 'text.secondary',
                                                                flexShrink: 0,
                                                            }}
                                                        >
                                                            <Delete fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                        </Box>
                                    )
                                )}
                            </CardContent>
                        </Card>
                    ))}
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 2,
                    }}
                >
                    <Button
                        onClick={onBackClick}
                        variant="outlined"
                        color="primary"
                    >
                        Back
                    </Button>
                    <Button
                        onClick={clickHandler}
                        variant="contained"
                        color="primary"
                    >
                        {isLastStep ? 'Generate' : 'Next'}
                    </Button>
                </Box>

                {/* Add Rule Modal */}
                <Modal open={isModalOpen} onClose={handleCloseModal}>
                    <Box sx={modalStyle}>
                        <Typography variant="h6" component="h2" mb={2}>
                            Add Rule for {editingSection}
                        </Typography>
                        <TextField
                            select
                            fullWidth
                            label="Rule Type"
                            value={currentRule.type}
                            onChange={(e) =>
                                setCurrentRule({
                                    ...currentRule,
                                    type: e.target.value,
                                    topics: [],
                                    modifications: '',
                                    instructions: '',
                                })
                            }
                            margin="normal"
                        >
                            <MenuItem value="copypaste">Copy/Paste</MenuItem>
                            <MenuItem value="summarize">Summarize</MenuItem>
                            {/* <MenuItem value="no-text">Shell (no text)</MenuItem> */}
                        </TextField>

                        {currentRule.type !== 'no-text' && (
                            <>
                                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                                    Sources
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ marginBottom: 2 }}
                                >
                                    {isDemo
                                        ? 'In demo mode, sources can only be added from one file at a time'
                                        : 'Select between adding rules for an individual source or from a list of studies specified by a location from a content plan'}
                                </Typography>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="source-select">
                                        Source
                                    </InputLabel>
                                    <Select
                                        labelId="source-select"
                                        label="Source"
                                        multiple
                                        value={currentRule.sources}
                                        onChange={(e) =>
                                            setCurrentRule({
                                                ...currentRule,
                                                sources: e.target.value,
                                            })
                                        }
                                        input={<OutlinedInput label="Source" />}
                                        renderValue={(selected) => (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: 0.5,
                                                }}
                                            >
                                                {selected.map((value) => (
                                                    <Chip
                                                        key={value}
                                                        label={value}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {sourceFiles.map((file) => (
                                            <MenuItem
                                                key={file.name}
                                                value={file.name}
                                            >
                                                {file.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {contentPlan && (
                                    <FormControl fullWidth sx={{ mt: 2 }}>
                                        <InputLabel id="location-select">
                                            Location
                                        </InputLabel>
                                        <Select
                                            labelId="location-select"
                                            value={selectedLocation}
                                            onChange={handleSelectChange}
                                            input={
                                                <OutlinedInput label="Location" />
                                            }
                                        >
                                            {groupedStudiesList.map((item) => (
                                                <MenuItem
                                                    key={item.location}
                                                    value={item.location}
                                                >
                                                    {item.location}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </>
                        )}
                        {currentRule.type == 'copypaste' && (
                            <>
                                <Typography
                                    variant="body2"
                                    sx={{ marginBottom: 2, marginTop: 2 }}
                                >
                                    Select a section to copy/paste from and then
                                    press ENTER.
                                </Typography>
                                <Autocomplete
                                    sx={{ mt: 2 }}
                                    id="topics-select"
                                    options={sectionOptions}
                                    freeSolo
                                    value={copypasteSection}
                                    onChange={(event, newValue) =>
                                        handleCopypasteSectionChange(newValue)
                                    }
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault()
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Select section to copy/paste from"
                                            variant="outlined"
                                        />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                label={option}
                                                {...getTagProps({ index })}
                                                sx={{ borderRadius: '16px' }}
                                            />
                                        ))
                                    }
                                />
                            </>
                        )}

                        {currentRule.type === 'summarize' && (
                            <>
                                <Divider sx={{ my: 2 }} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ mr: 1 }}
                                    >
                                        Topics
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body2"
                                    sx={{ marginBottom: 2 }}
                                >
                                    {isDemo
                                        ? 'In demo mode, only one topic can be selected.'
                                        : 'To add a custom topic, type the topic and then press the ENTER key.'}
                                </Typography>
                                <Autocomplete
                                    multiple
                                    id="topics-select"
                                    options={predefinedTopics}
                                    freeSolo
                                    value={currentRule.topics}
                                    onChange={(event, newValue) =>
                                        handleAddTopics(newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Add a topic"
                                            variant="outlined"
                                        />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                label={option}
                                                {...getTagProps({ index })}
                                                sx={{ borderRadius: '16px' }}
                                            />
                                        ))
                                    }
                                />
                                <Divider sx={{ my: 2 }} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ mr: 1 }}
                                    >
                                        Length
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body2"
                                    sx={{ marginBottom: 2 }}
                                >
                                    {isDemo
                                        ? 'In demo mode, only one topic can be selected.'
                                        : 'Select the length of the summary.'}
                                </Typography>
                                <FormControl
                                    sx={{ marginBottom: 2, minWidth: '100%' }}
                                >
                                    <InputLabel id="ruleset-select-label">
                                        Select Length
                                    </InputLabel>
                                    <Select
                                        labelId="length-select-label"
                                        value={length}
                                        onChange={(e) => {
                                            setLength(e.target.value)
                                            setCurrentRule({
                                                ...currentRule,
                                                modifications: e.target.value,
                                            })
                                        }}
                                        label="Select Length"
                                    >
                                        {lengthOptions &&
                                            lengthOptions.map((option) => (
                                                <MenuItem
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        <Button
                            onClick={handleAddRule}
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Add Rule
                        </Button>
                    </Box>
                </Modal>

                {/* Add Section Modal */}
                <Modal
                    open={isAddSectionModalOpen}
                    onClose={handleAddSectionClose}
                >
                    <Box sx={modalStyle}>
                        <Typography variant="h6" component="h2" mb={2}>
                            Add New Section
                        </Typography>
                        <TextField
                            fullWidth
                            label="Section Number"
                            value={newSection.number}
                            onChange={(e) =>
                                setNewSection({
                                    ...newSection,
                                    number: e.target.value,
                                })
                            }
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Section Title"
                            value={newSection.title}
                            onChange={(e) =>
                                setNewSection({
                                    ...newSection,
                                    title: e.target.value,
                                })
                            }
                            margin="normal"
                        />
                        <Button
                            onClick={handleAddSection}
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Add Section
                        </Button>
                    </Box>
                </Modal>

                {/* Add Content Plan Modal */}
                <Modal
                    open={addContentPlan}
                    onClose={handleAddContentPlanClose}
                >
                    <Box sx={modalStyle}>
                        <Typography variant="h6" component="h2" mb={2}>
                            Add Content Plan
                        </Typography>
                        <div
                            style={{ marginTop: '2vh' }}
                            className={`input-container ${
                                multipleFileList.length < 1
                                    ? 'dashed-border'
                                    : ''
                            }`}
                        >
                            {loading ? (
                                <div style={{ textAlign: 'center' }}>
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={100}
                                    />
                                </div>
                            ) : (
                                <div>
                                    {multipleFileList.length >= 1 && (
                                        <p style={{ textAlign: 'left' }}>
                                            Files Selected:
                                        </p>
                                    )}
                                    {multipleFileList.length == 0 && (
                                        <MultipleFileUploader
                                            handleFiles={handleFiles}
                                        />
                                    )}
                                    {multipleFileList.length >= 1 && (
                                        <>
                                            {multipleFileList.map(
                                                (file, index) => (
                                                    <div
                                                        className="multiple-file-upload-card"
                                                        style={{
                                                            minWidth: '0',
                                                        }}
                                                        key={file.name}
                                                    >
                                                        <div
                                                            className="file-info"
                                                            style={{
                                                                margin: '10px',
                                                            }}
                                                        >
                                                            {/* <img src={paperclip} alt="Paperclip"/> */}
                                                            <p className="file-name">
                                                                {file.name}
                                                            </p>
                                                        </div>
                                                        <button
                                                            className="remove-file-btn"
                                                            onClick={() =>
                                                                removeFile(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        {multipleFileList.length >= 1 && !loading && (
                            <Button
                                onClick={handleAddContentPlanFile}
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Add Content Plan
                            </Button>
                        )}
                    </Box>
                </Modal>
                {/* Save Ruleset Modal */}
                <Modal
                    open={addSaveRulesetModal}
                    onClose={handleSaveRulesetClose}
                >
                    <Box sx={modalStyle}>
                        <Typography variant="h6" component="h2" mb={2}>
                            Save Ruleset
                        </Typography>
                        <TextField
                            fullWidth
                            label="Ruleset Name"
                            value={rulesetName}
                            onChange={(e) => setRulesetName(e.target.value)}
                            margin="normal"
                        />
                        <Button
                            onClick={saveRulesSchema}
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Save Ruleset
                        </Button>
                    </Box>
                </Modal>

                {/* Edit Section Modal */}
                <Modal
                    open={isEditSectionModalOpen}
                    onClose={handleEditSectionClose}
                >
                    <Box sx={modalStyle}>
                        <Typography variant="h6" component="h2" mb={2}>
                            Edit Section Name
                        </Typography>
                        <TextField
                            fullWidth
                            label="Section Name"
                            value={editingSectionNewName}
                            onChange={(e) =>
                                setEditingSectionNewName(e.target.value)
                            }
                            margin="normal"
                        />
                        <Button
                            onClick={handleEditSection}
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Update Section Name
                        </Button>
                    </Box>
                </Modal>
                <Modal
                    open={noFilesModal}
                    onClose={() => setNoFilesModal(false)}
                >
                    <div className="modal-background">
                        <div className="modal-content">
                            <h2>
                                You must select at least one source file to
                                proceed.
                            </h2>
                            <Button
                                onClick={onBackClick}
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                            >
                                Return to Input Page
                            </Button>
                        </div>
                    </div>
                </Modal>
            </ThemeProvider>
        </Box>
    )
}

export default RulesComponent
