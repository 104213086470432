import React from 'react'
import { styled, ThemeProvider, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { NavLink } from 'react-router-dom'
import { useSidebar } from '../../../contexts/SidebarContext'

// Import icons
import folderOpen from '../../../icons/Folder open.svg'
import archive from '../../../icons/Archive.svg'
import support from '../../../icons/Support.svg'
import cog from '../../../icons/Cog.svg'
import flag from '../../../icons/Flag.svg'
import { ArtosTheme } from '../../Themes'

const drawerWidth = 240

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    height: '100%',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
    '& .MuiDrawer-paper': {
        position: 'relative',
        height: '100%',
        overflowY: 'auto',
    },
}))

const menuItems = [
    { text: 'Home', icon: folderOpen, path: '/' },
    { text: 'Templates', icon: archive, path: '/templates' },
    // { text: 'Automations', icon: flag, path: '/automations' },
    { text: 'Help', icon: support, path: '/help' },
    { text: 'Settings', icon: cog, path: '/settings' },
]

export default function Sidebar() {
    const theme = useTheme()
    const { isOpen, toggleSidebar } = useSidebar()

    return (
        <Box sx={{ height: '100%', overflow: 'hidden' }}>
            <ThemeProvider theme={ArtosTheme}>
                <CssBaseline />
                <Drawer variant="permanent" open={isOpen}>
                    <DrawerHeader>
                        <IconButton onClick={toggleSidebar}>
                            {!isOpen ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        {menuItems.map((item, index) => (
                            <ListItem
                                key={item.text}
                                disablePadding
                                sx={{ display: 'block' }}
                            >
                                <NavLink
                                    to={item.path}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }}
                                >
                                    {({ isActive }) => (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: isOpen
                                                    ? 'initial'
                                                    : 'center',
                                                px: 2.5,
                                                backgroundColor: isActive
                                                    ? theme.palette.action
                                                          .selected
                                                    : 'transparent',
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: isOpen ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <img
                                                    src={item.icon}
                                                    alt={item.text}
                                                    className="nav-logo"
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.text}
                                                sx={{ opacity: isOpen ? 1 : 0 }}
                                            />
                                        </ListItemButton>
                                    )}
                                </NavLink>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </ThemeProvider>
        </Box>
    )
}
