// src/pages/DocumentPage/modals/tables/ManageTablesModal.js
import React, { useState, useEffect, useCallback } from 'react'
import {
    Modal,
    Box,
    Tabs,
    Tab,
    Typography,
    IconButton,
    Button,
    Alert,
    CircularProgress,
    Snackbar,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InsertTablesTab from './InsertTablesTab'
import GalleryTab from './GalleryTab'
import TableDetailsView from './TableDetailsView'
import {
    getStoredTableTitles,
    addTabularDataToDoc,
    getTableTitlesInSection,
    getCSVColumns,
} from '../../../../utils/utils'
import { formatSectionText } from '../../../../utils/sectionExtract'
import { useAuthInfo } from '@propelauth/react'
import BulkUploadModal from './BulkUploadModal'
import ManageTablesTab from './ManageTablesTab'
import {
    PRELOADED_DOC_IDS,
    DEFAULT_TABLE_ASSIGNMENTS,
} from '../../../../utils/tableAssignments'

// const sampleTables = [
//     {
//         id: '1',
//         title: 'Adverse Events Summary',
//         type: 'adverse event',
//         population: 'ITT',
//         sourceDocument: 'Safety Report',
//         content: `
// | Adverse Event | Treatment Group (n=100) | Placebo Group (n=100) | P-value |
// |----------------|-------------------------|------------------------|---------|
// | Headache       | 15 (15%)                | 10 (10%)               | 0.3     |
// | Nausea         | 8 (8%)                  | 5 (5%)                 | 0.4     |
// | Dizziness      | 12 (12%)                | 7 (7%)                 | 0.2     |
//         `,
//     },
//     {
//         id: '2',
//         title: 'Pharmacokinetic Parameters',
//         type: 'PK',
//         population: 'PK Population',
//         sourceDocument: 'Clinical Study Report',
//         content: `
// | Parameter   | Mean (SD)     | Median     | Range        |
// |-------------|---------------|------------|--------------|
// | AUC (ng·h/mL)| 1250 (300)    | 1200       | 800 - 1800   |
// | Cmax (ng/mL) | 85 (20)       | 80         | 50 - 120     |
// | Tmax (h)     | 2.5 (1.0)     | 2.0        | 1.0 - 4.0    |
//         `,
//     },
//     {
//         id: '3',
//         title: 'Efficacy Endpoints',
//         type: 'efficacy',
//         population: 'mITT',
//         sourceDocument: 'Efficacy Analysis',
//         content: `
// | Endpoint           | Treatment Group (n=95) | Placebo Group (n=97) | Treatment Effect (95% CI) | P-value |
// |--------------------|-------------------------|----------------------|---------------------------|---------|
// | Primary Endpoint   | 65 (68.4%)              | 40 (41.2%)           | 27.2% (13.5% - 40.9%)     | <0.001  |
// | Secondary Endpoint | 50 (52.6%)              | 35 (36.1%)           | 16.5% (2.8% - 30.2%)      | 0.02    |
//         `,
//     },
// ]

const ManageTablesModal = ({
    open,
    handleClose,
    documentId,
    sectionInfo,
    onTableInserted,
    tableAssignments,
    onTableAssignmentsUpdate,
}) => {
    const [activeTab, setActiveTab] = useState(0)
    const [tables, setTables] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const [selectedTable, setSelectedTable] = useState(null)
    const [selectedSection, setSelectedSection] = useState(null)
    const [sectionTables, setSectionTables] = useState([])
    const [selectedExistingTable, setSelectedExistingTable] = useState(null)
    const [selectedGalleryTable, setSelectedGalleryTable] = useState(null)
    const [columns, setColumns] = useState([])
    const [operator, setOperator] = useState(null)
    const [comparator, setComparator] = useState('')
    const [newTableTitle, setNewTableTitle] = useState('')
    const [isLoadingColumns, setIsLoadingColumns] = useState(false)

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()

    useEffect(() => {
        if (open) {
            fetchTables()
        }
    }, [open])

    useEffect(() => {
        if (open && PRELOADED_DOC_IDS.includes(documentId)) {
            // Map stored tables to assignments
            const mappedAssignments = tables
                .map((table) => {
                    const defaultAssignment = DEFAULT_TABLE_ASSIGNMENTS.find(
                        (assignment) => assignment.tableTitle === table.title
                    )

                    if (defaultAssignment) {
                        return {
                            ...defaultAssignment,
                            tableId: table.id,
                            csvPath: table.csvPath,
                        }
                    }
                    return null
                })
                .filter(Boolean)

            // Only set assignments if they haven't been set already
            if (
                mappedAssignments.length > 0 &&
                (!tableAssignments || tableAssignments.length === 0)
            ) {
                onTableAssignmentsUpdate(mappedAssignments)
            }
        }
    }, [open, documentId, tables, tableAssignments, onTableAssignmentsUpdate])

    const fetchTables = async () => {
        setLoading(true)
        setError(null)
        try {
            const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(
                orgs[0].orgId
            )
            const result2 = await getStoredTableTitles(
                documentId,
                orgAccessToken
            )
            console.log(result2)

            // Handle null or undefined result by defaulting to empty array
            const tablesMapped = (result2 || []).map((table) => ({
                id: table.id,
                title: table.table_title,
                type: table.table_type,
                population: table.population,
                content: table.table_markdown,
                csvPath: table.output_csv_filename,
                description: table.table_description,
            }))

            console.log('tablesMapped:', tablesMapped)

            setTables(tablesMapped)
        } catch (err) {
            setError('Failed to fetch tables')
            console.error(err)
            // Set tables to empty array on error
            setTables([])
        } finally {
            setLoading(false)
        }
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
    }

    const handleTableSelect = (table) => {
        setSelectedTable(table)
    }

    const handleTableClose = () => {
        setSelectedTable(null)
    }

    const handleBulkInsert = async (assignments) => {
        setLoading(true)
        setError(null)
        var url
        try {
            const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(
                orgs[0].orgId
            )
            for (const assignment of assignments) {
                console.log('assignment:', assignment)
                console.log('documentId:', documentId)
                url = await addTabularDataToDoc(
                    {
                        document_id: documentId,
                        section_header: assignment.sectionName,
                        table_id: assignment.table,
                        filter_column: '',
                        operator: '',
                        comparator: '',
                        csv_input_container: 'tables',
                        document_container: 'output',
                        table_title: assignment.tableTitle,
                        csv_paths: [assignment.csvPath],
                    },
                    orgAccessToken
                )
            }
            onTableAssignmentsUpdate(assignments)
        } catch (err) {
            setError('Failed to insert tables')
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    const handleOpenUploadModal = () => setIsUploadModalOpen(true)
    const handleCloseUploadModal = () => setIsUploadModalOpen(false)

    const fetchSectionTables = useCallback(
        async (section) => {
            if (!section) return

            setLoading(true)
            setError(null)

            try {
                const orgAccessToken =
                    await authInfo.tokens.getAccessTokenForOrg(orgs[0].orgId)
                const nextSectionIndex =
                    sectionInfo.findIndex(
                        (s) => s.section_name === section.value
                    ) + 1
                const nextSectionHeader =
                    nextSectionIndex < sectionInfo.length
                        ? sectionInfo[nextSectionIndex].section_name
                        : 'END'

                const newHeader = await formatSectionText(section.value)
                const nextHeader = await formatSectionText(nextSectionHeader)

                const payload = {
                    document_id: documentId,
                    section_header: newHeader,
                    next_section_header: nextHeader,
                    input_container: 'output',
                }

                const titles = await getTableTitlesInSection(
                    payload,
                    orgAccessToken
                )
                setSectionTables(
                    titles.map((title) => ({
                        value: title,
                        label: title,
                    }))
                )
            } catch (err) {
                setError('Failed to fetch tables for this section')
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [documentId, sectionInfo, authInfo, orgs]
    )

    const fetchColumns = useCallback(
        async (csvPath) => {
            if (!csvPath) return

            setIsLoadingColumns(true)
            try {
                const orgAccessToken =
                    await authInfo.tokens.getAccessTokenForOrg(orgs[0].orgId)
                const fetchedColumns = await getCSVColumns(
                    csvPath.replace(`${orgs[0].orgId}/`, ''),
                    // csvPath,
                    orgAccessToken,
                    'tables'
                )

                if (Array.isArray(fetchedColumns)) {
                    setColumns(fetchedColumns)
                } else {
                    throw new Error('Unexpected response format from server')
                }
            } catch (err) {
                setError('Failed to fetch columns for filtering')
                setColumns([])
            } finally {
                setIsLoadingColumns(false)
            }
        },
        [authInfo, orgs]
    )

    const handleError = (error) => {
        console.error(error)
        setError(
            typeof error === 'string' ? error : 'An unexpected error occurred'
        )
        setIsProcessing(false)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="manage-tables-modal-title"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    height: '90%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        variant="h6"
                        component="h2"
                        id="manage-tables-modal-title"
                    >
                        Table Gallery
                    </Typography>
                    <Box>
                        <IconButton onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        borderBottom: 1,
                        borderColor: 'divider',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        <Tab label="Insert Table(s)" />
                        <Tab label="Manage Tables" />
                        <Tab label="Preview" />
                    </Tabs>
                    <Button
                        variant="outlined"
                        onClick={handleOpenUploadModal}
                        sx={{ mr: 2 }}
                    >
                        Bulk Upload
                    </Button>
                </Box>

                <Box sx={{ flexGrow: 1, overflow: 'auto', mt: 2 }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    {isProcessing && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: 2,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    {activeTab === 0 && (
                        <>
                            <Alert severity="info" sx={{ mb: 2 }}>
                                Add tables from the gallery to specific sections
                                in your document. You can insert multiple tables
                                at once and preview them before inserting.
                            </Alert>
                            <InsertTablesTab
                                sectionInfo={sectionInfo}
                                tables={tables}
                                loading={loading}
                                error={error}
                                onTableInserted={(title, url, message) => {
                                    onTableInserted(title, url, message)
                                    handleClose()
                                }}
                                tableAssignments={tableAssignments}
                                onTableAssignmentsUpdate={
                                    onTableAssignmentsUpdate
                                }
                                onTableSelect={handleTableSelect}
                                documentId={documentId}
                            />
                        </>
                    )}
                    {activeTab === 1 && (
                        <>
                            <Alert severity="info" sx={{ mb: 2 }}>
                                Replace or update existing tables in your
                                document. You can modify table content using
                                filters and preview changes before updating.
                            </Alert>
                            <Alert severity="info" sx={{ mb: 3 }}>
                                <Typography variant="body2">
                                    <strong>
                                        Table Management Instructions:
                                    </strong>
                                    <ul
                                        style={{
                                            margin: '4px 0',
                                            paddingLeft: '20px',
                                        }}
                                    >
                                        <li>
                                            First select the document section
                                            containing the table you want to
                                            update
                                        </li>
                                        <li>
                                            Choose the existing table you want
                                            to replace
                                        </li>
                                        <li>
                                            Select a new table from the gallery
                                            to replace it with
                                        </li>
                                        <li>
                                            Optionally add filters to customize
                                            the table data
                                        </li>
                                        <li>
                                            Preview the new table before
                                            confirming the update
                                        </li>
                                    </ul>
                                </Typography>
                            </Alert>
                            <ManageTablesTab
                                sectionInfo={sectionInfo}
                                tables={tables}
                                loading={loading}
                                error={error}
                                documentId={documentId}
                                selectedSection={selectedSection}
                                setSelectedSection={setSelectedSection}
                                sectionTables={sectionTables}
                                selectedExistingTable={selectedExistingTable}
                                setSelectedExistingTable={
                                    setSelectedExistingTable
                                }
                                selectedGalleryTable={selectedGalleryTable}
                                setSelectedGalleryTable={
                                    setSelectedGalleryTable
                                }
                                columns={columns}
                                operator={operator}
                                setOperator={setOperator}
                                comparator={comparator}
                                setComparator={setComparator}
                                newTableTitle={newTableTitle}
                                setNewTableTitle={setNewTableTitle}
                                isProcessing={isProcessing}
                                setIsProcessing={setIsProcessing}
                                isLoadingColumns={isLoadingColumns}
                                fetchSectionTables={fetchSectionTables}
                                fetchColumns={fetchColumns}
                                setError={handleError}
                                onTableInserted={(title, url, message) => {
                                    onTableInserted(title, url, message)
                                    handleClose()
                                }}
                            />
                        </>
                    )}
                    {activeTab === 2 && (
                        <>
                            <GalleryTab
                                tables={tables}
                                loading={loading}
                                error={error}
                                onTableSelect={handleTableSelect}
                                setError={handleError}
                                isProcessing={isProcessing}
                                setIsProcessing={setIsProcessing}
                            />
                        </>
                    )}
                </Box>

                {selectedTable && (
                    <TableDetailsView
                        table={selectedTable}
                        onClose={handleTableClose}
                    />
                )}

                <BulkUploadModal
                    open={isUploadModalOpen}
                    onClose={handleCloseUploadModal}
                    documentType={documentId}
                    documentId={documentId}
                />
            </Box>
        </Modal>
    )
}

export default ManageTablesModal
