import React, { useState, useEffect } from 'react'
import {
    Typography,
    Button,
    Box,
    Stepper,
    Step,
    StepLabel,
    Checkbox,
    TextField,
    IconButton,
    Divider,
    Chip,
    ThemeProvider,
    Paper,
    Skeleton,
    FormControlLabel,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    uploadMultipleFilesUpdated,
    getAnalytesFromDocument,
    generate265Tables,
    extractAndVectorizePKTables,
} from '../../../utils/utils'
import { MultipleFileUploader } from '../../../components/MultipleFileUploader'
import { ArtosTheme } from '../../../components/Themes'
import { useAuthInfo } from '@propelauth/react'

const TabularDataGenerationComponent = ({
    onNextClick,
    onBackClick,
    documentName,
    productName,
    documentType,
    documentSet,
    uploadedFiles,
    selectedCloudFiles,
    getOrgAccessToken,
    isLastStep,
}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [documents, setDocuments] = useState([])
    const [selectedItems, setSelectedItems] = useState({})
    const [newParameter, setNewParameter] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [pkFile, setPkFile] = useState(null)
    const [pkFileWithId, setPkFileWithId] = useState(null)
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [isFileProcessed, setIsFileProcessed] = useState(false)

    const steps = ['Upload PK Files', 'Configure PK Data', 'Generate Tables']

    const authInfo = useAuthInfo()

    useEffect(() => {
        if (isFileProcessed) {
            initializeData()
        }
    }, [isFileProcessed])

    const initializeData = async () => {
        setLoading(true)
        try {
            const orgAccessToken = await getOrgAccessToken()
            const analyteResults = await getAnalytesFromDocument(
                [pkFileWithId],
                orgAccessToken
            )

            if (analyteResults && typeof analyteResults === 'object') {
                const processedResult = {
                    document: pkFileWithId,
                    ...analyteResults,
                    parameters: ['Cmax', 'AUC t-0', 'Tmax', 't 1/2'],
                }
                setDocuments([processedResult])
                initializeSelectedItems([processedResult])
                setActiveStep(1) // Automatically move to the next step
            } else {
                throw new Error('Invalid analyte results returned')
            }
        } catch (error) {
            console.error('Error initializing data:', error)
            setError('Failed to initialize data. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const initializeSelectedItems = (documents) => {
        const initialItems = {}
        documents.forEach((doc) => {
            initialItems[doc.document] = {
                analytes: [],
                methods: [],
                doses: [],
                parameters: [],
                test_article: productName,
            }
        })
        setSelectedItems(initialItems)
    }

    const handleFileUpload = async (files, fileContents) => {
        if (files.length === 0) {
            setError('Please select a file to upload.')
            return
        }

        try {
            setLoading(true)
            const orgAccessToken = await getOrgAccessToken()

            const file = files[0] // Only handle the first file
            const fileContent = fileContents[0]

            console.log('File to upload:', file.name)

            // Upload file without orgId
            const uploadResult = await uploadMultipleFilesUpdated(
                setLoading,
                [{ name: file.name, file: file }],
                [fileContent],
                setError,
                'input',
                orgAccessToken
            )

            console.log('Upload result:', uploadResult)

            // Store the uploaded file name
            setPkFile(file.name)
            setIsFileUploaded(true)

            // Immediately process the uploaded file
            await processPKFile(file.name, orgAccessToken)
        } catch (error) {
            console.error('Error uploading and processing PK file:', error)
            setError('Failed to upload and process PK file. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const processPKFile = async (fileName, orgAccessToken) => {
        try {
            setLoading(true)
            const orgs = authInfo.orgHelper.getOrgs()
            const org = orgs[0].orgId
            const fileNameWithId = `${org}/${fileName}`
            setPkFileWithId(fileNameWithId)

            // Call extractAndVectorizePKTables with orgId
            const result = await extractAndVectorizePKTables(
                fileNameWithId,
                orgAccessToken
            )
            console.log('Vectorization result:', result)

            setIsFileProcessed(true)
        } catch (error) {
            console.error('Error processing PK file:', error)
            setError('Failed to process PK file. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const handleNext = () => {
        if (activeStep === 0 && !isFileProcessed) {
            setError('Please upload and process a file before proceeding.')
            return
        }
        setActiveStep((prevStep) => prevStep + 1)
    }

    const handleBack = () => {
        if (activeStep === 0) {
            onBackClick()
        } else {
            setActiveStep((prevStep) => prevStep - 1)
        }
    }

    const handleCheckboxChange = (docName, itemType, item) => {
        setSelectedItems((prevState) => {
            const updatedItems = { ...prevState }
            if (itemType === 'doses') {
                updatedItems[docName][itemType] = updatedItems[docName][
                    itemType
                ].some((d) => d.dose === item.dose && d.route === item.route)
                    ? updatedItems[docName][itemType].filter(
                          (d) => d.dose !== item.dose || d.route !== item.route
                      )
                    : [...updatedItems[docName][itemType], item]
            } else {
                updatedItems[docName][itemType] = updatedItems[docName][
                    itemType
                ].includes(item)
                    ? updatedItems[docName][itemType].filter((i) => i !== item)
                    : [...updatedItems[docName][itemType], item]
            }
            return updatedItems
        })
    }

    const handleAddParameter = (docName) => {
        if (newParameter.trim() === '') return
        setDocuments((prevDocs) =>
            prevDocs.map((doc) =>
                doc.document === docName
                    ? {
                          ...doc,
                          parameters: [...doc.parameters, newParameter.trim()],
                      }
                    : doc
            )
        )
        setNewParameter('')
    }

    const handleRemoveParameter = (docName, parameter) => {
        setDocuments((prevDocs) =>
            prevDocs.map((doc) =>
                doc.document === docName
                    ? {
                          ...doc,
                          parameters: doc.parameters.filter(
                              (p) => p !== parameter
                          ),
                      }
                    : doc
            )
        )
    }

    const handleGenerateTables = async () => {
        setLoading(true)
        try {
            const orgAccessToken = await getOrgAccessToken()
            const payload = {
                input_path: [pkFileWithId],
                input_container: 'input',
                template_name: documentType,
                document_name: `${
                    authInfo.orgHelper.getOrgs()[0].orgId
                }/${documentName}`,
                extract: false,
                json_data_extracts: Object.entries(selectedItems).map(
                    ([docName, data]) => ({
                        input_document: docName,
                        analytes: data.analytes,
                        doses: data.doses.map((dose) => dose.dose),
                        methods: data.methods,
                        parameters: data.parameters,
                        test_article: data.test_article,
                    })
                ),
                document_set: documentSet,
            }
            await generate265Tables(payload, orgAccessToken)
            onNextClick()
        } catch (error) {
            console.error('Error generating tables:', error)
            setError('Failed to generate tables. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Upload PK Files
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: '#f5f5f5',
                                p: 2,
                                borderRadius: 1,
                            }}
                        >
                            <MultipleFileUploader
                                handleFiles={handleFileUpload}
                            />
                            {pkFile && (
                                <Box mt={2}>
                                    <Typography variant="subtitle1">
                                        Uploaded file:
                                    </Typography>
                                    <Chip
                                        label={pkFile}
                                        onDelete={() => {
                                            setPkFile(null)
                                            setIsFileUploaded(false)
                                            setIsFileProcessed(false)
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            mt={1}
                        >
                            Please upload the PK data file you want to use for
                            table generation.
                        </Typography>
                    </Box>
                )
            case 1:
                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Configure PK Data
                        </Typography>
                        {documents.map((doc, index) => (
                            <Box
                                key={index}
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: 1,
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {doc.document}
                                </Typography>
                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            textTransform: 'capitalize',
                                            mt: 1,
                                        }}
                                    >
                                        Analytes
                                    </Typography>
                                    {doc.analytes?.map((analyte, idx) => (
                                        <FormControlLabel
                                            key={idx}
                                            control={
                                                <Checkbox
                                                    checked={selectedItems[
                                                        doc.document
                                                    ]?.analytes?.includes(
                                                        analyte
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            doc.document,
                                                            'analytes',
                                                            analyte
                                                        )
                                                    }
                                                />
                                            }
                                            label={analyte}
                                        />
                                    ))}
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            textTransform: 'capitalize',
                                            mt: 1,
                                        }}
                                    >
                                        Methods
                                    </Typography>
                                    {doc.methods?.map((method, idx) => (
                                        <FormControlLabel
                                            key={idx}
                                            control={
                                                <Checkbox
                                                    checked={selectedItems[
                                                        doc.document
                                                    ]?.methods?.includes(
                                                        method
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            doc.document,
                                                            'methods',
                                                            method
                                                        )
                                                    }
                                                />
                                            }
                                            label={method}
                                        />
                                    ))}
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            textTransform: 'capitalize',
                                            mt: 1,
                                        }}
                                    >
                                        Doses
                                    </Typography>
                                    {doc.doses?.map((dose, idx) => (
                                        <FormControlLabel
                                            key={idx}
                                            control={
                                                <Checkbox
                                                    checked={selectedItems[
                                                        doc.document
                                                    ]?.doses?.some(
                                                        (d) =>
                                                            d.dose ===
                                                                dose.dose &&
                                                            d.route ===
                                                                dose.route
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            doc.document,
                                                            'doses',
                                                            dose
                                                        )
                                                    }
                                                />
                                            }
                                            label={`${dose.dose} (${dose.route})`}
                                        />
                                    ))}
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            textTransform: 'capitalize',
                                            mt: 1,
                                        }}
                                    >
                                        Parameters
                                    </Typography>
                                    {doc.parameters.map((parameter, idx) => (
                                        <FormControlLabel
                                            key={idx}
                                            control={
                                                <Checkbox
                                                    checked={selectedItems[
                                                        doc.document
                                                    ]?.parameters?.includes(
                                                        parameter
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            doc.document,
                                                            'parameters',
                                                            parameter
                                                        )
                                                    }
                                                />
                                            }
                                            label={parameter}
                                        />
                                    ))}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mt: 1,
                                        }}
                                    >
                                        <TextField
                                            value={newParameter}
                                            onChange={(e) =>
                                                setNewParameter(e.target.value)
                                            }
                                            placeholder="New parameter"
                                            size="small"
                                            sx={{ mr: 1 }}
                                        />
                                        <IconButton
                                            onClick={() =>
                                                handleAddParameter(doc.document)
                                            }
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )
            case 2:
                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Generate Tables
                        </Typography>
                        <Typography variant="body1">
                            Click 'Generate' to create the PK tables based on
                            your selections.
                        </Typography>
                    </Box>
                )
            default:
                return 'Unknown step'
        }
    }

    const renderSkeletonLoader = () => (
        <Box>
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                sx={{ my: 2 }}
            />
            <Box sx={{ mt: 4 }}>
                {[1, 2, 3].map((item) => (
                    <Box key={item} sx={{ mb: 3 }}>
                        <Skeleton variant="text" width="40%" height={30} />
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={120}
                            sx={{ mt: 1 }}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    )

    return (
        <ThemeProvider theme={ArtosTheme}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <Paper
                    elevation={1}
                    sx={{
                        width: '100%',
                        maxWidth: '75vw',
                        p: 4,
                        backgroundColor: '#ffffff',
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Generate PK Tables
                    </Typography>
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        sx={{ mb: 4 }}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 4, mb: 4 }}>
                        {loading
                            ? renderSkeletonLoader()
                            : renderStepContent(activeStep)}
                    </Box>
                    <Divider sx={{ my: 4 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            onClick={handleBack}
                            variant="outlined"
                            disabled={loading}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                activeStep === steps.length - 1
                                    ? handleGenerateTables
                                    : handleNext
                            }
                            disabled={
                                loading ||
                                (activeStep === 0 && !isFileProcessed)
                            }
                        >
                            {activeStep === steps.length - 1
                                ? isLastStep
                                    ? 'Generate'
                                    : 'Next'
                                : 'Next'}
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </ThemeProvider>
    )
}

export default TabularDataGenerationComponent
