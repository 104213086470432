import React, { useRef } from 'react'
import { Box } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

function CSVUploader({ onUpload }) {
    const fileInputRef = useRef(null)

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file && file.type === 'text/csv') {
            onUpload(file)
        } else {
            alert('Please upload a CSV file.')
        }
    }

    return (
        <Box sx={{ my: 2 }}>
            <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <button
                className="main-button"
                onClick={() => fileInputRef.current.click()}
                style={{ width: '100%' }}
            >
                <CloudUploadIcon sx={{ mr: 1 }} />
                Upload CSV
            </button>
        </Box>
    )
}

export default CSVUploader
