import React from 'react'
import Select from 'react-select'

const SelectInput = ({
    options,
    isSearchable,
    selectedOption,
    setSelectedOption,
    isLoading = false,
}) => {
    return (
        <Select
            className="basic-single"
            classNamePrefix="select"
            value={options.find((option) => option.value === selectedOption)}
            isSearchable={isSearchable}
            name="color"
            options={options}
            onChange={setSelectedOption}
            isLoading={isLoading}
            isDisabled={isLoading}
        />
    )
}

export default SelectInput
