import React from 'react'
import { Box, Typography, ThemeProvider, Alert } from '@mui/material'
import { ArtosTheme } from '../../../components/Themes'

const BaseSidebarTab = ({ title, children, description }) => {
    return (
        <ThemeProvider theme={ArtosTheme}>
            <Box sx={{ p: 1 }}>
                <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>
                    {title}
                </Typography>
                {description ? (
                    <Alert severity="info">{description}</Alert>
                ) : null}
                <Box sx={{ mt: 2 }}>{children}</Box>
            </Box>
        </ThemeProvider>
    )
}

export default BaseSidebarTab
