import React, { useState, useEffect } from 'react'
import { Modal, Skeleton } from '@mui/material'
import PDFWebViewComponent from '../../../components/PDFWebViewComponent'
import '../../../App.css'
import { useDemo } from '../../../contexts/DemoContext'

const SourceSelectionModal = ({
    open,
    handleClose,
    onSourceSelect,
    inputFiles,
    isLoadingInputFiles,
    documentName,
    productName,
    documentType,
    outline,
    id,
    handleStepChange,
    setError,
    orgId,
}) => {
    const [selectedFiles, setSelectedFiles] = useState([])
    const [viewTemplateModal, setViewTemplateModal] = useState(false)
    const [viewFileUrl, setViewFileUrl] = useState('')
    const { isDemo, setIsDemo } = useDemo()

    const handleSelectFile = (index, file, isRemove) => {
        if (isRemove) {
            setSelectedFiles(selectedFiles.filter((f) => f.name !== file.name))
        } else {
            setSelectedFiles([...selectedFiles, file])
        }
    }

    const confirmSelection = () => {
        onSourceSelect(selectedFiles)
        handleClose()
    }

    const filterFiles = (files) => {
        if (isDemo) {
            return files
        }

        // Hardcoded file types based on document type
        let fileType
        switch (documentType) {
            case 'Artos-12345 CSR Template':
                fileType = 'CSR'
                break
            case 'Artos-12345 PSUR Template':
                fileType = 'PSUR'
                break
            case 'Artos-12345 Stability Data':
                fileType = 'CMC'
                break
            case 'Artos-12345 Stability Summary Template':
                fileType = 'CMC'
                break
            default:
                return files
        }

        return files.filter((file) => file.name.includes(`(${fileType})`))
    }

    const InputFiles = () => {
        const filteredFiles = filterFiles(inputFiles)

        if (isLoadingInputFiles) {
            return (
                <>
                    <Skeleton
                        variant="rounded"
                        width={'60vw'}
                        height={'10vh'}
                        animation="wave"
                    />
                    <br />
                    <Skeleton
                        variant="rounded"
                        width={'60vw'}
                        height={'10vh'}
                        animation="wave"
                    />
                    <br />
                    <Skeleton
                        variant="rounded"
                        width={'60vw'}
                        height={'10vh'}
                        animation="wave"
                    />
                </>
            )
        } else {
            return (
                <>
                    {filteredFiles.map((file, index) => (
                        <div
                            key={index}
                            className={`multiple-file-upload-card ${
                                selectedFiles.some((f) => f.name === file.name)
                                    ? 'selected-file-card'
                                    : ''
                            }`}
                        >
                            <div className="file-info">
                                <p className="file-name">{file.name}</p>
                            </div>
                            <div className="file-actions">
                                <button
                                    className="view-file-btn"
                                    style={{
                                        marginRight: '2px',
                                    }}
                                    onClick={() => {
                                        setViewFileUrl(file.url)
                                        setViewTemplateModal(true)
                                    }}
                                >
                                    View
                                </button>
                                {selectedFiles.some(
                                    (f) => f.name === file.name
                                ) ? (
                                    <button
                                        className="remove-file-btn"
                                        onClick={() =>
                                            handleSelectFile(index, file, true)
                                        }
                                    >
                                        Remove
                                    </button>
                                ) : (
                                    <button
                                        className="select-file-btn"
                                        onClick={() =>
                                            handleSelectFile(index, file, false)
                                        }
                                    >
                                        Select
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            )
        }
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <div className="modal-background">
                <div className="modal-content">
                    {!viewTemplateModal ? (
                        <div>
                            <h2>Choose From Library</h2>
                            <div
                                className="files-container"
                                style={{
                                    maxHeight: '40vh',
                                    overflow: 'auto',
                                }}
                            >
                                <InputFiles />
                            </div>
                            <div
                                className="modal-button-container"
                                style={{ margin: '20px' }}
                            >
                                <button
                                    className="generate-modal-submit grey-button"
                                    onClick={handleClose}
                                >
                                    <p>Return</p>
                                </button>
                                {selectedFiles.length >= 1 && (
                                    <button
                                        className="generate-modal-submit"
                                        onClick={confirmSelection}
                                    >
                                        Confirm Selection
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h4 style={{ textAlign: 'center' }}>
                                View Template
                            </h4>
                            <div
                                className="webviewContainer"
                                style={
                                    viewFileUrl
                                        ? {
                                              height: '60vh',
                                              width: '40vw',
                                              visibility: 'visible',
                                              margin: 'auto',
                                          }
                                        : {
                                              height: '0',
                                              width: '0',
                                              visibility: 'hidden',
                                              margin: 'auto',
                                          }
                                }
                            >
                                <PDFWebViewComponent
                                    initialFile={viewFileUrl}
                                ></PDFWebViewComponent>
                            </div>
                            <div
                                className="modal-buttons"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <button
                                    className="select-file-btn"
                                    style={{ margin: '5px' }}
                                    onClick={() => setViewTemplateModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default SourceSelectionModal
