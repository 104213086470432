import {
    useEffect,
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
    useCallback,
} from 'react'
import WebViewer from '@pdftron/webviewer'
import { saveFile } from '../utils/utils'
import save from '../icons/Save.svg'
import { useAuthInfo } from '@propelauth/react'
import { useWebViewer } from '../contexts/WebViewerContext'
import { Box, Container, Skeleton } from '@mui/material'

const WebViewComponent = forwardRef(
    // ({ hasLink, initialFile, fileKey, id, onTextSelected, userId }, ref) => {
    (
        {
            initialFile,
            id,
            userId,
            onLoaded,
            onVectorizationStart,
            onVectorizationEnd,
        },
        ref
    ) => {
        const { setWebViewerInstance } = useWebViewer()

        const viewer = useRef(null)

        const [selectedText, setSelectedText] = useState('')
        const [pageNumber, setPageNumber] = useState(null)
        const [currentZoom, setCurrentZoom] = useState(1)
        const [isLoading, setIsLoading] = useState(true)
        const [instance, setInstance] = useState(null)
        const [isActionButtonAdded, setIsActionButtonAdded] = useState(false) // Step 1: New state variable
        var buttonNeeded = true

        const authInfo = useAuthInfo()

        const orgs = authInfo.orgHelper.getOrgs()

        async function getOrgAccessToken(orgId) {
            const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(
                orgId
            )
            // console.log('orgAccessToken', orgAccessToken)
            return orgAccessToken
        }

        const SkeletonLoader = () => (
            <Container maxWidth="md">
                {/* Header */}
                <Box mb={4}>
                    <Skeleton variant="rectangular" width="100%" height={60} />
                </Box>

                {/* Content */}
                {[...Array(4)].map((_, index) => (
                    <Box key={index} mb={4}>
                        <Skeleton variant="text" width="90%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="95%" height={20} />
                        <Skeleton variant="text" width="98%" height={20} />
                        <Skeleton variant="text" width="92%" height={20} />
                    </Box>
                ))}

                {/* Footer */}
                <Box mt={6}>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                </Box>
            </Container>
        )

        // Make the method accessible from the parent component
        useImperativeHandle(ref, () => ({
            getText: () => {
                return { selectedText, pageNumber }
            },
        }))

        useEffect(() => {
            console.log(viewer.current)
            console.log(initialFile)
            if (viewer.current && initialFile) {
                console.log('initialFile', initialFile)
                WebViewer(
                    {
                        path: '/public',
                        licenseKey: process.env.REACT_APP_APRYSE_KEY,
                        initialDoc: initialFile,
                        enableOfficeEditing: true,
                        extension: 'docx',
                        // loadAsPDF: true,
                        // annotationUser: userId,
                        // preloadWorker: 'office',
                        // backendType: 'asm',
                        fullAPI: true,
                        // fallbackToClientSide: true,
                        streaming: true,

                        // autoExpandOutlines: true,
                        // onLoadingProgress: (percent) => {
                        //     console.log('percent loaded:', percent)
                        // },
                        // streaming: true,
                    },
                    viewer.current
                ).then((i) => {
                    setInstance(i)
                    const {
                        documentViewer,
                        Annotations,
                        annotationManager,
                        Tools,
                    } = i.Core

                    documentViewer.addEventListener('documentLoaded', () => {
                        setWebViewerInstance(i)
                        setIsLoading(false)
                        onLoaded() // Notify parent component that loading is complete
                        console.log('doc Loaded HERE')

                        const doc = documentViewer.getDocument()

                        // const textSelectedListener = (quads, text, page) => {
                        //     if (text.length > 0) {
                        //         setSelectedText(text)
                        //         setPageNumber(page)
                        //         // console.log('page number', text, page);
                        //     }
                        // }

                        // annotationManager.setAnnotationDisplayAuthorMap(
                        //     (userId) => {
                        //         return userId
                        //     }
                        // )

                        // annotationManager.setAnnotationDisplayAuthorMap(mapNames);

                        // // Now you can get a display author by passing in a unique ID
                        // const displayAuthor = annotationManager.getDisplayAuthor(annotation.Author);

                        // documentViewer.addEventListener(
                        //     'textSelected',
                        //     textSelectedListener
                        // )

                        // Add Save button modal
                        const modalOptions = {
                            dataElement: 'documentSavedModal',
                            header: {
                                title: 'Notification',
                                className: 'documentSavedModal-header',
                            },
                            body: {
                                className: 'documentSavedModal-body',
                                title: 'Document Saved!',
                                style: { textAlign: 'center' },
                            },
                            footer: {
                                className: 'documentSavedModal-footer',
                                style: { color: 'blue' },
                                children: [
                                    {
                                        title: 'OK',
                                        button: true,
                                        style: {
                                            backgroundColor: '#0078D4',
                                            color: '#0078D4',
                                            border: 'none',
                                            borderRadius: '5px',
                                            border: '1px solid #0078D4',
                                            cursor: 'pointer',
                                            display: 'block',
                                            margin: 'auto', // Centers the button horizontally},
                                        },
                                        className:
                                            'modal-button confirm ok-btn',
                                        onClick: (e) => {
                                            console.log('OK button')
                                            i.UI.closeElements([
                                                'documentSavedModal',
                                            ])
                                        },
                                    },
                                ],
                            },
                        }

                        i.UI.addCustomModal(modalOptions)

                        i.UI.setHeaderItems((header) => {
                            if (buttonNeeded) {
                                console.log('pushing item!')
                                header.push({
                                    type: 'actionButton',
                                    img: save,
                                    onClick: async () => {
                                        onVectorizationStart()
                                        console.log('started')
                                        const orgAccessToken =
                                            await getOrgAccessToken(
                                                orgs[0].orgId
                                            )

                                        const data = await doc.getFileData({})
                                        const arr = new Uint8Array(data)
                                        const blob = new Blob([arr])
                                        await saveFile(id, blob, orgAccessToken)
                                        onVectorizationEnd()
                                    },
                                })
                                buttonNeeded = false
                            }
                        })
                    })
                    // documentViewer.addEventListener(
                    //     'pageNumberUpdated',
                    //     (pageNumber) => {
                    //         setPageNumber(pageNumber)
                    //         setCurrentZoom(documentViewer.getPageZoom())
                    //     }
                    // )
                })
            }
        }, [initialFile])

        // useEffect(() => {
        //     if (instance) {
        //         setIsActionButtonAdded(true)
        //         console.log('Loading new document:', initialFile)
        //         // var doc = await instance.docViewer.getDocument().getFileData({flags: instance.Core.SaveOptions.LINEARIZED});
        //         // instance.UI.loadDocument(initialFile, {extension: 'docx', enableOfficeEditing: true})
        //     }
        // }, [initialFile, instance])

        return (
            <div
                className="MyComponent"
                style={{
                    paddingLeft: '5px',
                    position: 'relative',
                    height: '100%',
                }}
            >
                {isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1000,
                            backgroundColor: 'white',
                        }}
                    >
                        <SkeletonLoader />
                    </div>
                )}
                <div
                    className="webviewer"
                    ref={viewer}
                    style={{
                        opacity: isLoading ? 0 : 1,
                        transition: 'opacity 0.5s ease-in-out',
                        height: '100%',
                    }}
                ></div>
            </div>
        )
    }
)

export default WebViewComponent
