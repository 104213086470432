import React, { useState, useEffect, useCallback } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Paper,
    Chip,
    Card,
    CardContent,
    Alert,
} from '@mui/material'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiContext,
    useGridSelector,
    gridFilterModelSelector,
} from '@mui/x-data-grid'

const steps = ['Select Section', 'Select Table(s)', 'Review']

const TableAssignmentWizard = ({
    open,
    onClose,
    sectionInfo,
    tables,
    onAssign,
}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedTables, setSelectedTables] = useState([])
    const [sectionSearchTerm, setSectionSearchTerm] = useState('')
    const [filterModel, setFilterModel] = useState({
        items: [],
    })

    const CustomToolbar = () => {
        const apiRef = useGridApiContext()
        const filterModel = useGridSelector(apiRef, gridFilterModelSelector)

        return (
            <GridToolbarContainer>
                <Box sx={{ width: '100%', p: 1 }}>
                    <Typography variant="h6" gutterBottom>
                        {selectedSection?.section_name}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <GridToolbarFilterButton />
                    </Box>
                </Box>
            </GridToolbarContainer>
        )
    }

    useEffect(() => {
        if (open) {
            setActiveStep(0)
            setSelectedSection(null)
            setSelectedTables([])
            setSectionSearchTerm('')
        }
    }, [open])

    const handleNext = () =>
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    const handleBack = () =>
        setActiveStep((prevActiveStep) => prevActiveStep - 1)

    const handleSectionSelect = (section) => {
        setSelectedSection(section)
        handleNext()
    }

    const handleTableSelect = (selectionModel) => {
        const selectedTableData = tables.filter((table) =>
            selectionModel.includes(table.id)
        )
        setSelectedTables(selectedTableData)
    }

    const handleAssign = () => {
        onAssign(selectedSection, selectedTables)
        onClose()
    }

    const filteredSections = sectionInfo.filter((section) =>
        section.section_name
            .toLowerCase()
            .includes(sectionSearchTerm.toLowerCase())
    )

    const columns = [
        { field: 'title', headerName: 'Title', flex: 1, filterable: true },
        // { field: 'type', headerName: 'Type', flex: 1, filterable: true },
        // {
        //     field: 'population',
        //     headerName: 'Population',
        //     flex: 1,
        //     filterable: true,
        // },
        // {
        //     field: 'sourceDocument',
        //     headerName: 'Source Document',
        //     flex: 1,
        //     filterable: true,
        // },
    ]

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            Select the document section where you want to insert
                            the table(s). You can use the search box to filter
                            sections by name.
                        </Alert>
                        <Typography variant="h6" gutterBottom>
                            Select a Section
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search sections..."
                            value={sectionSearchTerm}
                            onChange={(e) =>
                                setSectionSearchTerm(e.target.value)
                            }
                            sx={{ mb: 2 }}
                        />
                        <List>
                            {filteredSections.map((section) => (
                                <ListItem
                                    key={section.section_name}
                                    disablePadding
                                >
                                    <Paper
                                        elevation={1}
                                        sx={{ width: '100%', mb: 1 }}
                                        variant="outlined"
                                    >
                                        <ListItemButton
                                            onClick={() =>
                                                handleSectionSelect(section)
                                            }
                                        >
                                            <ListItemText
                                                primary={section.section_name}
                                            />
                                        </ListItemButton>
                                    </Paper>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )
            case 1:
                return (
                    <Box>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            Select one or more tables to insert into the chosen
                            section. Use filters and search to find specific
                            tables. You can preview tables before selection.
                        </Alert>
                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .MuiDataGrid-root .MuiDataGrid-checkboxInput':
                                    {
                                        display: 'block',
                                    },
                                '& .MuiDataGrid-root input[type="checkbox"]': {
                                    display: 'block',
                                },
                            }}
                        >
                            <DataGrid
                                rows={tables}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5, 10, 20]}
                                checkboxSelection
                                onRowSelectionModelChange={handleTableSelect}
                                filterModel={filterModel}
                                onFilterModelChange={(newModel) =>
                                    setFilterModel(newModel)
                                }
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        selectedSection,
                                    },
                                }}
                                // disableRowSelectionOnClick
                            />
                        </Box>
                    </Box>
                )
            case 2:
                return (
                    <Box>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            Review your selections before confirming. Make sure
                            the tables are appropriate for the chosen section.
                            You can go back to make changes if needed.
                        </Alert>
                        <Box sx={{ mb: 3, px: 2 }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 700,
                                    mb: 0.5,
                                }}
                            >
                                {selectedSection?.section_name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Review the tables to be assigned to this section
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 2,
                                px: 2,
                            }}
                        >
                            {selectedTables.map((table) => (
                                <Paper
                                    key={table.id}
                                    sx={{
                                        p: 2,
                                        minWidth: 300,
                                        maxWidth: '100%',
                                        flex: '1 1 300px',
                                    }}
                                    elevation={2}
                                    variant="outlined"
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            // fontWeight: 700,
                                            mb: 0.5,
                                        }}
                                    >
                                        {table.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ mb: 1 }}
                                    >
                                        {table.description ||
                                            'No description provided'}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        <Chip
                                            label={`Type: ${table.type}`}
                                            size="small"
                                        />
                                        <Chip
                                            label={`Population: ${table.population}`}
                                            size="small"
                                        />
                                        {/* <Chip
                                            label={table.sourceDocument}
                                            size="small"
                                        /> */}
                                    </Box>
                                </Paper>
                            ))}
                        </Box>
                    </Box>
                )
            default:
                return null
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={activeStep === 1 ? false : 'md'}
            fullWidth
            PaperProps={{
                style:
                    activeStep === 1 ? { maxWidth: '90%', height: '90%' } : {},
            }}
        >
            <DialogTitle>Assign Table to Section</DialogTitle>
            <DialogContent
                sx={{
                    height:
                        activeStep === 1 ? 'calc(100% - 64px - 64px)' : 'auto',
                }}
            >
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{ mb: 4 }}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {renderStepContent(activeStep)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
                {activeStep < steps.length - 1 ? (
                    <Button
                        onClick={handleNext}
                        disabled={
                            (activeStep === 0 && !selectedSection) ||
                            (activeStep === 1 && selectedTables.length === 0)
                        }
                    >
                        Next
                    </Button>
                ) : (
                    <Button
                        onClick={handleAssign}
                        variant="contained"
                        color="primary"
                    >
                        Assign
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default TableAssignmentWizard
