export const formatSectionText = async (sectionLabel) => {
    // Guard against null or undefined input
    if (!sectionLabel) return ''

    // Regex to match number prefix pattern (e.g. "6.1.2 ")
    const numberPattern = /^\d+(\.\d+)*\s+(.+)$/

    // Try to match the pattern
    const match = sectionLabel.match(numberPattern)

    // If no match found, return original string
    if (!match) return sectionLabel

    // Extract the text portion (captured in second group)
    const text = match[2]

    // Capitalize each word
    return text
        .split(' ')
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
}
