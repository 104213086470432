import React, { useState } from 'react'
import MainButton from '../../../components/MainButton'
import { extractAndStoreTFLTables } from '../../../utils/utils'

const BulkUploadComponent = ({
    onBackClick,
    onNextClick,
    documentType,
    orgAccessToken,
    isLastStep,
    documentId,
}) => {
    const [selectedFile, setSelectedFile] = useState(null)
    const [error, setError] = useState('')
    const [isUploaded, setIsUploaded] = useState(false)

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            if (file.size > 100 * 1024 * 1024) {
                setError('File size exceeds 100 MB limit')
                setSelectedFile(null)
            } else {
                setSelectedFile(file)
                setError('')
                setIsUploaded(false)
            }
        }
    }

    const handleUpload = async () => {
        if (!selectedFile) {
            setError('Please select a file')
            return
        }

        try {
            // In theory, we have to upload the file to the input container FIRST
            const response = await extractAndStoreTFLTables(
                selectedFile.name,
                'input',
                'tables',
                documentType,
                documentId,
                orgAccessToken
            )
            console.log('Upload successful:', response)
            setIsUploaded(true)
            setError('')
            // Handle successful upload (e.g., show success message)
        } catch (error) {
            console.error('Upload failed:', error)
            setError('Upload failed. Please try again.')
            setIsUploaded(false)
        }
    }

    return (
        <div className="bulk-upload-container">
            <h2>Bulk Table Upload</h2>
            <p>Upload a file containing PDFs or RTFs (max 100 MB)</p>
            <input type="file" accept=".pdf,.rtf" onChange={handleFileChange} />
            {selectedFile && (
                <p>
                    Selected file: {selectedFile.name} (
                    {(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
                </p>
            )}
            {error && <p className="error-message">{error}</p>}
            {isUploaded && (
                <p className="success-message">File uploaded successfully!</p>
            )}

            <MainButton
                onClick={handleUpload}
                text="Upload"
                width="100px"
                backgroundColor="#1f477d"
                fontColor="#ffffff"
                className="unfilled-button"
                border="2px solid #1f477d"
                disabled={!selectedFile || isUploaded}
                style={{ marginBottom: '20px' }}
            />

            <div className="button-container">
                <MainButton
                    onClick={onBackClick}
                    text="Back"
                    width="100px"
                    backgroundColor="#ffffff"
                    fontColor="#1f477d"
                    className="main-button"
                    border="2px solid #1f477d"
                    style={{ marginRight: '10px' }}
                />
                <MainButton
                    onClick={onNextClick}
                    text={isLastStep ? 'Generate' : 'Next'}
                    width="100px"
                    backgroundColor="#1f477d"
                    fontColor="#ffffff"
                    className="unfilled-button"
                    border="2px solid #1f477d"
                    disabled={!isUploaded}
                />
            </div>
        </div>
    )
}

export default BulkUploadComponent
