import { createTheme } from '@mui/material/styles'

export const ArtosTheme = createTheme({
    palette: {
        primary: {
            main: '#1F477D',
        },
        // main: '#1F477D',
    },
    typography: {
        fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
    },
})
