import React, { useState, useEffect, useCallback } from 'react'
import {
    Box,
    Typography,
    Alert,
    TextField,
    Button,
    CircularProgress,
    Divider,
    Snackbar,
} from '@mui/material'
import Select from 'react-select'
import { delay, updateTabularDataToDoc } from '../../../../utils/utils'
import TableDetailsView from './TableDetailsView'
import { useAuthInfo } from '@propelauth/react'

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#f5f5f5' : 'white',
        cursor: state.isDisabled ? 'not-allowed' : 'default',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        bottom: '100%',
        top: 'auto',
        marginBottom: '8px',
    }),
}

const ManageTablesTab = ({
    sectionInfo,
    documentId,
    tables,
    loading,
    error,
    selectedSection,
    setSelectedSection,
    sectionTables,
    selectedExistingTable,
    setSelectedExistingTable,
    selectedGalleryTable,
    setSelectedGalleryTable,
    columns,
    operator,
    setOperator,
    comparator,
    setComparator,
    newTableTitle,
    setNewTableTitle,
    isProcessing,
    setIsProcessing,
    isLoadingColumns,
    fetchSectionTables,
    fetchColumns,
    setError,
    onTableInserted,
}) => {
    // Add state for preview functionality
    const [showPreview, setShowPreview] = useState(false)
    const [previewTable, setPreviewTable] = useState(null)
    const [selectedColumn, setSelectedColumn] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()

    const getOrgAccessToken = useCallback(async () => {
        return await authInfo.tokens.getAccessTokenForOrg(orgs[0].orgId)
    }, [authInfo, orgs])

    useEffect(() => {
        if (selectedSection) {
            fetchSectionTables(selectedSection)
        }
    }, [selectedSection])

    // Preview table handler
    const handlePreviewTable = (table) => {
        setPreviewTable(table)
        setShowPreview(true)
    }

    // Handle gallery table selection
    const handleGalleryTableSelect = (selected) => {
        setSelectedGalleryTable(selected)
        setSelectedColumn(null) // Reset column selection

        if (selected?.value?.csvPath) {
            fetchColumns(selected.value.csvPath)
        }
    }

    // Reset form function
    const resetForm = () => {
        setSelectedExistingTable(null)
        setSelectedGalleryTable(null)
        setOperator(null)
        setComparator('')
        setNewTableTitle('')
        setSelectedColumn(null)
        setSnackbarOpen(false)
        setError(null)
    }

    // Handle table update
    const handleUpdateTable = async () => {
        if (
            !selectedSection ||
            !selectedExistingTable ||
            !selectedGalleryTable
        ) {
            setError(
                'Please select a section, existing table, and gallery table'
            )
            return
        }

        setIsProcessing(true)

        try {
            const updatePayload = {
                section_header: selectedSection.value,
                document_id: documentId,
                filter_column: selectedColumn?.value || '',
                operator: operator?.value || '',
                comparator: operator?.value ? comparator : '',
                csv_path: `${selectedGalleryTable.value.csvPath.replace(
                    `${orgs[0].orgId}/`,
                    ''
                )}`,
                table_to_update: selectedExistingTable.value,
                table_title: newTableTitle || selectedExistingTable.value,
                csv_input_container: 'tables',
                document_container: 'output',
            }

            const orgAccessToken = await getOrgAccessToken()
            const url = await updateTabularDataToDoc(
                updatePayload,
                orgAccessToken
            )

            // After successful update
            await delay(500)

            resetForm()
            onTableInserted(
                newTableTitle || selectedExistingTable.value,
                url,
                'Table successfully updated'
            )
        } catch (err) {
            setError('Failed to update table')
            console.error(err)
        } finally {
            setIsProcessing(false)
        }
    }

    // Options for dropdowns
    const sectionOptions = sectionInfo.map((section) => ({
        value: section.section_name,
        label: section.section_name,
    }))

    const galleryTableOptions = tables.map((table) => ({
        value: table,
        label: table.title,
    }))

    const operatorOptions = [
        { value: '', label: 'None' },
        { value: '==', label: '=' },
        { value: '>', label: '>' },
        { value: '>=', label: '≥' },
        { value: '<', label: '<' },
        { value: '<=', label: '≤' },
        { value: '!=', label: '≠' },
    ]

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setSnackbarOpen(false)
    }

    return (
        <Box
            sx={{
                // height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                position: 'relative',
            }}
        >
            {/* Alerts section */}
            <Box sx={{ mb: 2 }}>
                {error && typeof error === 'string' && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
            </Box>

            {/* Section Selection */}
            <Box>
                <Typography variant="subtitle1" gutterBottom>
                    Select Document Section
                </Typography>
                <Select
                    options={sectionOptions}
                    value={selectedSection}
                    onChange={setSelectedSection}
                    placeholder={
                        loading ? 'Loading sections...' : 'Choose a section'
                    }
                    styles={customStyles}
                    isDisabled={loading || isProcessing}
                    isLoading={loading}
                    loadingMessage={() => 'Loading sections...'}
                />
            </Box>

            {/* Existing Table Selection */}
            {selectedSection && (
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        Select Existing Table to Update
                    </Typography>
                    <Select
                        options={sectionTables}
                        value={selectedExistingTable}
                        onChange={setSelectedExistingTable}
                        placeholder={
                            loading ? 'Loading tables...' : 'Choose a table'
                        }
                        styles={customStyles}
                        isDisabled={loading || isProcessing}
                        isLoading={loading}
                        loadingMessage={() => 'Loading tables...'}
                    />
                </Box>
            )}

            {/* Gallery Table Selection */}
            {selectedExistingTable && (
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        Select Gallery (Source) Table
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <Select
                                options={galleryTableOptions}
                                value={selectedGalleryTable}
                                onChange={handleGalleryTableSelect}
                                placeholder="Choose a table from gallery"
                                styles={customStyles}
                                isDisabled={loading || isProcessing}
                            />
                        </Box>
                        {selectedGalleryTable && (
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    handlePreviewTable(
                                        selectedGalleryTable.value
                                    )
                                }
                            >
                                Preview Table
                            </Button>
                        )}
                    </Box>
                </Box>
            )}

            {/* Table Update Options */}
            {selectedGalleryTable && (
                <>
                    <Divider />
                    <Box sx={{ position: 'relative', zIndex: 1 }}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                New Table Title (Optional)
                            </Typography>
                            <TextField
                                fullWidth
                                value={newTableTitle}
                                onChange={(e) =>
                                    setNewTableTitle(e.target.value)
                                }
                                placeholder={selectedGalleryTable.value.title}
                                disabled={loading || isProcessing}
                            />
                        </Box>

                        {/* Filter Controls */}
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            {/* Operator Selection */}
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Data Cutoff Operator (optional)
                                </Typography>
                                <Select
                                    options={operatorOptions}
                                    value={operator}
                                    onChange={setOperator}
                                    placeholder="Select operator"
                                    styles={customStyles}
                                    isDisabled={loading || isProcessing}
                                />
                            </Box>

                            {/* Column Selection */}
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Filter Column (optional)
                                </Typography>
                                <Select
                                    options={columns.map((col) => ({
                                        value: col,
                                        label: col,
                                    }))}
                                    value={selectedColumn}
                                    onChange={setSelectedColumn}
                                    placeholder={
                                        isLoadingColumns
                                            ? 'Loading columns...'
                                            : 'Select column'
                                    }
                                    styles={customStyles}
                                    isDisabled={
                                        loading ||
                                        isProcessing ||
                                        isLoadingColumns ||
                                        !operator ||
                                        operator.value === ''
                                    }
                                    isLoading={isLoadingColumns}
                                />
                            </Box>

                            {/* Value Input */}
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Filter Value (optional)
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={comparator}
                                    onChange={(e) =>
                                        setComparator(e.target.value)
                                    }
                                    placeholder={
                                        loading ||
                                        isProcessing ||
                                        !operator ||
                                        operator.value === '' ||
                                        !selectedColumn
                                            ? 'Disabled...'
                                            : 'Enter value'
                                    }
                                    disabled={
                                        loading ||
                                        isProcessing ||
                                        !operator ||
                                        operator.value === '' ||
                                        !selectedColumn
                                    }
                                    size="small"
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            <Button
                variant="contained"
                onClick={handleUpdateTable}
                disabled={
                    !selectedExistingTable ||
                    !selectedGalleryTable ||
                    isProcessing
                }
                sx={{ mt: 2 }}
            >
                {isProcessing ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    'Update Table'
                )}
            </Button>

            {/* Preview Modal */}
            {showPreview && previewTable && (
                <TableDetailsView
                    table={previewTable}
                    onClose={() => {
                        setShowPreview(false)
                        setPreviewTable(null)
                    }}
                />
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Table successfully updated"
            />
        </Box>
    )
}

export default ManageTablesTab
