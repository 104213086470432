import React, { useEffect, useState, useCallback } from 'react'
import {
    Box,
    Typography,
    Alert,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Divider,
} from '@mui/material'
import Select from 'react-select'
import { useAuthInfo } from '@propelauth/react'
import {
    uploadMultipleFilesUpdated,
    getCSVColumns,
    preprocessUploadedCSV,
    addTabularDataToDoc,
    updateTabularDataToDoc,
    getTableTitlesInSection,
    delay,
} from '../../../utils/utils'
import CSVUploader from '../CSVUploader'
import BaseSidebarTab from './BaseSidebarTab'
import ManageTablesModal from '../modals/tables/ManageTablesModal'
import { TableView } from '@mui/icons-material'
import { formatSectionText } from '../../../utils/sectionExtract'

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#f5f5f5' : 'white',
        cursor: state.isDisabled ? 'not-allowed' : 'default',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 11,
    }),
}

function TablesComponent({
    id,
    sectionInfo,
    onTableInserted,
    tableAssignments,
    onTableAssignmentsUpdate,
}) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [csvFiles, setCsvFiles] = useState([])
    const [csvColumns, setCsvColumns] = useState([])
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedColumn, setSelectedColumn] = useState(null)
    const [operator, setOperator] = useState(null)
    const [comparator, setComparator] = useState('')
    const [tableTitle, setTableTitle] = useState('')
    const [sections, setSections] = useState([])
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [replaceMode, setReplaceMode] = useState(false)
    const [tableTitles, setTableTitles] = useState([])
    const [selectedTableToReplace, setSelectedTableToReplace] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [sectionOptions, setSectionOptions] = useState([])
    const [isManageTablesModalOpen, setIsManageTablesModalOpen] =
        useState(false)

    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()

    useEffect(() => {
        setSections(sectionInfo)
    }, [sectionInfo])

    const getOrgAccessToken = useCallback(async () => {
        return await authInfo.tokens.getAccessTokenForOrg(orgs[0].orgId)
    }, [authInfo, orgs])

    const handleOpenManageTablesModal = () => {
        setIsManageTablesModalOpen(true)
    }

    const handleCloseManageTablesModal = () => {
        setIsManageTablesModalOpen(false)
    }

    const fetchTableTitles = useCallback(async () => {
        if (!selectedSection) {
            console.log('No section selected, skipping table titles fetch')
            return
        }

        try {
            const orgAccessToken = await getOrgAccessToken()
            const nextSectionIndex =
                sections.findIndex(
                    (section) => section.section_name === selectedSection.value
                ) + 1
            const nextSectionHeader =
                nextSectionIndex < sections.length
                    ? sections[nextSectionIndex].section_name
                    : 'END'

            var newHeader = await formatSectionText(selectedSection.value)
            var nextHeader = await formatSectionText(nextSectionHeader)
            const payload = {
                document_id: id,
                section_header: newHeader,
                next_section_header: nextHeader,
                input_container: 'output',
            }

            console.log('Fetching table titles with payload:', payload)
            const titles = await getTableTitlesInSection(
                payload,
                orgAccessToken
            )
            console.log('Fetched table titles:', titles)
            setTableTitles(titles)
            return titles
        } catch (err) {
            console.error('Error fetching table titles:', err)
            setError('Failed to fetch existing table titles')
            return []
        }
    }, [selectedSection])

    useEffect(() => {
        if (replaceMode && selectedSection) {
            fetchTableTitles()
        }
    }, [replaceMode, selectedSection, fetchTableTitles])

    const handleCSVUpload = async (file) => {
        setLoading(true)
        setError(null)
        setCsvFiles([file])
        try {
            const orgAccessToken = await getOrgAccessToken()

            await uploadMultipleFilesUpdated(
                setLoading,
                [file],
                [await file.text()],
                setError,
                'intermediate',
                orgAccessToken
            )

            await preprocessUploadedCSV(
                file.name,
                'intermediate',
                orgAccessToken
            )

            const columns = await getCSVColumns(
                file.name,
                orgAccessToken,
                'intermediate'
            )
            if (Array.isArray(columns)) {
                setCsvColumns(columns)
            } else {
                throw new Error('Unexpected response format from server')
            }
        } catch (err) {
            console.error('Error in CSV upload:', err)
            setError(
                err.message || 'An error occurred while processing the CSV file'
            )
            setCsvColumns([])
            setCsvFiles([]) // Clear the file on error
        } finally {
            setLoading(false)
        }
    }

    const handleTableInserted = async (insertedTable) => {
        await fetchTableTitles()
        console.log(insertedTable)
        onTableInserted(insertedTable)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (csvFiles.length === 0 || !selectedSection || !selectedColumn) {
            setError('Please fill in all required fields')
            return
        }
        setIsProcessing(true)
        setError(null)

        try {
            const orgAccessToken = await getOrgAccessToken()
            const basePayload = {
                section_header: selectedSection.value,
                document_id: id,
                filter_column: selectedColumn.value,
                operator: operator ? operator.value : '',
                comparator: operator && operator.value !== '' ? comparator : '',
                csv_input_container: 'intermediate',
                document_container: 'output',
            }

            let url
            if (replaceMode && selectedTableToReplace) {
                const updatePayload = {
                    ...basePayload,
                    csv_path: csvFiles[0].name,
                    table_to_update: selectedTableToReplace.value,
                    table_title: tableTitle || `Table ${sections.length + 1}`,
                }
                url = await updateTabularDataToDoc(
                    updatePayload,
                    orgAccessToken
                )
            } else {
                const addPayload = {
                    ...basePayload,
                    csv_paths: csvFiles.map((file) => file.name),
                    table_title: [tableTitle] || [
                        `Table ${sections.length + 1}`,
                    ],
                }
                url = await addTabularDataToDoc(addPayload, orgAccessToken)
            }

            await delay(2000)

            await fetchTableTitles()

            setTableTitle('')
            setIsSuccessModalOpen(true)
            onTableInserted(tableTitle, url)
        } catch (err) {
            console.error('Error adding/updating table in document:', err)
            setError(
                err.message || 'An error occurred while processing the table'
            )
        } finally {
            setIsProcessing(false)
        }
    }

    const getFormattedSections = async (sectionText) => {
        const sectionName = await formatSectionText(sectionText)
        return sectionName
    }

    const fetchSectionOptions = async () => {
        try {
            const returnedSectionOptions = await Promise.all(
                sections.map(async (section) => ({
                    value: section.section_name,
                    label: await getFormattedSections(section.section_name),
                }))
            )
            return returnedSectionOptions
        } catch (error) {
            console.error('Error fetching section options:', error)
            return []
        }
    }

    // Example usage within a component
    useEffect(() => {
        const loadSectionOptions = async () => {
            const options = await fetchSectionOptions()
            setSectionOptions(options)
        }

        loadSectionOptions()
    }, [sections])

    const columnOptions = csvColumns.map((column) => ({
        value: column,
        label: column,
    }))

    const operatorOptions = [
        { value: '', label: 'None' },
        { value: '==', label: '=' },
        { value: '>', label: '>' },
        { value: '>=', label: '≥' },
        { value: '<', label: '<' },
        { value: '<=', label: '≤' },
        { value: '!=', label: '≠' },
    ]

    const renderForm = () => (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={replaceMode}
                        onChange={(e) => {
                            setReplaceMode(e.target.checked)
                            setSelectedTableToReplace(null) // Reset selected table when toggling replace mode
                        }}
                        disabled={loading || isProcessing}
                    />
                }
                label="Replace existing table"
            />

            {sectionOptions.length > 0 && (
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Document Section
                    </Typography>
                    <Select
                        options={sectionOptions}
                        value={selectedSection}
                        onChange={(selected) => {
                            setSelectedSection(selected)
                            setSelectedTableToReplace(null) // Reset selected table when changing section
                        }}
                        placeholder="Choose a section"
                        styles={customStyles}
                        className="react-select"
                        isDisabled={loading || isProcessing}
                    />
                </Box>
            )}

            {replaceMode && tableTitles.length > 0 && (
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Table to Replace
                    </Typography>
                    <Select
                        options={tableTitles.map((title) => ({
                            value: title,
                            label: title,
                        }))}
                        value={selectedTableToReplace}
                        onChange={setSelectedTableToReplace}
                        placeholder="Select table to replace"
                        styles={customStyles}
                        className="react-select"
                        isDisabled={loading || isProcessing}
                    />
                </Box>
            )}

            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Data Column
                </Typography>
                <Select
                    options={columnOptions}
                    value={selectedColumn}
                    onChange={setSelectedColumn}
                    placeholder={
                        loading ? 'Loading columns...' : 'Choose a column'
                    }
                    styles={customStyles}
                    className="react-select"
                    isDisabled={!csvColumns.length || loading || isProcessing}
                />
            </Box>

            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Query Operator
                </Typography>
                <Select
                    options={operatorOptions}
                    value={operator}
                    onChange={setOperator}
                    placeholder="Operator"
                    styles={customStyles}
                    className="react-select"
                    isDisabled={!selectedColumn || loading || isProcessing}
                />
            </Box>

            {operator && operator.value !== '' && (
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Comparison Value
                    </Typography>
                    <TextField
                        fullWidth
                        label="Comparison Value"
                        value={comparator}
                        onChange={(e) => setComparator(e.target.value)}
                        helperText="Value to compare against the selected column."
                        disabled={loading || isProcessing}
                    />
                </Box>
            )}

            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Table Title
                </Typography>
                <TextField
                    fullWidth
                    label="Table Title"
                    value={tableTitle}
                    onChange={(e) => setTableTitle(e.target.value)}
                    helperText="Enter a title for your generated table"
                    disabled={loading || isProcessing}
                />
            </Box>

            <Button
                type="submit"
                variant="contained"
                color="primary"
                className="main-button"
                onClick={handleSubmit}
                disabled={
                    isProcessing ||
                    loading ||
                    !csvFiles.length ||
                    !selectedSection ||
                    !selectedColumn
                }
            >
                {isProcessing ? (
                    <CircularProgress size={24} color="inherit" />
                ) : replaceMode ? (
                    'Update Table'
                ) : (
                    'Generate Table'
                )}
            </Button>
        </Box>
    )

    // Modify the CSVUploader to show the current file name
    const renderCSVUploader = () => (
        <Box sx={{ mb: 2 }}>
            <CSVUploader
                onUpload={handleCSVUpload}
                buttonText={
                    csvFiles.length > 0
                        ? `Change CSV (Current: ${csvFiles[0].name})`
                        : 'Upload CSV'
                }
                disabled={loading || isProcessing}
            />
            {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                    <Typography variant="body2" color="textSecondary">
                        Processing CSV...
                    </Typography>
                </Box>
            )}
        </Box>
    )

    return (
        <BaseSidebarTab
            title="Table Management"
            description="Upload individual tables or manage your table gallery."
        >
            <Divider />

            {/* Two main action buttons at the top */}
            <Box sx={{ display: 'flex', gap: 2, mb: 3, mt: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<TableView />}
                    onClick={handleOpenManageTablesModal}
                    fullWidth
                >
                    Open Table Gallery
                </Button>
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Typography variant="h6" gutterBottom>
                Single Table Upload
            </Typography>

            <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
                Tables must contain exactly 1 header column row, which must be
                the first row.
            </Alert>

            {renderCSVUploader()}
            {error && (
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                    {error}
                </Alert>
            )}
            {csvFiles.length > 0 && renderForm()}

            {/* Modal remains at bottom */}
            <ManageTablesModal
                open={isManageTablesModalOpen}
                handleClose={handleCloseManageTablesModal}
                documentId={id}
                sectionInfo={sectionInfo}
                onTableInserted={onTableInserted}
                tableAssignments={tableAssignments}
                onTableAssignmentsUpdate={onTableAssignmentsUpdate}
            />
        </BaseSidebarTab>
    )
}

export default TablesComponent
