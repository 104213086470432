import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Modal, Skeleton, ThemeProvider } from '@mui/material'
import { useAuthInfo } from '@propelauth/react'
import { Tabs, Tab, Box } from '@mui/material'
import { Snackbar, Alert } from '@mui/material'

import '../../App.css'
import WebViewComponent from '../../components/WebViewComponent'
import OnlyOfficeViewerComponent from './DocViewer'

import { delay, getOneDocument } from '../../utils/utils'
import SectionComponent from '../../components/SectionComponent'
import InsightsComponent from '../../components/InsightsComponent'
import FormatComponent from '../../components/FormatComponent'
import PromptsComponent from '../../components/PromptsComponent'
import { ArtosTheme } from '../../components/Themes'
import TablesComponent from './tabs/TablesComponent'
import { useWebViewer } from '../../contexts/WebViewerContext'
import SectionTabComponent from './tabs/SectionTabComponent'

function DocumentPage() {
    const { id } = useParams()
    const location = useLocation()
    const { webViewerInstance } = useWebViewer()

    const [loading, setLoading] = useState(true)
    const [webViewerLoading, setWebViewerLoading] = useState(true)
    const [error, setError] = useState(false)
    const [documentUrl, setDocumentUrl] = useState('')
    const [documentName, setDocumentName] = useState('')
    const [sectionInfo, setSectionInfo] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const [isVectorizing, setIsVectorizing] = useState(false)
    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()
    const webViewRef = useRef(null)
    const [tableAssignments, setTableAssignments] = useState([])
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')

    const handleTableAssignmentsUpdate = (newAssignments) => {
        setTableAssignments(newAssignments)
    }

    const handleWebViewerLoaded = () => {
        setWebViewerLoading(false)
    }

    const isFullyLoaded = !loading && !webViewerLoading

    useEffect(() => {
        async function retrieveData() {
            const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
            try {
                setLoading(true)
                const result = await getOneDocument(
                    setLoading,
                    setError,
                    id,
                    orgAccessToken
                )
                setDocumentUrl(result['url'])
                setSectionInfo(result['section_info'])
                setDocumentName(result['document_name'])
                setLoading(false)
                setError(false)
            } catch (error) {
                console.error(error)
                setError(true)
            } finally {
                setLoading(false)
            }
        }
        retrieveData()
    }, [])

    async function getOrgAccessToken(orgId) {
        return await authInfo.tokens.getAccessTokenForOrg(orgId)
    }

    const handleButtonClick = () => {
        const { selectedText, pageNumber } = webViewRef.current.getText()
        console.log(
            `Selected text: ${selectedText}, Page number: ${pageNumber}`
        )
        return selectedText
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
    }

    const handleTableInserted = async (tableTitle, url, message) => {
        setSnackbarMessage(message || 'Table successfully inserted')
        setSnackbarOpen(true)

        if (webViewerInstance) {
            const doc = webViewerInstance.Core.documentViewer
            await delay(1500)
            await doc
                .loadDocument(url, {
                    extension: 'docx',
                    enableOfficeEditing: true,
                })
                .then(async () => {
                    await delay(1000)
                    webViewerInstance.UI.searchTextFull(tableTitle, {
                        caseSensitive: true,
                        wholeWord: true,
                        wildcard: false,
                        regex: false,
                        searchUp: true,
                        ambientString: false,
                    })
                })
        }
    }

    const SidebarSkeletonLoader = () => (
        <Box sx={{ padding: 2 }}>
            <Skeleton
                variant="rectangular"
                width="100%"
                height={40}
                sx={{ mb: 2 }}
            />
            <Skeleton variant="text" width="80%" height={20} sx={{ mb: 1 }} />
            <Skeleton variant="text" width="90%" height={20} sx={{ mb: 1 }} />
            <Skeleton variant="text" width="70%" height={20} sx={{ mb: 1 }} />
            <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                sx={{ mb: 2 }}
            />
            <Skeleton variant="text" width="85%" height={20} sx={{ mb: 1 }} />
            <Skeleton variant="text" width="75%" height={20} sx={{ mb: 1 }} />
        </Box>
    )

    useEffect(() => {
        console.log('isVectorizing', isVectorizing)
    }, [isVectorizing])

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setSnackbarOpen(false)
    }

    return (
        <div
            className="full-width-container"
            style={{ paddingLeft: '1px', paddingRight: '0px' }}
        >
            <div className="inner-container" style={{ margin: 0 }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        overflow: 'auto',
                        height: '100%',
                    }}
                >
                    <div
                        className="webviewContainer"
                        style={{
                            flex: 2,
                            width: '75%',
                            visibility: 'visible',
                        }}
                    >
                        <WebViewComponent
                            ref={webViewRef}
                            initialFile={documentUrl}
                            id={id}
                            userId={authInfo.user.email}
                            onLoaded={handleWebViewerLoaded}
                            onVectorizationStart={() => setIsVectorizing(true)}
                            onVectorizationEnd={() => setIsVectorizing(false)}
                        />
                    </div>
                    <div className="right-panel" style={{ flex: 1 }}>
                        {!isFullyLoaded || isVectorizing ? (
                            <>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}
                                >
                                    <ThemeProvider theme={ArtosTheme}>
                                        <SidebarSkeletonLoader />
                                    </ThemeProvider>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}
                                >
                                    <ThemeProvider theme={ArtosTheme}>
                                        <Tabs
                                            value={activeTab}
                                            onChange={handleTabChange}
                                            aria-label="document tabs"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                        >
                                            <Tab label="Section" />
                                            <Tab label="Global" />
                                            <Tab label="Insights" />
                                            <Tab label="Table" />
                                        </Tabs>
                                    </ThemeProvider>
                                </Box>
                                <Box sx={{ padding: 2 }}>
                                    {activeTab === 0 && (
                                        <SectionTabComponent
                                            id={id}
                                            sectionInfo={sectionInfo}
                                            documentUrl={documentUrl}
                                        />
                                    )}
                                    {activeTab === 1 && (
                                        <FormatComponent id={id} documentName={documentName} documentUrl={documentUrl} />
                                    )}
                                    {activeTab === 2 && (
                                        <InsightsComponent id={id} />
                                    )}
                                    {activeTab === 3 && (
                                        <TablesComponent
                                            id={id}
                                            sectionInfo={sectionInfo}
                                            onTableInserted={
                                                handleTableInserted
                                            }
                                            tableAssignments={tableAssignments}
                                            onTableAssignmentsUpdate={
                                                handleTableAssignmentsUpdate
                                            }
                                        />
                                    )}
                                </Box>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Modal open={error}>
                <div className="modal-background">
                    <div className="modal-content">
                        <h2>Error</h2>
                        <p>
                            There was an error loading the document. This
                            document may still be generating.
                        </p>
                        <button onClick={() => setError(false)}>Close</button>
                    </div>
                </div>
            </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default DocumentPage
