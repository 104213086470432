import React from 'react'
import MainButton from '../components/MainButton'
import '../App.css'
import LogoPNG from '../icons/LOGO.png'
import { useAuthInfo } from '@propelauth/react'
import { MultipleFileUploader } from '../components/MultipleFileUploader'
import paperclip from '../icons/Paper clip.svg'
import { useDemo } from '../contexts/DemoContext'

const AddSourcesComponent = ({
    onNextClick1,
    onBackClick,
    sourceClick,
    handleFiles,
    uploadedFiles,
    selectedCloudFiles,
    removeFile,
    handleMultipleFiles,
}) => {
    const authInfo = useAuthInfo()

    const org = authInfo.orgHelper.getOrgs()[0].orgId
    const integrations = [
        { name: 'Artos', status: 'Select from Library', img: LogoPNG },
    ]
    const { isDemo, setIsDemo } = useDemo()

    const totalFiles = uploadedFiles.length + selectedCloudFiles.length

    return (
        <div className="upload-container">
            <div className="input-container">
                <p
                    className="secondary-text"
                    style={{ fontWeight: 'bold', color: 'black' }}
                >
                    Select a system from which to upload your file(s):
                </p>
            </div>
            <div className="tiles-container">
                {integrations.map((integration, index) => (
                    <div className="tile" key={index}>
                        <button className="tile-content" onClick={sourceClick}>
                            <img src={integration.img} alt={integration.name} />
                            <p style={{ fontWeight: 'bold' }}>
                                {integration.status}
                            </p>
                        </button>
                    </div>
                ))}
            </div>
            <div
                style={{ marginTop: '2vh' }}
                className={`input-container ${
                    // Undo to disable demo uploading
                    // totalFiles < 1 && !isDemo ? 'dashed-border' : ''
                    totalFiles < 1 ? 'dashed-border' : ''
                }`}
            >
                {totalFiles >= 1 && (
                    <p style={{ textAlign: 'left' }}>Files Selected:</p>
                )}
                {totalFiles === 0 && (
                    <MultipleFileUploader
                        handleFiles={handleFiles}
                        // isDisabled={isDemo}
                    />
                )}
                {uploadedFiles.map((file) => (
                    <div className="multiple-file-upload-card" key={file.name}>
                        <div className="file-info">
                            <img src={paperclip} alt="Paperclip" />
                            <p className="file-name">{file.name} (Uploaded)</p>
                        </div>
                        <button
                            className="remove-file-btn"
                            onClick={() => removeFile(file.name, false)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
                {selectedCloudFiles.map((file) => (
                    <div className="multiple-file-upload-card" key={file.name}>
                        <div className="file-info">
                            <img src={paperclip} alt="Paperclip" />
                            <p className="file-name">{file.name} (Cloud)</p>
                        </div>
                        <button
                            className="remove-file-btn"
                            onClick={() => removeFile(file.name, true)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
            </div>

            <div className="button-container">
                <MainButton
                    onClick={onBackClick}
                    text="Back"
                    width="100px"
                    backgroundColor="#ffffff"
                    fontColor="#1f477d"
                    className="main-button"
                    border="2px solid #1f477d"
                    style={{ marginRight: '10px' }}
                />
                <MainButton
                    onClick={onNextClick1}
                    text="Next"
                    width="100px"
                    backgroundColor="#1f477d"
                    fontColor="#ffffff"
                    className="unfilled-button"
                    border="2px solid #1f477d"
                />
            </div>
        </div>
    )
}

export default AddSourcesComponent
