import React, { useState } from 'react'
import MainButton from './MainButton'
import SelectInput from './inputs/select'
import '../App.css'
import { Box, Checkbox, Divider, IconButton, Tooltip } from '@mui/material'
import { useDemo } from '../contexts/DemoContext'
import InfoIcon from '@mui/icons-material/Info'
import { Typography } from '@mui/material'

const CreateDocumentTextInput = ({
    onNextClick,
    categories,
    setDocumentName,
    setDocumentType,
    setProductName,
    setEnableBulkUpload,
    enableBulkUpload,
    isDocumentTypeLoading,
}) => {
    const [fileName, setFileName] = useState('')
    const [additionalText, setAdditionalText] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const { isDemo, setIsDemo } = useDemo()

    const handleFileNameChange = (event) => {
        setFileName(event.target.value)
        setDocumentName(event.target.value)
    }

    const handleAdditionalTextChange = (event) => {
        setAdditionalText(event.target.value)
        setProductName(event.target.value)
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.id)
        setDocumentType(event.label)
    }

    const renderLabelWithTooltip = (label, tooltipText) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <p className="secondary-text">{label}</p>
            <Tooltip title={tooltipText} arrow placement="right">
                <IconButton size="small" sx={{ p: 0 }}>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    )

    return (
        <div className="upload-container">
            <div className="input-container">
                <Box sx={{ mb: 2 }}>
                    <p className="secondary-text">Name:</p>
                    <input
                        type="text"
                        value={fileName}
                        onChange={handleFileNameChange}
                        placeholder="Enter a name for your file"
                        className="file-input"
                        style={{ width: '100%' }}
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    {renderLabelWithTooltip(
                        'Product Name:',
                        'Note: this must match the product name in the input documents. '
                    )}
                    {/* <p className="secondary-text">Product Name:</p> */}
                    <input
                        type="text"
                        value={additionalText}
                        onChange={handleAdditionalTextChange}
                        placeholder="DRUG-123"
                        className="file-input"
                        style={{ width: '100%' }}
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    {renderLabelWithTooltip(
                        'Document Type',
                        'Provide specific instructions for generating the section content.'
                    )}
                    <SelectInput
                        options={categories.map((c) => ({
                            label: c.label,
                            value: c.label,
                        }))}
                        isSearchable={true}
                        selectedOption={selectedOption}
                        setSelectedOption={handleOptionChange}
                        isLoading={isDocumentTypeLoading}
                    />
                </Box>

                {/* Bulk upload feature - commented out but formatted */}
                {/* <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <label>Enable Bulk Table Upload</label>
                        <Checkbox
                            checked={enableBulkUpload}
                            onChange={(e) => setEnableBulkUpload(!enableBulkUpload)}
                        />
                    </Box>
                </Box> */}
            </div>

            <div className="button-container">
                <MainButton
                    onClick={onNextClick}
                    text="Next"
                    width="100px"
                    backgroundColor="#1f477d"
                    fontColor="#ffffff"
                    className="main-button"
                />
            </div>
        </div>
    )
}

export default CreateDocumentTextInput
