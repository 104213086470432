// src/contexts/WebViewerContext.js
import React, { createContext, useContext, useState } from 'react'

const WebViewerContext = createContext(null)

export const WebViewerProvider = ({ children }) => {
    const [webViewerInstance, setWebViewerInstance] = useState(null)

    return (
        <WebViewerContext.Provider
            value={{ webViewerInstance, setWebViewerInstance }}
        >
            {children}
        </WebViewerContext.Provider>
    )
}

export const useWebViewer = () => useContext(WebViewerContext)
