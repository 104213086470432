import React from 'react'
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate } from 'react-router-dom'
import '../App.css'
import Modal from '@mui/material/Modal'
import { DataGrid } from '@mui/x-data-grid'
import { useAuthInfo } from '@propelauth/react'

import {
    createNewDocumentSet,
    getDocumentSets,
    newDocumentSetName,
} from '../utils/utils'
import { Alert, Box, Skeleton } from '@mui/material'
import { useDemo } from '../contexts/DemoContext'

function HomePage() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [documentSets, setDocumentSets] = useState([])

    const [newDocumentSetName, setNewDocumentSetName] = useState('')

    const [newDocumentModal, setNewDocumentModal] = useState(false)

    const handleNewDocumentModalOpen = () => setNewDocumentModal(true)
    const handleNewDocumentModalClose = () => setNewDocumentModal(false)
    const { isDemo, setIsDemo } = useDemo()

    const navigate = useNavigate()

    const handleRowClick = (params) => {
        navigate(`/documentSet/${params.row.doc_set}`, {
            state: { ...params.row },
        })
    }

    const authInfo = useAuthInfo()

    const orgs = authInfo.orgHelper.getOrgs()
    console.log('orgs', orgs)

    async function getOrgAccessToken(orgId) {
        const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(orgId)
        // console.log('orgAccessToken', orgAccessToken)
        return orgAccessToken
    }

    useEffect(() => {
        async function retrieveData() {
            const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
            setLoading(true) // Start loading
            try {
                const result = await getDocumentSets(
                    setLoading,
                    setError,
                    orgAccessToken
                ) // Assuming getDocumentSets returns the data
                if (result && result.length > 0) {
                    setDocumentSets(result) // Update state with fetched data
                    setError(false) // Reset error state if successful
                } else {
                    console.log('No documents found')
                    setDocumentSets([]) // Set document sets to an empty array
                    setError(false) // No error, just no documents found
                }
            } catch (error) {
                console.error(error)
                setLoading(false) // End loading regardless of outcome
                // setError(true); // Set error state
            } finally {
                setLoading(false) // End loading regardless of outcome
            }
        }
        retrieveData()
    }, [])

    const columns = [
        { field: 'doc_set', headerName: 'Document Set', flex: 1 },
        { field: 'owner', headerName: 'Owner', flex: 1 },
        { field: 'docs_generated', headerName: 'Documents', flex: 1 },
        { field: 'last_updated', headerName: 'Last Updated', flex: 1 },
        { field: 'created', headerName: 'Created', flex: 1 },
    ]

    const LoadingSkeleton = () => (
        <div className="loading-skeleton" style={{ padding: '25px' }}>
            <Box border={1} borderColor="grey.300" borderRadius={1}>
                <Box
                    display="flex"
                    borderBottom={1}
                    borderColor="grey.300"
                    p={1}
                >
                    <Skeleton variant="text" width="40%" height={30} />
                    <Skeleton
                        variant="text"
                        width="30%"
                        height={30}
                        style={{ marginLeft: '16px' }}
                    />
                    <Skeleton
                        variant="text"
                        width="20%"
                        height={30}
                        style={{ marginLeft: '16px' }}
                    />
                </Box>

                {[...Array(4)].map((_, index) => (
                    <Box
                        key={index}
                        display="flex"
                        borderBottom={1}
                        borderColor="grey.300"
                        p={1}
                    >
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton
                            variant="text"
                            width="30%"
                            height={20}
                            style={{ marginLeft: '16px' }}
                        />
                        <Skeleton
                            variant="text"
                            width="20%"
                            height={20}
                            style={{ marginLeft: '16px' }}
                        />
                    </Box>
                ))}
            </Box>
        </div>
    )

    return (
        <div className="full-width-container">
            <div className="inner-container">
                <div className="header-container">
                    <div className="main-panel-header">
                        <h2 className="top-main-panel">Document Sets</h2>
                    </div>
                    {isDemo && (
                        <Alert severity="info" sx={{ mb: 3 }}>
                            Organize various documents by drug development or
                            document type in a "Document Set".
                        </Alert>
                    )}
                    <div className="secondary-header-container">
                        <p className="secondary-text">
                            Manage and view all your submissions here.
                        </p>
                        <div
                            className="new-document-button"
                            onClick={() => handleNewDocumentModalOpen()}
                        >
                            <p className="text-white">New Document Set</p>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className="loading">
                        <LoadingSkeleton />
                    </div>
                ) : documentSets.length >= 1 ? (
                    <div className="datagrid">
                        <DataGrid
                            rows={documentSets}
                            columns={columns}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                        />
                    </div>
                ) : (
                    <div>No document sets found!</div>
                )}
            </div>
            <Modal
                open={newDocumentModal}
                onClose={handleNewDocumentModalClose}
            >
                <div className="modal-background">
                    <div className="modal-content">
                        <div className="form-container">
                            <h2>Let's get started!</h2>
                            <p>Enter a name for this Document Set:</p>
                            <input
                                className="modal-input"
                                type="text"
                                value={newDocumentSetName}
                                onChange={(e) =>
                                    setNewDocumentSetName(e.target.value)
                                }
                            ></input>
                            <button
                                className="modal-submit"
                                onClick={async () => {
                                    console.log(
                                        'newDocumentSetName',
                                        newDocumentSetName
                                    )
                                    const orgAccessToken =
                                        await getOrgAccessToken(orgs[0].orgId)

                                    await createNewDocumentSet(
                                        newDocumentSetName,
                                        orgAccessToken,
                                        orgAccessToken
                                    )
                                    handleNewDocumentModalClose()
                                    navigate(
                                        `/documentSet/${newDocumentSetName}`
                                    )
                                }}
                            >
                                <p className="text-white">Continue</p>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={error} onClose={() => setError(false)}>
                <div
                    className="modal-background"
                    style={{ width: '40%', height: '40%' }}
                >
                    <div className="modal-content">
                        Error. Please try again.
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default HomePage
