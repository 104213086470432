import React from 'react'
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import {
    Routes,
    Route,
    Link,
    useNavigate,
    Outlet,
    NavLink,
} from 'react-router-dom'
import './App.css'
import Modal from '@mui/material/Modal'
import { DataGrid } from '@mui/x-data-grid'
import HomePage from './pages/HomePage'
import DocumentSetsPage from './pages/DocumentSetsPage'
import DocumentPage from './pages/DocumentPage'
import TemplatesPage from './pages/TemplatesPage'
import AutomationsPage from './pages/AutomationsPage'
import IndividualAutomationPage from './pages/IndividualAutomationPage'
import EditTemplatePage from './pages/EditTemplatePage'
import CreateTemplatePage from './pages/CreateTemplatePage'
import logo from './icons/LOGO.svg'
import folderOpen from './icons/Folder open.svg'
import archive from './icons/Archive.svg'
import support from './icons/Support.svg'
import cog from './icons/Cog.svg'
import flag from './icons/Flag.svg'
import { withAuthInfo, useLogoutFunction } from '@propelauth/react'
import CreateDocumentPage from './pages/CreateDocumentPage'
import Sidebar from './components/nav/sidebar'
import {
    AppBar,
    Box,
    Button,
    ThemeProvider,
    Toolbar,
    Typography,
} from '@mui/material'
import { SidebarProvider } from './contexts/SidebarContext'
import { DemoProvider } from './contexts/DemoContext'
import { ArtosTheme } from './components/Themes'
import { WebViewerProvider } from './contexts/WebViewerContext'

// Define your page components here
const About = () => (
    <div>
        <h2>About</h2>
    </div>
)
const Dashboard = () => (
    <div>
        <h2>Dashboard</h2>
    </div>
)
const NoMatch = () => (
    <div>
        <div>No page found!</div>
        <h2>Nothing to see here.</h2>
        <p>
            <Link to="/">Go to the home page</Link>
        </p>
    </div>
)

const Help = () => (
    <div
        className="full-width-container"
        style={{ backgroundColor: '#F9FAFB' }}
    >
        <h2>Contact info@artosai.com for help and settings</h2>
    </div>
)

const Settings = ({ props }) => (
    <div
        className="full-width-container"
        style={{ backgroundColor: '#F9FAFB' }}
    >
        <h2>Currently signed in as: {props.user.email} </h2>
        <h2>Contact info@artosai.com for account details</h2>
    </div>
)

function Layout() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <Sidebar />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    overflow: 'auto',
                    height: '100%',
                    padding: '0px',
                }}
            >
                <Outlet />
            </Box>
        </Box>
    )
}

function App(props) {
    const logout = useLogoutFunction()
    const orgs = props.orgHelper.getOrgs()
    const org = orgs[0].orgId

    return props.isLoggedIn ? (
        <DemoProvider>
            <SidebarProvider>
                <WebViewerProvider>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100vh',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 2,
                                borderBottom: '1px solid #e0e0e0',
                                height: '64px',
                                backgroundColor: '#ffffff',
                                zIndex: 1100,
                                position: 'relative',
                            }}
                        >
                            <ThemeProvider theme={ArtosTheme}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={logo}
                                        style={{ marginRight: '8px' }}
                                        alt="Logo"
                                    />
                                    <Typography variant="h6" component="div">
                                        Artos
                                    </Typography>
                                </Box>
                                <Button
                                    onClick={logout}
                                    sx={{ color: '#1f477d' }}
                                >
                                    Log Out
                                </Button>
                            </ThemeProvider>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexGrow: 1,
                                overflow: 'hidden',
                            }}
                        >
                            <Routes>
                                <Route path="/" element={<Layout />}>
                                    <Route index element={<HomePage />} />
                                    <Route path="about" element={<About />} />
                                    <Route
                                        path="dashboard"
                                        element={<Dashboard />}
                                    />
                                    <Route
                                        path="automations"
                                        element={<AutomationsPage />}
                                    />
                                    <Route path="help" element={<Help />} />
                                    <Route
                                        path="settings"
                                        element={<Settings props={props} />}
                                    />
                                    <Route
                                        path="templates"
                                        element={<TemplatesPage />}
                                    />
                                    <Route
                                        path="documentSet/:id"
                                        element={
                                            <DocumentSetsPage
                                                authProps={props}
                                            />
                                        }
                                    />
                                    <Route
                                        path="individualAutomationPage"
                                        element={<IndividualAutomationPage />}
                                    />
                                    <Route
                                        path="document/:id"
                                        element={<DocumentPage />}
                                    />
                                    <Route
                                        path="createDocument"
                                        element={<CreateDocumentPage />}
                                    />
                                    <Route
                                        path="editTemplate/:id"
                                        element={<EditTemplatePage />}
                                    />
                                    <Route
                                        path="createTemplate"
                                        element={<CreateTemplatePage />}
                                    />
                                    <Route path="*" element={<NoMatch />} />
                                </Route>
                            </Routes>
                        </Box>
                    </Box>
                </WebViewerProvider>
            </SidebarProvider>
        </DemoProvider>
    ) : (
        <div>Please sign in</div>
    )
}

export default withAuthInfo(App)
