import React from 'react'
import { useState, useEffect, useRef, useReducer, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom'
import {
    Button,
    Container,
    FormControl,
    TextField,
    FormControlLabel,
    h4,
    Radio,
    RadioGroup,
    Slide,
    Skeleton,
    Box,
    Alert,
    Tooltip,
} from '@mui/material'
import '../App.css'
import Modal from '@mui/material/Modal'
import { DataGrid } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { MultipleFileUploader } from '../components/MultipleFileUploader'
import PDFWebViewComponent from '../components/PDFWebViewComponent'
import {
    uploadMultipleFilesUpdated,
    generateOutline,
    generateBoth,
    createINDModule2Section,
    extractAndStoreMultiple,
    createOutputVectorstore,
    processTemplate,
    listAllTemplates,
} from '../utils/utils'
import plus from '../icons/Plus.svg'
import paperclip from '../icons/Paper clip.svg'
import { useAuthInfo } from '@propelauth/react'
import { useDemo } from '../contexts/DemoContext'

function TemplatesPage() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [templateFiles, setTemplateFiles] = useState([])
    const [newTemplateName, setNewTemplateName] = useState('')
    const [newTemplateModal, setNewTemplateModal] = useState(false)
    const [viewTemplateModal, setViewTemplateModal] = useState(false)
    const [fileUrl, setFileUrl] = useState('')

    const [step, setStep] = useState(0)

    const { isDemo, setIsDemo } = useDemo()

    const documentTypeList = [
        'Nonclinical Overview',
        'Document Type 1',
        'Document Type 2',
        'Document Type 3',
        'Document Type 4',
        'Document Type 5',
    ]
    const [documentType, setDocumentType] = useState('')

    const [multipleFileList, setMultipleFileList] = useState([])
    const [multipleFileNameList, setMultipleFileNameList] = useState([])
    const [multipleFileContentList, setMultipleFileContentList] = useState([])

    const authInfo = useAuthInfo()

    const orgs = authInfo.orgHelper.getOrgs()

    const org = orgs[0].orgId

    async function getOrgAccessToken(orgId) {
        const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(orgId)
        // console.log('orgAccessToken', orgAccessToken)
        return orgAccessToken
    }

    const columns = [
        { field: 'doc_name', headerName: 'Document Name', flex: 2 },
        { field: 'doc_type', headerName: 'Document Type', flex: 1 },
        { field: 'created_date', headerName: 'Created Date', flex: 1 },
    ]

    const rows = templateFiles.map((file, index) => {
        return {
            id: index,
            created_date: file.created_date,
            doc_name: file.doc_name,
            doc_type: file.doc_type,
            url: file.url,
        }
    })

    const removeFile = (index) => {
        const newFileList = [...multipleFileList]
        newFileList.splice(index, 1)
        setMultipleFileList(newFileList) // Assuming your state setter is named like this
    }

    const handleContinue = () => {
        setStep((prevStep) => prevStep + 1)
    }

    const navigate = useNavigate()

    const navigateToPageWithUrl = (params) => {
        navigate(`/${params}`)
    }

    const navigateToTemplate = (params) => {
        console.log('params here', params.documentType)
        navigate(`/editTemplate/${params.documentType}`)
    }

    useEffect(() => {
        async function retrieveInputs() {
            const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
            setLoading(true) // Start loading
            try {
                // const result = await listAllFiles('templates'); // Assuming getDocumentSets returns the data
                const result = await listAllTemplates(orgAccessToken)
                console.log('template files', result)
                setTemplateFiles(result) // Update state with fetched data
                setError(false) // Reset error state if successful
            } catch (error) {
                console.error(error)
                setError(true) // Set error state
            } finally {
                setLoading(false) // End loading regardless of outcome
            }
        }
        retrieveInputs()
    }, [])

    const handleMultipleFiles = (files, fileContents) => {
        try {
            // Handle saving the file content or any other logic as needed for each file
            const newFiles = []
            console.log('files here', files)

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const content = fileContents[i]
                newFiles.push({ name: file.name, content })
                console.log('newFiles', newFiles)
            }

            // Update React state with the array of file objects
            setMultipleFileList(newFiles)
            setMultipleFileNameList(newFiles.map((file) => file.name))
            setMultipleFileContentList(fileContents)
        } catch (e) {
            console.log('Error in handling files', e)
        }
    }

    const LoadingSkeleton = () => (
        <div className="loading-skeleton" style={{ padding: '25px' }}>
            <Box border={1} borderColor="grey.300" borderRadius={1}>
                <Box
                    display="flex"
                    borderBottom={1}
                    borderColor="grey.300"
                    p={1}
                >
                    <Skeleton variant="text" width="40%" height={30} />
                    <Skeleton
                        variant="text"
                        width="30%"
                        height={30}
                        style={{ marginLeft: '16px' }}
                    />
                    <Skeleton
                        variant="text"
                        width="20%"
                        height={30}
                        style={{ marginLeft: '16px' }}
                    />
                </Box>

                {[...Array(4)].map((_, index) => (
                    <Box
                        key={index}
                        display="flex"
                        borderBottom={1}
                        borderColor="grey.300"
                        p={1}
                    >
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton
                            variant="text"
                            width="30%"
                            height={20}
                            style={{ marginLeft: '16px' }}
                        />
                        <Skeleton
                            variant="text"
                            width="20%"
                            height={20}
                            style={{ marginLeft: '16px' }}
                        />
                    </Box>
                ))}
            </Box>
        </div>
    )

    return (
        <div className="full-width-container">
            <div className="inner-container">
                <div className="header-container">
                    <div className="main-panel-header">
                        <h2 className="top-main-panel">Templates</h2>
                    </div>
                    {isDemo && (
                        <Alert severity="info" sx={{ mb: 2 }}>
                            A template must be inserted as a .docx file. Each
                            header must be either a Heading 1, Heading 2, or
                            Heading 3. These determine the outline in "Create
                            Document".
                        </Alert>
                    )}
                    <div
                        className="secondary-header-container"
                        style={isDemo ? { marginTop: '0px' } : {}}
                    >
                        <p className="secondary-text">
                            Manage all of your document templates here.
                        </p>
                        {/* <Tooltip
                            title={
                                isDemo
                                    ? 'Template creation is disabled in demo mode'
                                    : ''
                            }
                        > */}
                        <div
                            className="new-document-button"
                            onClick={
                                // isDemo
                                //     ? undefined
                                //     : () => navigate('/createTemplate')
                                () => navigate('/createTemplate')
                            }
                            style={{
                                // cursor: isDemo ? 'not-allowed' : '
                                cursor: 'pointer',
                                // opacity: isDemo ? 0.6 : 1,
                                opacity: 1,
                            }}
                        >
                            <p className="text-white">Create Template</p>
                        </div>
                        {/* </Tooltip> */}
                    </div>
                </div>

                {!loading && error && <div>Error...</div>}
                {loading ? (
                    <LoadingSkeleton />
                ) : rows.length > 0 ? ( // Check if there are rows to display
                    <div className="datagrid">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                            onRowClick={(params) => {
                                console.log('params', params)
                                navigateToTemplate({
                                    id: params.row.id,
                                    documentType: params.row.doc_type,
                                })
                                // setFileUrl(params.row.url);
                                // setViewTemplateModal(true);
                            }}
                        />
                    </div>
                ) : (
                    <div>No templates found!</div>
                )}
            </div>
            <Modal
                open={newTemplateModal}
                onClose={() => setNewTemplateModal(false)}
            >
                <div className="modal-background">
                    <Slide
                        direction="up"
                        in={step === 0}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            <h2>Enter Document Type</h2>
                            <div className="input-container">
                                <input
                                    type="text"
                                    value={documentType}
                                    onChange={(e) =>
                                        setDocumentType(e.target.value)
                                    }
                                    placeholder="Type the document type here"
                                    className="document-type-input"
                                    style={{
                                        width: '50vh',
                                        textAlign: 'center',
                                    }}
                                />
                            </div>
                            {documentType && (
                                <div className="modal-button-container">
                                    <button
                                        onClick={() => handleContinue()}
                                        className="generate-modal-submit"
                                    >
                                        <p className="text-white">Confirm</p>
                                    </button>
                                </div>
                            )}
                        </div>
                    </Slide>
                    <Slide
                        direction="up"
                        in={step === 1}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            <h4>Create New Template</h4>
                            <div className="form-container">
                                <div
                                    className={`input-container ${
                                        multipleFileList.length < 1
                                            ? 'dashed-border'
                                            : ''
                                    }`}
                                >
                                    {multipleFileList.length >= 1 && (
                                        <p style={{ textAlign: 'left' }}>
                                            Files Selected:
                                        </p>
                                    )}
                                    {multipleFileList.length == 0 && (
                                        <MultipleFileUploader
                                            handleFiles={handleMultipleFiles}
                                            allowMultiple={false}
                                        />
                                    )}
                                    {multipleFileList.length >= 1 && (
                                        <>
                                            {multipleFileList.map(
                                                (file, index) => (
                                                    <div
                                                        className="multiple-file-upload-card"
                                                        key={file.name}
                                                    >
                                                        <div className="file-info">
                                                            <img
                                                                src={paperclip}
                                                                alt="Paperclip"
                                                            />
                                                            <p className="file-name">
                                                                {file.name}
                                                            </p>
                                                        </div>
                                                        <button
                                                            className="remove-file-btn"
                                                            onClick={() =>
                                                                removeFile(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="modal-buttons">
                                <button
                                    className="select-file-btn"
                                    onClick={async () => {
                                        setLoading(true)
                                        handleContinue()
                                        const orgAccessToken =
                                            await getOrgAccessToken(
                                                orgs[0].orgId
                                            )
                                        await uploadMultipleFilesUpdated(
                                            setLoading,
                                            multipleFileList,
                                            multipleFileContentList,
                                            setError,
                                            'templates',
                                            orgAccessToken
                                        )
                                        await processTemplate(
                                            setLoading,
                                            setError,
                                            multipleFileNameList[0],
                                            documentType,
                                            orgAccessToken
                                        )
                                        setLoading(false)
                                        setNewTemplateModal(false)
                                        window.location.reload()
                                    }}
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                    </Slide>
                    <Slide
                        direction="up"
                        in={step === 2}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="modal-content">
                            {loading ? (
                                <div>Template Processing...</div>
                            ) : (
                                <>
                                    <h4>Template Created</h4>
                                    <div className="modal-buttons">
                                        <button
                                            className="select-file-btn"
                                            onClick={() =>
                                                setNewTemplateModal(false)
                                            }
                                        >
                                            Close
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </Slide>
                </div>
            </Modal>
            <Modal
                open={viewTemplateModal}
                onClose={() => setViewTemplateModal(false)}
            >
                <div className="modal-background">
                    <div className="modal-content">
                        <h4>View Template</h4>
                        <div
                            className="webviewContainer"
                            style={
                                fileUrl
                                    ? {
                                          height: '60vh',
                                          width: '75%',
                                          visibility: 'visible',
                                      }
                                    : {
                                          height: '0',
                                          width: '0',
                                          visibility: 'hidden',
                                      }
                            }
                        >
                            <PDFWebViewComponent
                                initialFile={fileUrl}
                            ></PDFWebViewComponent>
                        </div>
                        <div className="modal-buttons">
                            <button
                                className="select-file-btn"
                                style={{ margin: '5px' }}
                                onClick={() => setViewTemplateModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default TemplatesPage
