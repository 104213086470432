import React, { useState, useEffect, useCallback } from 'react'
import {
    Box,
    Typography,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Button,
    Chip,
    Tooltip,
    Skeleton,
    Grid,
    Modal,
    IconButton,
    Alert,
} from '@mui/material'
import Select from 'react-select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'

import { useAuthInfo } from '@propelauth/react'
import {
    savePrompts,
    reCreateINDModule2Section,
    listAllFiles,
    delay,
    getSources,
    createOutputVectorstore,
} from '../../../utils/utils'
import BaseSidebarTab from './BaseSidebarTab'
import { useWebViewer } from '../../../contexts/WebViewerContext'
import RulesModal from './RulesModal'
import SourceSelectionModal from './SourceSelectionModal'
import GetSources from './GetSources'
import { useDemo } from '../../../contexts/DemoContext'
import { formatSectionText } from '../../../utils/sectionExtract'

const modelOptions = [
    {
        value: 'Claude-3.5-Sonnett',
        label: 'Claude-3.5-Sonnet',
        image: '/images/claude.svg',
    },
    { value: 'GPT-4o', label: 'GPT-4o', image: '/images/gpt.svg' },
    {
        value: 'Llama-3-BioLLM',
        label: 'Llama-3-BioLLM',
        image: '/images/meta.svg',
    },
]

const tenseOptions = [
    { value: 'past', label: 'Past' },
    { value: 'present', label: 'Present' },
    { value: 'none', label: 'None' },
]

const customStyles = {
    option: (provided) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
    }),
    singleValue: (provided) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
    }),
}

const removeTrailingSlash = (str) => str.replace(/\/$/, '')

function SectionTabComponent({ id, sectionInfo, documentUrl }) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [selectedSection, setSelectedSection] = useState(null)
    const [prompt, setPrompt] = useState('')
    const [rules, setRules] = useState([])
    const [hasInitialRules, setHasInitialRules] = useState(false)
    const [model, setModel] = useState(modelOptions[0])
    const [topK, setTopK] = useState(15)
    const [temperature, setTemperature] = useState(0)
    const [tenseInstruction, setTenseInstruction] = useState(tenseOptions[0])
    const [styleInstructions, setStyleInstructions] = useState('')
    const [additionalInfo, setAdditionalInfo] = useState('')
    const [isRulesModalOpen, setIsRulesModalOpen] = useState(false)
    const [isSourceModalOpen, setIsSourceModalOpen] = useState(false)
    const [sections, setSections] = useState([])
    const [inputFiles, setInputFiles] = useState([])
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

    const [questions, setQuestions] = useState([])
    const [length, setLength] = useState('')
    const [depth, setDepth] = useState('')
    const [outline, setOutline] = useState('')

    const [OGSources, setOGSources] = useState([])
    const [newSources, setNewSources] = useState([])
    const [allSources, setAllSources] = useState([])
    const [documents, setDocuments] = useState([])

    const [selectedText, setSelectedText] = useState('')
    const [isGetSourcesExpanded, setIsGetSourcesExpanded] = useState(false)
    const [sourceResults, setSourceResults] = useState([])

    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()
    const { webViewerInstance } = useWebViewer()
    const { isDemo } = useDemo()

    const searchOptions = {
        caseSensitive: false,
        wholeWord: true,
        wildcard: false,
        regex: false,
        searchUp: true,
        ambientString: false,
    }

    async function getOrgAccessToken() {
        return await authInfo.tokens.getAccessTokenForOrg(orgs[0].orgId)
    }

    useEffect(() => {
        const getFormattedSections = async (sectionText) => {
            const sectionName = await formatSectionText(sectionText)
            return sectionName
        }
        const fetchSections = async () => {
            try {
                const formattedSections = await Promise.all(
                    sectionInfo.map(async (section) => {
                        const formattedSectionName = await getFormattedSections(
                            section.section_name
                        )
                        return {
                            ...section,
                            value: section.section_name,
                            label: formattedSectionName,
                            guidance: section.guidance,
                        }
                    })
                )
                setSections(formattedSections)
                setLoading(false)
            } catch (err) {
                console.error('Error fetching sections:', err)
                setError('Failed to load sections. Please try again.')
                setLoading(false)
            }
        }

        const fetchInputFiles = async () => {
            try {
                const orgAccessToken = await getOrgAccessToken()
                const files = await listAllFiles('input', orgAccessToken)
                setInputFiles(files)
            } catch (err) {
                console.error('Error fetching input files:', err)
            }
        }

        fetchSections()
        fetchInputFiles()
    }, [sectionInfo])

    useEffect(() => {
        if (selectedSection && webViewerInstance) {
            UISearch(selectedSection.label)
        }
    }, [selectedSection, webViewerInstance])

    useEffect(() => {
        setAllSources([...OGSources, ...newSources])
    }, [OGSources, newSources])

    useEffect(() => {
        if (webViewerInstance) {
            webViewerInstance.Core.documentViewer.addEventListener(
                'textSelected',
                (quads, selectedText, pageNumber) => {
                    if (selectedText.length > 0) {
                        setSelectedText(selectedText)
                    }
                }
            )
        }
    }, [webViewerInstance])

    useEffect(() => {
        const preloadDocuments = async () => {
            try {
                const orgAccessToken = await getOrgAccessToken()
                const files = await listAllFiles('input', orgAccessToken)
                setDocuments(files)
            } catch (err) {
                console.error('Error preloading documents:', err)
            }
        }

        preloadDocuments()
    }, [])

    const canGenerate = () => {
        if (hasInitialRules) {
            return rules.length > 0 && !loading
        }
        return !loading
    }

    async function UISearch(query) {
        const query2 = await formatSectionText(query)
        await delay(1500)
        webViewerInstance.UI.searchText(query2, searchOptions)
    }

    const handleGetSourcesExpand = useCallback(() => {
        setIsGetSourcesExpanded(true)
    }, [])

    const handleSectionChange = (selected) => {
        setSelectedSection(selected)
        const sectionData = sectionInfo.find(
            (s) => s.section_name === selected.value
        )

        const initialRules = sectionData.rules || []
        setHasInitialRules(initialRules.length > 0)

        setPrompt(sectionData.guidance || '')
        setQuestions(sectionData.questions || [])
        setLength(sectionData.length || '')
        setDepth(sectionData.depth || '')
        setOutline(sectionData.outline || '')
        setRules(initialRules)

        const ogSources = (sectionData.sources || []).map(removeTrailingSlash)
        setOGSources(ogSources)
        setNewSources([])

        if (sectionData.model) {
            setModel(
                modelOptions.find(
                    (option) => option.value === sectionData.model
                ) || modelOptions[0]
            )
        }
        if (sectionData.top_k !== undefined) setTopK(sectionData.top_k)
        if (sectionData.temperature !== undefined)
            setTemperature(sectionData.temperature)
        if (sectionData.tense_instructions) {
            setTenseInstruction(
                tenseOptions.find(
                    (option) => option.value === sectionData.tense_instructions
                ) || tenseOptions[0]
            )
        }
        if (sectionData.style_instructions)
            setStyleInstructions(sectionData.style_instructions)
        if (sectionData.additional_info)
            setAdditionalInfo(sectionData.additional_info)
    }

    const handleSourceAdd = (newSourcesInput) => {
        const orgId = orgs[0].orgId
        const filteredSources = newSourcesInput
            .filter((source) => !OGSources.includes(source))
            .map((source) => `${orgId}/${removeTrailingSlash(source)}`)
            .filter((source) => !newSources.includes(source))

        setNewSources((prevSources) => [...prevSources, ...filteredSources])
    }

    const handleSourceRemove = (sourceToRemove) => {
        setNewSources((prevSources) =>
            prevSources.filter((source) => source !== sourceToRemove)
        )
    }

    const handleRuleAdd = (newRule) => {
        // For summarize rules in demo mode, ensure only one topic
        if (
            isDemo &&
            newRule.type === 'summarize' &&
            newRule.topics?.length > 1
        ) {
            newRule.topics = [newRule.topics[newRule.topics.length - 1]]
        }

        // Ensure proper schema for all rules
        const formattedRule = {
            ...newRule,
            topics: newRule.topics || [], // Ensure topics is always an array
            instructions: newRule.instructions || '', // Ensure instructions is always a string
            modifications: newRule.modifications || '',
        }

        setRules((prevRules) => [...prevRules, formattedRule])
    }

    const handleRuleRemove = (ruleToRemove) => {
        setRules((prevRules) =>
            prevRules.filter((rule) => rule !== ruleToRemove)
        )
    }

    const handleGenerate = async () => {
        setLoading(true)
        setError(null)
        try {
            const orgAccessToken = await getOrgAccessToken()
            const updatedSectionInfo = sectionInfo.map((section) => {
                if (section.section_name === selectedSection.value) {
                    return {
                        ...section,
                        guidance: prompt,
                        questions,
                        length,
                        depth,
                        outline,
                        model: model.value,
                        top_k: topK,
                        temperature,
                        additional_info: additionalInfo,
                        style_instructions: styleInstructions,
                        tense_instructions: tenseInstruction.value,
                        rules,
                        sources: allSources,
                    }
                }
                return section
            })

            await savePrompts(
                setLoading,
                setError,
                id,
                updatedSectionInfo,
                orgAccessToken
            )

            // Fixed rules payload structure
            const rulesPayload = rules.map((rule) => {
                const orgId = orgs[0].orgId

                const nSources = rule.sources.map(
                    (source) => `${orgId}/${removeTrailingSlash(source)}`
                )
                console.log(nSources)
                return {
                    type: rule.type,
                    sources: nSources,
                    topics: rule.topics || [],
                    instructions: rule.instructions || '',
                    modifications: rule.modifications || '',
                    section: selectedSection.value,
                }
            })

            const regenerationPayload = {
                document_id: id,
                rules: rulesPayload, // Updated to use the new structure
                top_k: topK,
                temperature,
                additional_info: additionalInfo,
                tense_instructions: tenseInstruction.value,
                style_instructions: styleInstructions,
                new_sources: newSources,
                section_info: [
                    {
                        section_name: selectedSection.value,
                        sources: allSources,
                        questions,
                        guidance: prompt,
                        length,
                        depth,
                        outline,
                    },
                ],
                section: selectedSection.value,
                model: model.value,
            }

            const url = await reCreateINDModule2Section(
                regenerationPayload,
                orgAccessToken
            )
            await delay(2000)
            createOutputVectorstore(id, 'output', orgAccessToken)

            setLoading(false)
            setIsSuccessModalOpen(true)
            postGeneration(url)
        } catch (err) {
            console.error('Error regenerating section:', err)
            setError('Failed to regenerate section. Please try again.')
            setLoading(false)
        }
    }

    const formatSourceTitle = (source) => {
        return source.split('/').pop()
    }

    const postGeneration = async (url) => {
        if (webViewerInstance) {
            const doc = webViewerInstance.Core.documentViewer
            await delay(1500)
            await doc.loadDocument(url, {
                extension: 'docx',
                enableOfficeEditing: true,
            })
            if (selectedSection) {
                UISearch(selectedSection.label)
            }
        }
    }

    const handleSuccessModalClose = () => {
        setIsSuccessModalOpen(false)
        if (webViewerInstance && selectedSection) {
            UISearch(selectedSection.label)
        }
    }

    const handleGetSources = async (selectedText) => {
        setLoading(true)
        try {
            const orgAccessToken = await getOrgAccessToken()
            const sources = await getSources(
                setLoading,
                () => {},
                id,
                selectedText,
                orgAccessToken
            )
            return sources
        } catch (error) {
            console.error('Error getting sources:', error)
            throw error
        } finally {
            setLoading(false)
        }
    }

    const ConditionalGuide = () => {
        if (isDemo && hasInitialRules) {
            return (
                <>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        This section was generated using pre-defined "rules".
                        You can:
                        <br />- modify the rules used to generate the section
                        <br />- revise general content
                        <br />- change generation paramters
                    </Alert>
                </>
            )
        } else if (isDemo) {
            return (
                <>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        This section was generated without "rules". You can:
                        <br />- modifying the guidance prompt used to generate
                        this section
                        <br />- add rules to re-generate the section (replaces
                        the guidance prompt)
                        <br />- change generation paramters
                    </Alert>
                </>
            )
        }
    }

    const renderContent = () => (
        <>
            <Box sx={{ mb: 3 }}>
                <Alert severity="error" sx={{ mb: 2 }}>
                    If a section does not appear in search, scroll to the
                    section manually. Only sections that have been generated
                    will appear in search.
                </Alert>
                <Typography variant="subtitle1" gutterBottom>
                    Select Section
                </Typography>
                <Select
                    options={sections}
                    value={selectedSection}
                    onChange={handleSectionChange}
                    placeholder="Choose a section"
                    className="react-select"
                    isDisabled={loading}
                />
            </Box>

            {selectedSection && (
                <>
                    <Accordion
                        defaultExpanded={isGetSourcesExpanded ? false : true}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Regenerate Section</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ConditionalGuide />

                            {hasInitialRules && rules.length === 0 && (
                                <Alert severity="warning" sx={{ mb: 2 }}>
                                    This section requires at least one rule to
                                    regenerate.
                                </Alert>
                            )}

                            <Typography variant="h6" gutterBottom>
                                Generation
                            </Typography>
                            <Divider sx={{ my: 2 }} />

                            {!hasInitialRules && (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1,
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            Prompt
                                        </Typography>
                                        <Tooltip
                                            title="Provide specific instructions for generating the section content."
                                            arrow
                                        >
                                            <IconButton
                                                size="small"
                                                sx={{ ml: 1, mt: 0.25, p: 0 }}
                                            >
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={16}
                                        value={prompt}
                                        onChange={(e) =>
                                            setPrompt(e.target.value)
                                        }
                                        variant="outlined"
                                        sx={{ mb: 2 }}
                                    />

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1,
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            Additional Information
                                        </Typography>
                                        <Tooltip
                                            title="Provide additional context or requirements for the content generation."
                                            arrow
                                        >
                                            <IconButton
                                                size="small"
                                                sx={{ ml: 1, mt: 0.25, p: 0 }}
                                            >
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={8}
                                        value={additionalInfo}
                                        onChange={(e) =>
                                            setAdditionalInfo(e.target.value)
                                        }
                                        variant="outlined"
                                        sx={{ mb: 2 }}
                                    />
                                </>
                            )}

                            {hasInitialRules && (
                                <>
                                    <Box sx={{ mb: 2 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: 1,
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Content Revision
                                            </Typography>

                                            <Tooltip
                                                title="Revise aspects of the outputted content such as the order of information, the level of presented detail, or the tone of the writing."
                                                arrow
                                            >
                                                <IconButton
                                                    size="small"
                                                    sx={{
                                                        ml: 1,
                                                        mt: 0.25,
                                                        p: 0,
                                                        verticalAlign:
                                                            'text-bottom',
                                                    }}
                                                >
                                                    <InfoIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={16}
                                            value={additionalInfo}
                                            onChange={(e) =>
                                                setAdditionalInfo(
                                                    e.target.value
                                                )
                                            }
                                            variant="outlined"
                                        />
                                    </Box>
                                </>
                            )}

                            <Typography variant="subtitle1" gutterBottom>
                                Sources
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    mb: 2,
                                }}
                            >
                                {allSources.map((source, index) => (
                                    <Chip
                                        key={index}
                                        label={formatSourceTitle(source)}
                                        onDelete={
                                            newSources.includes(source)
                                                ? () =>
                                                      handleSourceRemove(source)
                                                : undefined
                                        }
                                        sx={{
                                            maxWidth: '100%',
                                            justifyContent: 'space-between',
                                        }}
                                    />
                                ))}
                            </Box>
                            <Button
                                disabled={true}
                                onClick={() => setIsSourceModalOpen(true)}
                            >
                                Manage Sources
                            </Button>
                            <Alert severity="info" sx={{ mb: 2 }}>
                                This button is used to add or remove sources for
                                the section. This feature has been disabled for
                                this sandbox.
                            </Alert>

                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                sx={{ mt: 2 }}
                            >
                                Rules
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    mb: 2,
                                }}
                            >
                                {rules.map((rule, index) => (
                                    <Tooltip
                                        key={index}
                                        title={
                                            rule.type === 'conclude' ? (
                                                'Generates conclusion from document tables'
                                            ) : (
                                                <>
                                                    {`${
                                                        rule.type
                                                    } rule using sources: ${rule.sources.join(
                                                        ', '
                                                    )}`}
                                                    {rule.topics &&
                                                        rule.topics.length >
                                                            0 && (
                                                            <>
                                                                <br />
                                                                {`Topics: ${rule.topics.join(
                                                                    ', '
                                                                )}`}
                                                            </>
                                                        )}
                                                    {rule.instructions && (
                                                        <>
                                                            <br />
                                                            {`Instructions: ${rule.instructions}`}
                                                        </>
                                                    )}
                                                </>
                                            )
                                        }
                                    >
                                        <Chip
                                            label={
                                                rule.type === 'conclude' ? (
                                                    'Conclude: Generate conclusion from tables'
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            gap: 1,
                                                        }}
                                                    >
                                                        <span>{`${
                                                            rule.type
                                                        }: ${rule.sources.join(
                                                            ', '
                                                        )}`}</span>
                                                        {rule.topics &&
                                                            rule.topics.length >
                                                                0 && (
                                                                <span
                                                                    style={{
                                                                        opacity: 0.7,
                                                                    }}
                                                                >
                                                                    [
                                                                    {rule.topics.join(
                                                                        ', '
                                                                    )}
                                                                    ]
                                                                </span>
                                                            )}
                                                    </Box>
                                                )
                                            }
                                            onDelete={() =>
                                                handleRuleRemove(rule)
                                            }
                                            sx={{
                                                maxWidth: '100%',
                                                justifyContent: 'space-between',
                                                '& .MuiChip-label': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                },
                                            }}
                                        />
                                    </Tooltip>
                                ))}
                            </Box>
                            <Button onClick={() => setIsRulesModalOpen(true)}>
                                Add Rules
                            </Button>

                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 3 }}
                            >
                                AI Settings
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Alert severity="info" sx={{ mb: 2 }}>
                                These settings only apply to sections generated
                                with a "summarize" rule or without rules.
                            </Alert>

                            <Typography variant="subtitle1" gutterBottom>
                                Model
                            </Typography>
                            <Select
                                options={modelOptions}
                                value={model}
                                onChange={setModel}
                                styles={customStyles}
                                formatOptionLabel={(option) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            src={option.image}
                                            alt={option.label}
                                            style={{
                                                width: 20,
                                                marginRight: 10,
                                            }}
                                        />
                                        <span>{option.label}</span>
                                    </div>
                                )}
                            />

                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Temperature
                                    </Typography>
                                    <TextField
                                        type="number"
                                        value={temperature}
                                        onChange={(e) =>
                                            setTemperature(
                                                Math.min(
                                                    1,
                                                    Math.max(
                                                        0,
                                                        Number(e.target.value)
                                                    )
                                                )
                                            )
                                        }
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            min: 0,
                                            max: 1,
                                            step: 0.01,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 3 }}
                            >
                                Output Styling
                            </Typography>
                            <Divider sx={{ my: 2 }} />

                            <Typography variant="subtitle1" gutterBottom>
                                Tense Instructions
                            </Typography>
                            <Select
                                options={tenseOptions}
                                value={tenseInstruction}
                                onChange={setTenseInstruction}
                                className="react-select"
                            />

                            <Button
                                onClick={handleGenerate}
                                variant="contained"
                                color="primary"
                                disabled={!canGenerate()}
                                sx={{ mt: 2 }}
                            >
                                {loading ? 'Generating...' : 'Generate'}
                            </Button>

                            {error && (
                                <Typography color="error" sx={{ mt: 2 }}>
                                    {error}
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>

                    <GetSources
                        selectedText={selectedText}
                        getSources={getSources}
                        documentId={id}
                        getOrgAccessToken={getOrgAccessToken}
                        setParentLoading={setLoading}
                        isExpanded={isGetSourcesExpanded}
                        onExpand={handleGetSourcesExpand}
                        sourceResults={sourceResults}
                        setSourceResults={setSourceResults}
                    />
                </>
            )}
        </>
    )

    return (
        <BaseSidebarTab
            title="Section Management"
            description="View and manage document sections."
        >
            {loading ? (
                <Box sx={{ padding: 2 }}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={60}
                        sx={{ mb: 2 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={100}
                        sx={{ mb: 2 }}
                    />
                    <Skeleton variant="rectangular" width="100%" height={200} />
                </Box>
            ) : (
                renderContent()
            )}

            <Modal
                open={isSuccessModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="regeneration-success-modal"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography
                        id="regeneration-success-modal"
                        variant="h6"
                        component="h2"
                        gutterBottom
                    >
                        Section Regenerated Successfully
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Section re-generation successful.
                    </Typography>
                    <Button
                        onClick={handleSuccessModalClose}
                        variant="contained"
                        sx={{ mt: 2 }}
                    >
                        Close and Search for Section
                    </Button>
                </Box>
            </Modal>

            <RulesModal
                open={isRulesModalOpen}
                onClose={() => setIsRulesModalOpen(false)}
                onAddRule={handleRuleAdd}
                sourceFiles={inputFiles}
                documentId={id}
                getOrgAccessToken={getOrgAccessToken}
                documents={documents}
                availableFiles={inputFiles}
            />

            <SourceSelectionModal
                open={isSourceModalOpen}
                handleClose={() => setIsSourceModalOpen(false)}
                onSourceSelect={handleSourceAdd}
                inputFiles={inputFiles}
                isLoadingInputFiles={loading}
                oldFiles={allSources}
                id={id}
            />
        </BaseSidebarTab>
    )
}

export default SectionTabComponent
