import React, { useState } from 'react';
import MainButton from './MainButton'; // Adjust the import path as necessary
import '../App.css'; // Import your CSS file
import veevaIcon from '../icons/veeva.png';
import boxIcon from '../icons/box.png';
import driveIcon from '../icons/drive.png';
import sharepointIcon from '../icons/sharepoint.png';
import UploadArea from './UploadArea';
import LogoPNG from '../icons/LOGO.png';
import paperclip from '../icons/Paper clip.svg';
import { MultipleFileUploader } from './MultipleFileUploader';
import { useAuthInfo } from '@propelauth/react';

const MultipleSystemUploaderComponent = ({ onNextClick1, onNextClick2, multipleFileList, handleFiles, removeFile}) => {
    const [fileName, setFileName] = useState('');
    const [additionalText, setAdditionalText] = useState('');

    const handleFileNameChange = (event) => {
        setFileName(event.target.value);
    };

    const handleAdditionalTextChange = (event) => {
        setAdditionalText(event.target.value);
    };

    const handleSaveClick = () => {
        // Handle save logic here
        console.log('File name saved:', fileName);
    console.log('Additional text saved:', additionalText);
    };

    const authInfo = useAuthInfo();

    const orgs = authInfo.orgHelper.getOrgs()

    const org = orgs[0].orgId

    let integrations;

    if (org === 'c3c7c741-e38b-4dbb-b8c0-be8367d36b7f') {
        integrations = [
            { name: "Artos", status: "Connected", img: LogoPNG },
            { name: "Veeva", status: "Not Connected", img: veevaIcon },
            { name: "Google Drive", status: "Not Connected", img: driveIcon },
            { name: "Box", status: "Not Connected", img: boxIcon },
            { name: "Sharepoint", status: "Not Connected", img: sharepointIcon },
        ]
    } else {
        integrations = [
            { name: "Artos", status: "Connected", img: LogoPNG },
        ]
    }

    

    // const integrations = [
    //     { name: "Artos", status: "Connected", img: LogoPNG },
    //     { name: "Veeva", status: "Not Connected", img: veevaIcon },
    //     { name: "Google Drive", status: "Not Connected", img: driveIcon },
    //     { name: "Box", status: "Not Connected", img: boxIcon },
    //     { name: "Sharepoint", status: "Not Connected", img: sharepointIcon },
    // ]

    return (
        <div className="upload-container">
            <div className="input-container">
                <p className='secondary-text' style={{ fontWeight: "bold", color: "black" }}>Select a system from which to upload your file(s):</p>
            </div>
            <div className="tiles-container">
                {integrations.map((integration, index) => (
                    <div className="tile" key={index}>
                        <div className="tile-content">
                            <img src={integration.img} alt={integration.name} />
                            <p>{integration.status}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* <UploadArea /> */}
            <div style={{marginTop:'2vh'}}className={`input-container ${multipleFileList.length < 1 ? 'dashed-border' : ''}`}>
                            {multipleFileList.length >= 1 && <p style={{textAlign:'left'}}>Files Selected:</p>}
                            {multipleFileList.length == 0 && <MultipleFileUploader handleFiles={handleFiles} />}
                            {multipleFileList.length >= 1 && (
                                <>
                                {multipleFileList.map((file, index) => (
                                    <div className='multiple-file-upload-card' key={file.name}>
                                    <div className='file-info'>
                                      <img src={paperclip} alt="Paperclip"/>
                                      <p className='file-name'>{file.name}</p>
                                    </div>
                                    <button className='remove-file-btn' onClick={() => removeFile(index)}>Remove</button>
                                  </div>
                                ))}
                                </>
                            )}
                        </div>
            <div className="button-container">
                <MainButton
                    onClick={onNextClick2}
                    text="Back"
                    width="100px"
                    backgroundColor="#ffffff"
                    fontColor="#1f477d"
                    className="main-button"
                    border="2px solid #1f477d"
                    style={{ marginRight: "10px" }}
                />
                <MainButton
                    onClick={onNextClick1}
                    text="Upload"
                    width="100px"
                    backgroundColor="#1f477d"
                    fontColor="#ffffff"
                    className="unfilled-button"
                    border="2px solid #1f477d"
                />
            </div>
        </div>
    );
};

export default MultipleSystemUploaderComponent;