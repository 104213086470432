import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, Divider, Snackbar } from '@mui/material'
import { DataGrid, GridFooter } from '@mui/x-data-grid'
import TableAssignmentWizard from './TableAssignmentWizard'
import { addTabularDataToDoc, delay } from '../../../../utils/utils'
import { useAuthInfo } from '@propelauth/react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Alert,
} from '@mui/material'
import { formatSectionText } from '../../../../utils/sectionExtract'
import { PRELOADED_DOC_IDS } from '../../../../utils/tableAssignments'

const InsertTablesTab = ({
    sectionInfo,
    tables,
    tableAssignments,
    onTableAssignmentsUpdate,
    onTableSelect,
    documentId,
    onTableInserted,
}) => {
    const [isWizardOpen, setIsWizardOpen] = useState(false)
    const [isConfirmOpen, setIsConfirmOpen] = useState(false)
    const [insertProgress, setInsertProgress] = useState(0)
    const [isInserting, setIsInserting] = useState(false)
    const [insertError, setInsertError] = useState(null)
    const [dialogError, setDialogError] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()

    const getOrgAccessToken = async () => {
        return await authInfo.tokens.getAccessTokenForOrg(orgs[0].orgId)
    }

    const columns = [
        { field: 'sectionName', headerName: 'Section', flex: 1 },
        { field: 'tableTitle', headerName: 'Table', flex: 2 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handlePreviewTable(params.row.tableId)}
                    >
                        Preview
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleRemoveAssignment(params.row.id)}
                    >
                        Remove
                    </Button>
                </Box>
            ),
        },
    ]

    const handlePreviewTable = (tableId) => {
        const table = tables.find((t) => t.id === tableId)
        if (table) {
            onTableSelect(table)
        }
    }

    const handleOpenWizard = () => setIsWizardOpen(true)
    const handleCloseWizard = () => setIsWizardOpen(false)

    const handleAssign = (section, selectedTables) => {
        const newAssignments = selectedTables.map((table) => ({
            id: `${section.section_name}-${table.id}`,
            sectionName: section.section_name,
            tableTitle: table.title,
            sectionId: section.section_name,
            tableId: table.id,
            csvPath: table.csvPath,
        }))

        if (PRELOADED_DOC_IDS.includes(documentId)) {
            const existingAssignments = tableAssignments.filter(
                (assignment) =>
                    !newAssignments.some(
                        (newAssign) =>
                            newAssign.tableTitle === assignment.tableTitle
                    )
            )
            onTableAssignmentsUpdate([
                ...existingAssignments,
                ...newAssignments,
            ])
        } else {
            onTableAssignmentsUpdate([...tableAssignments, ...newAssignments])
        }
    }

    const handleRemoveAssignment = (id) => {
        const updatedAssignments = tableAssignments.filter(
            (assignment) => assignment.id !== id
        )
        onTableAssignmentsUpdate(updatedAssignments)
    }

    const handleBulkInsert = () => {
        setIsConfirmOpen(true)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setSnackbarOpen(false)
    }

    const performBulkInsert = async () => {
        setIsInserting(true)
        setInsertProgress(0)
        setInsertError(null)
        setDialogError(null)
        let lastUrl

        try {
            const orgAccessToken = await getOrgAccessToken()

            for (let i = 0; i < tableAssignments.length; i++) {
                const assignment = tableAssignments[i]

                const insertPayload = {
                    section_header: assignment.sectionName,
                    document_id: documentId,
                    csv_paths: [
                        assignment.csvPath.replace(`${orgs[0].orgId}/`, ''),
                    ],
                    table_title: [assignment.tableTitle],
                    csv_input_container: 'tables',
                    document_container: 'output',
                    filter_column: '',
                    operator: '',
                    comparator: '',
                }

                try {
                    lastUrl = await addTabularDataToDoc(
                        insertPayload,
                        orgAccessToken
                    )
                    setInsertProgress(((i + 1) / tableAssignments.length) * 100)
                } catch (err) {
                    console.error(
                        `Error inserting table ${assignment.tableTitle}:`,
                        err
                    )
                    setDialogError(
                        `Failed to insert table ${assignment.tableTitle}`
                    )
                    throw err
                }
            }

            setIsConfirmOpen(false)
            onTableInserted(
                tableAssignments[tableAssignments.length - 1].tableTitle,
                lastUrl,
                'Table successfully inserted'
            )
            onTableAssignmentsUpdate([])
        } catch (err) {
            console.error('Bulk insert error:', err)
            setInsertError('Failed to complete bulk insert')
        } finally {
            setIsInserting(false)
        }
    }

    return (
        <Box
            sx={{
                height: '85%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                overflowY: 'auto',
            }}
        >
            {insertError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {insertError}
                </Alert>
            )}
            <Box sx={{ mb: 2 }}>
                <Button variant="contained" onClick={handleOpenWizard}>
                    Assign Table(s)
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid
                    rows={tableAssignments}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    disableSelectionOnClick
                    hideFooterSelectedRowCount
                    slots={{
                        footer: (props) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderTop:
                                            '1px solid rgba(224, 224, 224, 1)',
                                        width: '100%',
                                    }}
                                >
                                    <Box sx={{ p: 1 }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleBulkInsert}
                                            disabled={
                                                tableAssignments.length === 0
                                            }
                                            size="small"
                                        >
                                            Bulk Insert
                                        </Button>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <GridFooter {...props} />
                                    </Box>
                                </Box>
                            )
                        },
                    }}
                />
            </Box>

            <TableAssignmentWizard
                open={isWizardOpen}
                onClose={handleCloseWizard}
                sectionInfo={sectionInfo}
                tables={tables}
                onAssign={handleAssign}
            />

            <Dialog
                open={isConfirmOpen}
                onClose={() => !isInserting && setIsConfirmOpen(false)}
            >
                <DialogTitle>
                    {!isInserting ? 'Confirm Bulk Insert' : 'Processing Tables'}
                </DialogTitle>
                <DialogContent>
                    {!isInserting ? (
                        <Typography>
                            Are you sure you want to insert{' '}
                            {tableAssignments.length} table(s) into their
                            respective sections?
                        </Typography>
                    ) : (
                        <Box sx={{ textAlign: 'center', py: 3 }}>
                            <CircularProgress size={40} />
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="body1" sx={{ mb: 1 }}>
                                Processing and inserting tables...
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This may take up to 1 minute.
                            </Typography>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                {Math.round(insertProgress)}%
                            </Typography>
                        </Box>
                    )}
                    {dialogError && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {dialogError}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsConfirmOpen(false)}
                        disabled={isInserting}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={performBulkInsert}
                        disabled={isInserting}
                        variant="contained"
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default InsertTablesTab
