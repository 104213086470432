import { useRef } from 'react'
import '../App.css'

export const MultipleFileUploader = ({
    handleFiles,
    allowMultiple = true,
    isDisabled,
}) => {
    const hiddenFileInput = useRef(null)

    const handleClick = (event) => {
        event.preventDefault()
        hiddenFileInput.current.click()
    }

    const handleChange = (event) => {
        const filesUploaded = event.target.files
        const fileContents = []

        // Convert FileList to Array
        const filesArray = Array.from(filesUploaded)

        filesArray.forEach((currentFile) => {
            if (currentFile.size > 10 * 10 * 1024 * 1024) {
                alert(`File ${currentFile.name} size must be less than 100 MB.`)
                return // Skip this file and move to the next one
            }

            const reader = new FileReader()
            reader.onload = function (e) {
                fileContents.push(e.target.result)
                // If all files have been read, call handleFiles
                if (fileContents.length === filesArray.length) {
                    handleFiles(filesArray, fileContents)
                }
            }
            reader.readAsArrayBuffer(currentFile)
        })

        event.target.value = '' // Clear the input field after handling files
    }

    function ConditionalUploader() {
        return isDisabled === true ? (
            <></>
        ) : (
            <>
                <div className="select-file-box" onClick={handleClick}>
                    <p
                        className="blue-text"
                        style={{
                            textAlign: 'center',
                            marginBottom: 0,
                            fontSize: 18,
                            fontWeight: 700,
                        }}
                    >
                        Click to upload a file or drag and drop
                    </p>
                    <p
                        className="blue-text"
                        style={{
                            textAlign: 'center',
                            marginTop: 2,
                            textDecoration: 'underline',
                        }}
                    >
                        DOCX or PDF up to 100MB
                    </p>
                </div>
                <input
                    type="file"
                    accept=".pdf,.docx,.xlsx,.csv"
                    {...(allowMultiple ? { multiple: true } : {})}
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                />
            </>
        )
    }

    return (
        <>
            <ConditionalUploader />
        </>
    )
}
