import React from 'react'
import { Box, Typography, IconButton, Paper } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const TableDetailsView = ({ table, onClose }) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: 'rgba(255, 255, 255, 1)',
                zIndex: 1,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: 3,
                    px: 2, // Added horizontal padding to match table
                }}
            >
                <Box>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 700,
                            mb: 0.5,
                        }}
                    >
                        {table.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {table.description || 'No description provided'}
                    </Typography>
                </Box>
                <IconButton onClick={onClose} sx={{ mt: -1 }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Paper sx={{ YflexGrow: 1, overflow: 'auto', p: 2 }}>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        table: ({ node, ...props }) => (
                            <table
                                style={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    marginBottom: '1rem',
                                }}
                                {...props}
                            />
                        ),
                        tr: ({ node, index, ...props }) => (
                            <tr
                                style={{
                                    backgroundColor:
                                        props.children[0]?.props?.node?.position
                                            ?.start?.line %
                                            2 ===
                                        0
                                            ? '#f8f9fa'
                                            : '#ffffff',
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        '#f5f5f5'
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        props.children[0]?.props?.node?.position
                                            ?.start?.line %
                                            2 ===
                                        0
                                            ? '#f8f9fa'
                                            : '#ffffff'
                                }}
                                {...props}
                            />
                        ),
                        th: ({ node, ...props }) => (
                            <th
                                style={{
                                    borderBottom: '2px solid #d0d0d0',
                                    padding: '12px 16px',
                                    textAlign: 'left',
                                    backgroundColor: '#f2f2f2',
                                    fontWeight: 600,
                                }}
                                {...props}
                            />
                        ),
                        td: ({ node, ...props }) => (
                            <td
                                style={{
                                    borderBottom: '1px solid #e0e0e0',
                                    padding: '12px 16px',
                                }}
                                {...props}
                            />
                        ),
                    }}
                >
                    {table.content}
                </ReactMarkdown>
                {/* <Typography mode="markdown">{table.content}</Typography> */}
            </Paper>
        </Box>
    )
}

export default TableDetailsView
